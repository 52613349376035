import React, { useEffect, useState } from "react";
import "./myobjs.scss";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useContext} from "react";
import {DataContext} from "../../context/DataContext";
import {ModalWin} from "../modalwin/ModalWin";
import {PlusMyObjModal} from "./PlusMyObjModal";
import ObjsService from "../../services/ObjsService";
import {Context} from "../../index";
import ModalFiles from "../modalwin/ModalFiles";
import PassObj from "./modalactive/PassObj";
import {observer} from "mobx-react-lite";
import UserService from "../../services/UserService";

function DriveOnProject() {
    const {store} = useContext(Context)
    const rule = store.user.unit
    const myacc = store.user.account
    const myid = store.user.id
    const {btns_modules} = useContext(DataContext)

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    let getId = searchParams.get('id_object')

    if(getId === null){
        getId = 0
    }
    const [thisURL, setThisURL] = useState(getId)
    const [passactive, setPassactive] = useState(false)
    const [alluser, setAllusers] = useState([])
    const [listuser, setListusers] = useState([])
    const [activeusers, setActiveusers] = useState([])
    const [typeopen, setTypeopen] = useState('tabel')
    const [reload, setReload] = useState('start')
    const openShifrBlock = (putId) => {
        setThisURL(putId)
        if(thisURL !== 0){
        }
    }

    const [listmans, setListMans] = useState([])
    const [openSV, setOpenSV] = useState(false)
    const [svMan, setSvMan] = useState(0)
    const findObjectById = (id, array) => {
        return array.find(item => item.user_id === id);
    }

    const uploadList = async () => {
        try{
            const {data} = await UserService.getPoint()
            setListMans(data.list)
            const foundObject = findObjectById(myid, data.list);
            if(foundObject){
                setOpenSV(true)
                setSvMan(foundObject.unit)
            }
        }catch(e){

        }
    }
    const makeList = () => {
        let newarr = []
        let renameobj = {}
        listuser.forEach(user=>{
            renameobj = user
            alluser.forEach(man=> {
                if(user.user_id === man.id){
                    renameobj.full_name = man.full_name
                    renameobj.developer = man.developer
                }
            })
            newarr.push(renameobj)
        })
        setActiveusers(newarr)
    }
    const getListusers = async () => {
        try{
            const users = await ObjsService.getUsersList()
            setAllusers(users.data)
            const {data} = await ObjsService.dataOfObj({idobj: getId})
            setListusers(data)
        }catch(e){

        }

    }
    useEffect(()=>{
        getListusers()
        uploadList()
    }, [reload])

    useEffect(()=>{
        makeList()
    }, [alluser, listuser])
    return (
        <>
            <div className='right-block'>
                <div className='top-box'>
                    <div className='left-box-portal'>
                        <Link to={thisURL !== 0 ? openShifrBlock(0)  : `/objectsportal`} className='back-button'>
                            <p>Назад</p> <i className='fa-solid fa-rotate-left'></i>
                        </Link>
                        {(rule === 7 || myacc === 'superadmin') &&
                            <div className='back-button' onClick={()=>{setPassactive(!passactive);setTypeopen('tabel')}}>
                                <p>Передать табель</p>
                            </div>
                        }
                        {(rule === 7 || rule === 5 || myacc === 'superadmin') &&
                            <div className='back-button' onClick={()=>{setPassactive(!passactive);setTypeopen('sv')}}>
                                <p>Передать сварку</p>
                            </div>
                        }
                        {/*<SearchObj/>*/}
                    </div>
                    <div className='right-box'>
                        {/*<ChangeObj/>*/}
                    </div>
                </div>
                <div className='btn_projects'>
                    {btns_modules.map((btn, index)=>{
                        let svbtn = false
                        let tabelbtn = false
                        {activeusers.map((man, index)=>{
                            if(man.user_id === myid && man.type === 'sv'){
                                svbtn = true
                            }
                            if(man.user_id === myid && man.type === 'tabel'){
                                tabelbtn = true
                            }
                        })}
                        return(
                            <Link style={((tabelbtn && btn.title === 'Табеля') ||(svbtn && btn.title === 'Сварщики') || (myacc === 'superadmin'))?{display: 'flex'}:{display: 'none'}} key={index} to={btn.url + '?id=' + getId}  className='btn_project_block'><i className={`${btn.icon}`}/><div>{btn.title}</div></Link>
                        )}

                    )}
                </div>
                <div className="autrosblock">
                    <div className="autrosblock_title">Создал (только этот пользователь может передать): </div>
                    <div className="autrosblock_papa">
                        {activeusers.map((man, index)=>{
                            if(+man.papa === +man.user_id){return ( <div key={index}>{man.full_name}</div> )}}
                        )}
                    </div>
                    <div className="autrosblock_title">
                        Пользователи, которые работают с табелем:
                    </div>
                    <div className="autrosblock_users">
                        {activeusers.map((man, index)=>{
                            if(+man.papa !== +man.user_id && man.type === 'tabel'){
                                return ( <div key={index}>{man.full_name}  - - {man.developer}; </div> )}
                        })}
                    </div>
                    <div style={{marginBottom: '20px'}}></div>
                    <div className="autrosblock_title">
                        Пользователи, которые работают с разделом "Сварщики":
                    </div>

                    <div className="autrosblock_users">
                        {activeusers.map((man, index)=>{
                            if(+man.papa !== +man.user_id && man.type === 'sv'){
                                return ( <div key={index}>{man.full_name}  - - {man.developer}; </div> )}
                        })}
                    </div>
                </div>
                <ModalFiles data={<PassObj getId={getId} active={passactive} setActive={setPassactive} typeopen={typeopen} setTypeopen={setTypeopen} setReload={setReload}/>} active={passactive} setActive={setPassactive} />
            </div>
        </>
    )
}
export default observer(DriveOnProject)