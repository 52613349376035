import "./style.scss"
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import PollsService from "../../services/PollsService";
import UserService from "../../services/UserService";

export const Slider = () => {
    const click = async () => {
        try {
            await UserService.sliderClick()
        }catch (e) {
            console.log(e)
        }
    }
    const clickHandler = () => click()
    return (
        <div className="slider_block" style={{backgroundImage: `url('/news/contest/yearofhealth-min.jpg')`, backgroundColor: 'rgba(255,255,255,0.9)'}}>
            <Link to={'/naturepower'} onClick={clickHandler} className={`about-button`}>подробнее</Link>
        </div>

    )
}