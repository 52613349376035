import React, {useState} from 'react'

const PhotoGallery = ({ images,path,currentImageIndex,setCurrentImageIndex }) => {

    const closeImageFullscreen = () => {
        setCurrentImageIndex(null)
    }
    const goToPreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
    }
    const goToNextImage = () => {
        setCurrentImageIndex((prevIndex) => prevIndex !== null && prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex)
    }
    return (
        <div className="fullscreen-overlay">
            <div className={`fullscreen-container`}>
                <div style={{width:'100%'}} className="fullscreen-image">
                    <div className="prev-button" onClick={goToPreviousImage}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </div>
                    <div className="next-button" onClick={goToNextImage}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    <img src={`${path}/${images[currentImageIndex].path}`} alt={'image'}/>

                    <div className="image-description">
                        <div className={`name`}>{images[currentImageIndex].name}</div>
                        <div className='progress'>{currentImageIndex+1} из {images.length} </div>
                    </div>
                </div>
            </div>
            <div className={`overlay`} onClick={closeImageFullscreen}></div>
            <div onClick={closeImageFullscreen} className='close-fullscreen'><i className="fa-solid fa-xmark"></i></div>
        </div>
    )
}

export default PhotoGallery