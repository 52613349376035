import React, {useContext, useRef, useState,useEffect} from 'react'
import styles from './MessageArea.module.scss'
import {DataContext} from "../../context/DataContext";


const MessageArea = ({message,setMessage,sendMessage,loading=false}) => {
    const textareaRef = useRef(null)
    const {emojis} = useContext(DataContext)
    const [showEmojis,setShowEmojis] = useState(false)
    const changeHandler = (e) => {
        setMessage(e.target.value)
    }
    const addEmoji = (emoji) => {
        setMessage(message + emoji)
        setShowEmojis(false)
    }
    useEffect(() => {
        const textarea = textareaRef.current
        const maxHeight = 200
        const minHeight = 30
        if (textarea) {
            textarea.style.height = textarea ? 'auto' : null
            const newHeight = Math.min(Math.max(textarea.scrollHeight - 20, minHeight), maxHeight)
            textarea.style.height = `${newHeight}px`
        }
    }, [message])
    return (
        <div className={styles.textbox}>
            <div className={styles.textarea}>
                <textarea ref={textareaRef} rows="1" cols="50" value={message} onChange={changeHandler} placeholder="Напишите сообщение"/>
                <div className={styles.circle2}><i onMouseEnter={() => setShowEmojis(true)} onMouseLeave={() => setShowEmojis(false)} className="fa-regular fa-face-smile"></i></div>
                <div className={styles.emojicontainer}>
                    {showEmojis && (
                        <div className={styles.emojipicker} onMouseEnter={() => setShowEmojis(true)} onMouseLeave={() => setShowEmojis(false)}>
                            {emojis.map((emoji, index) => (
                                <span key={index} onClick={() => addEmoji(emoji)} className={styles.emoji}>{emoji}</span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.left}><div className={styles.circle}>{!loading ? <i onClick={() => sendMessage()} className="fa-solid fa-paper-plane"></i> : <i className="fa-solid fa-hourglass-half"></i>}</div></div>
        </div>
    )
}

export default MessageArea