import React, {useContext, useEffect, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import {Context} from "../../index";

import style from './PriMedosmotry.module.scss'


function PriMedosmotry(){
    const {store} = useContext(Context)

    useEffect(() => {
        console.log(store.user.full_name)
    },[])

    return (
        <div className={style.content}>
            <h4 className={style.title}>PriMedosmotry</h4>

        </div>
    )
}

export default observer(PriMedosmotry)