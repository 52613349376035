import './fixers.scss'
import React, {useContext, useEffect, useRef, useState} from "react";
import UserService from "../../services/UserService";
import CmsSelect from "../../components/inputs/CmsSelect";
import FilesService from "../../services/FilesService";
import {useMessage} from "../../hooks/message.hook";
import checkPassword from "../../components/functions/checkPassword";
import isCorrectLogin from "../../components/functions/isCorrectLogin";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import ModalFiles from "../../components/modalwin/ModalFiles";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import Select from "react-select";

function PointSV(){
    const [loading,setLoading] = useState(false)
    const {store} = useContext(Context)
    const imageRef = useRef(null)
    const message = useMessage()
    const [t13,setT13] = useState([])
    const [worker,setWorker] = useState(null)
    const [zas,setZas] = useState([])
    const [point, setPoint] = useState(0)
    const [name,setName] = useState('')
    const [thisView,setThisView] = useState([])
    const [listWorks,setListWorks] = useState([])
    const [listMans, setListMans] = useState([])

    const loadingHandler = async () => {
        try {
            setLoading(true)
            // const {data} = await UserService.getUnregWorkers()
            // const prereg = await UserService.getPrereg()
            const allusers = await UserService.getStatUsers()
            // if(prereg.data) setZas(prereg.data)
            // if(allusers.data) setZas(allusers.data)
            let allmans = allusers.data
            let i = 0
            const newlist = [...allmans.users]
            newlist.forEach((man)=>{
                man.label = man.full_name + '  ' + man.developer
                man.value = man.tn
                man.index = i
                i++
            })
            setListWorks(newlist)
        }catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const uploadList = async () => {
        try{
            const {data} = await UserService.getPoint()
            console.log(data.list)
            setListMans(data.list)
        }catch(e){

        }
    }

    const [update, setUpdate] = useState({})

    const plusPoint = async () => {
        try{
            const {data} = await UserService.plusPoint({user_id: thisView.id, unit: point})
            console.log(data)
            setUpdate(data)
            // console.log(thisView)
            // console.log(point)
        }catch(e){
            console.log(e)
        }
    }

    useEffect(() => {
        loadingHandler()
        // uploadList()
    },[])

    useEffect(() => {
        uploadList()
    },[update])



    return (
        <div className={`register`}>
            <h4>Поиск сотрудника в таблице Т13</h4>
            <Select placeholder="Выбрать пользователя" onChange={(e) => setThisView(listWorks[e.index])} value={thisView} options={listWorks}  styles={{container:(baseStyles, state) => ({...baseStyles,width:'250px'})}}/>
            <div className="poinsv_button">
                <select className="poinsv_button_sel" onChange={(e)=>setPoint(e.target.value)}>
                    <option>0</option>
                    <option>9</option>
                    <option>8</option>
                    <option>7</option>
                    <option>6</option>
                    <option>5</option>
                    <option>4</option>
                    <option>3</option>
                    <option>2</option>
                    <option>1</option>
                </select>
                <div className="poinsv_button_plus" onClick={()=>plusPoint()}>Добавить</div>
            </div>
            <hr/>
            {(listMans)&&listMans.map((man, index)=>(
                <div key={index} className='listmans'>
                    <div key={index} className='listmans_fio'>{man.user.full_name}</div>
                    <div key={index} className='listmans_dev'>{man.user.developer}</div>
                    <div key={index} className='listmans_tn'>{man.user.tn}</div>
                    <div key={index} className='listmans_unit'>{man.unit}</div>
                </div>
            ))}

        </div>
    )
}

export default observer(PointSV)