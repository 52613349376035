import React, {useContext, useEffect, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import "../econom/ogm.scss"
import UserService from "../../services/UserService"
import LoadingSpinner from "../../components/loading/LoadingSpinner"
import './editor.scss'
import style from './CmsMed.module.scss'


function CmsMed(){
    const [loading,setLoading] = useState(false)

    const [jobs,setJobs] = useState(null)

    const syncJobs = async() => {
        try {
            setLoading(true)
            const {data} = await UserService.syncMedJobs()
            if(data){
                const sortedArray = data.sort((a, b) => {
                    if (a.job.toLowerCase() < b.job.toLowerCase()) return -1
                    if (a.job.toLowerCase() > b.job.toLowerCase()) return 1
                    return 0;
                })
                setJobs(sortedArray)
            }
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const changeMed = async (med,num) => {
        try {
            setLoading(true)
            const {data} = await UserService.setMedJob(med,num)
            const newJobs = jobs.map(item => {
                if (item.id === med.id) {
                    return { ...item, med: num }
                }
                return item
            })
            setJobs(newJobs)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        syncJobs()
    },[])
    return (
        <div className='workers'>
            <div className={`title`}>
                <h3 onClick={() => console.log(jobs)}>Справочник медосмотров</h3>
                <div className={`buttons-cms`}>
                    <div className={`button`} onClick={() => syncJobs()}>Синхронизовать должности</div>
                </div>
            </div>

            <div className={style.medtable}>
                {jobs ?
                    <div className={style.table}>
                        {jobs.map((job,index) => (
                            <div key={index} className={style.row}>
                                <div className={`${style.column} ${style.c1}`}>{job.job}</div>
                                <div className={`${style.column} ${style.c2}`}><div onClick={() => changeMed(job,0)} className={`${style.btn} ${job.med === 0 ? style.active : ''}`}></div></div>
                                <div className={`${style.column} ${style.c3}`}><div onClick={() => changeMed(job,1)} className={`${style.btn} ${job.med === 1 ? style.active : ''}`}></div></div>
                                <div className={`${style.column} ${style.c4}`}><div onClick={() => changeMed(job,2)} className={`${style.btn} ${job.med === 2 ? style.active : ''}`}></div></div>
                            </div>
                            ))
                        }
                    </div>
                    : null }
            </div>

            {loading ? (<LoadingSpinner/>) : null}

        </div>
    )
}

export default observer(CmsMed)