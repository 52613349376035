export default function removePatronymic(fullName) {
    // Разделяем строку по пробелам
    const nameParts = fullName.trim().split(/\s+/)
    // Проверяем, есть ли отчество (если частей больше двух)
    if (nameParts.length > 2) {
        // Возвращаем только первые две части (Фамилия и Имя)
        return `${nameParts[0]} ${nameParts[1]}`
    }
    // Если отчества нет, возвращаем строку как есть
    return fullName
}