import $api from "../http"

export default class OdataService{
    static getStructure(){
        return $api.get('/odata/getstructure')
    }
    static getpeoples(){
        return $api.get('/odata/peoples')
    }
    static getmethodwork(){
        return $api.get('/odata/getmethodwork')
    }
    static getobsos(){
        return $api.get('/odata/getobsos')
    }
    static getAllOtp(){
        return $api.get('/odata/getallotp')
    }
    static getallotp2(){
        return $api.get('/odata/getallotp2')
    }

    static getUserInfo(id){
        return $api.post('/odata/getuserinfo',{id})
    }
    static getOtdely(){
        return $api.get('/odata/getotdely')
    }

    static getInnUsers(){
        return $api.get('/odata/getinnusers')
    }
    static setInnsToUsers(){
        return $api.get('/odata/setinnstousers')
    }
    static getMeds(){
        return $api.get('/odata/getmeds')
    }
    static setMedsToUsers(){
        return $api.get('/odata/setmedstousers')
    }
    static getUatStructure(){
        return $api.get('/odata/getuatstructure')
    }
    static getHolydaysCounts(){
        return $api.get('/odata/getholydayscounts')
    }
    static getHolydaysCountsIam(){
        return $api.get('/odata/getholydayscountsiam')
    }
    static getTemp(){
        return $api.get('/odata/gettemp')
    }

}
