import React,{useEffect, useState} from "react";

import {MainHeader} from "../../components/header/Mainheader";
import {WorkPage} from "../../components/workpage/WorkPage";
import {Mainnavbar} from "../../components/navbar/Mainnavbar";

import '../economist/econom.scss'
import CmsMenu from "./CmsMenu";
import SyncTo1C from "./SyncToUat1C";
import SyncFrom1C from "./SyncFrom1C";
import Testing from "./Testing";

export default function CmsRouter({page= 1}){
    return (
        <div className='new_container'>
            <div className="up_path"><MainHeader /></div>
            <div className="main_path">
                <Mainnavbar />
                {page === 1 && <WorkPage data={<CmsMenu />}/>}
                {page === 3 && <WorkPage data={<SyncTo1C />}/>}
                {page === 2 && <WorkPage data={<SyncFrom1C />}/>}
                {page === 4 && <WorkPage data={<Testing />}/>}
            </div>
        </div>

    )
}
