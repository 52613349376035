import React from 'react'
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

export default async function ExportExcelSvodka(data){
    const json = []
    json[0] = ['№','ФИО','Должность']
    let shag = data.dops.length + 2
    data.days.map( (item,index) => {
        json[0][3 + index*shag] = `${item}`
        data.dops.map( (row,indexr) => {
            json[0][4+indexr+(index*shag)] = row.name
        })
        json[0][2+shag+(index*shag)] = `примечание`
    })
    const SHIFR = data.tabel[0].shifr
    const DOPSLENGTH = data.dops.length

    let INDEX = 1
    data.tabel.map( (item,index) => {
        let ifexist = false
        data.days.map(ifka => {
            const key = `c${ifka}`
             if(item[key].length){
                 ifexist = true
             }
        })
        if(ifexist) {
            json[INDEX] = []
            json[INDEX][0] = INDEX
            json[INDEX][1] = item.name
            json[INDEX][2] = item.developer

            data.days.map((row, rindex) => {
                json[INDEX][3 + rindex * shag] = item[`m${row}`]
                data.dops.map((dops, dindex) => {
                    if (dops.name === item[`dop${row}`]) {
                        json[INDEX][4 + dindex + rindex * shag] = item[`m${row}`]
                    } else {
                        json[INDEX][4 + dindex + rindex * shag] = ' '
                    }
                })
                json[INDEX][2 + (1 + rindex) * shag] = item[`c${row}`]
            })
            INDEX++
        }
    })

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    worksheet.addRow(['ООО Сургутское РСУ'])
    worksheet.addRow([SHIFR])
    worksheet.addRow(['Фонд оплаты труда'])
    worksheet.addRow([])
    json.forEach(row => {
        worksheet.addRow(row)
    })

// Установка границ для всех ячеек
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            cell.border = {
                top: { style: 'medium', color: { argb: 'FF333333' } },
                bottom: { style: 'medium', color: { argb: 'FF333333' } },
                left: { style: 'medium', color: { argb: 'FF333333' } },
                right: { style: 'medium', color: { argb: 'FF333333' } }
            }
        })
    })
    const dynamicColumnIndices = []
    for (let i = DOPSLENGTH + 4; i < worksheet.columns.length; i += (DOPSLENGTH + 2)) {
        dynamicColumnIndices.push(i)
    }
    worksheet.columns.forEach((column, index) => {
        let maxLength = 0

        if (index === 1 || index === 2 || dynamicColumnIndices.includes(index)) {
            column.eachCell({ includeEmpty: true }, cell => {
                let columnLength;
                if (cell.value instanceof Date) {
                    columnLength = cell.value.toISOString().length + 5
                } else if (typeof cell.value === 'number') {
                    columnLength = cell.value.toString().length + 5
                } else if (typeof cell.value === 'boolean') {
                    columnLength = cell.value.toString().length + 5
                } else if (cell.value === null) {
                    columnLength = 3
                } else {
                    columnLength = cell.value.length + 5
                }
                if (columnLength > maxLength) {
                    maxLength = columnLength
                }
            })
            if (maxLength > 30) {
                column.width = 30;
                column.eachCell({ includeEmpty: true }, cell => {
                    cell.alignment = { wrapText: true }
                })
            } else {
                column.width = maxLength < 10 ? 10 : maxLength;
            }
        } else {
            column.width = 8
        }
    })

    worksheet.getColumn(1).width = 4;

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, 'output.xlsx')
}

// // Преобразуем JSON данные в worksheet
// const ws = XLSX.utils.aoa_to_sheet(json)
// // Добавляем worksheet в workbook
//
// ws['!cols'] = json[0].map((col, i) => ({
//     wch: Math.max(...json.map(row => (row[i] ? row[i].toString().length : 0))) * 1.2
// }))
//
// const borderStyle = {
//     top: { style: 'thick', color: { rgb: '000000' } },
//     bottom: { style: 'thick', color: { rgb: '000000' } },
//     left: { style: 'thick', color: { rgb: '000000' } },
//     right: { style: 'thick', color: { rgb: '000000' } }
// }
//
//
// const wb = XLSX.utils.book_new()
// XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
// // Генерируем файл Excel и запускаем скачивание
// XLSX.writeFile(wb, 'cmk.xlsx')