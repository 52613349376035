import React, {useEffect, useState} from 'react'
import style from './Galary.module.scss';
import cssphoto from './Images.module.scss';

import formatDate from "../functions/formatDate";
import FilesService from "../../services/FilesService";
import {useLocation} from "react-router-dom";
import {useContext} from "react";
import {Context} from "../../index";

const ImgGallery = ({ images,setImages, path='',currentImageIndex,setCurrentImageIndex }) => {
    const  {store} = useContext(Context)
    const ava = store.user.avatar
    const myid = store.user.id
    const login = store.user.login
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let getId = searchParams.get('id');
    const [newcomment, setNewComment] = useState('')
    const [allcomment, setAllcomment] = useState([])
    const [opennow, setOpennow] = useState(null)
    const [likes, setLikes] = useState([])
    const [imglike, setImglike] = useState([])
    const [colorlike, setColorlike] = useState('')
    useEffect(()=>{
        if(images[currentImageIndex]){
            if(images[currentImageIndex].user_id_likes.includes(myid)){
                setColorlike('red')
            }else{
                setColorlike('#454545')
            }
        }

    },[currentImageIndex])
    const plusLike = async () => {
        try{
            const image_id = images[currentImageIndex].id
            const heart = await FilesService.plusLikeGalary({myid, image_id})
            console.log(heart.data)
            images[currentImageIndex].user_id_likes = heart.data.user_id_likes
            const newlikesarr = [...images]
            setImages(newlikesarr)
        }catch(e){
            console.log(e)
        }
    }

    const sendComment = async () => {
        try {
            if(newcomment!==''){

                const image_id = images[currentImageIndex].id
                const thismess = newcomment
                const post = await FilesService.sendCommImg({thismess, image_id})
                console.log(post.data)
                setAllcomment(post.data)
                setNewComment('')
            }
        }catch(e){
            console.log(e)
        }
    }

    const allCommImg = async () => {
        try {
                // setOpennow(images[currentImageIndex].id)
                console.log(images[currentImageIndex].id)
            const image_id = images[currentImageIndex].id
                const comms = await FilesService.allCommImg({image_id})
                setAllcomment(comms.data)
                console.log(comms.data)

        }catch(e){
            console.log(e)
        }
    }

    useEffect(()=>{

    },[])

    useEffect(()=>{
        allCommImg()
    },[currentImageIndex])
    let thisimage
    const closeImageFullscreen = () => {
        setCurrentImageIndex(-1)

    }
    const goToPreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
    }
    const goToNextImage = () => {
        setCurrentImageIndex((prevIndex) => prevIndex !== null && prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex)
    }

    const [resize, setResize] = useState('40px')

    const makeResizeArea = (remake=true) => {
        // if(!remake){
        //     setResize('100px')
        // }else{
        //     setResize('40px')
        // }
    }

    const postComment = () => {
        // makeResizeArea(true)
    }

    return (
        <div className={cssphoto.fullscreen_overlay}>
            <div className={cssphoto.fullscreen_container}>
                <div className={cssphoto.fullscreen_image}>
                    <div className={cssphoto.prev_button} onClick={goToPreviousImage}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </div>
                    <div className={cssphoto.next_button} onClick={goToNextImage}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                    <img src={`files/albums/${images[currentImageIndex].image}`} alt={'image'}/>

                    <div className={cssphoto.image_description}>
                        {/*<div className={`name`}>{images[currentImageIndex].title}</div>*/}
                        <div className='progress'>{currentImageIndex+1} из {images.length} </div>
                    </div>
                </div>
                <div className={cssphoto.data_container}>
                    <div className={cssphoto.info}>
                        {/*<div className={`about`}>*/}
                        {/*    <div*/}
                        {/*        // style={{backgroundImage:`url("/files/profile/${works[currentImageIndex].face}")`}}*/}
                        {/*        className={`avatar`}></div>*/}
                        {/*    <div className={`text`}>*/}
                        {/*        <div className='name'>*/}
                        {/*            /!*{works[currentImageIndex].full_name}*!/*/}
                        {/*        </div>*/}
                        {/*        <div className={`date`}>*/}
                        {/*            /!*{works[currentImageIndex].developer}*!/*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <hr/>
                        <div className={cssphoto.answers}>
                            <span class="news-status"><span class="rigth-box"><span class="circle cliker " onClick={()=>plusLike()}><i class="fa-regular fa-heart" style={{color: colorlike}}></i> {images[currentImageIndex].user_id_likes.length}</span></span><span class="left-box"></span></span>
                            <br/>
                            {/*<i className={`fa-heart fa-regular`}></i>*/}
                            {/*{works[currentImageIndex].votes} */}
                            {/*<i className="fa-regular fa-calendar"></i>*/}
                            <span>
                                {/*{formatDate(works[currentImageIndex].createdAt)}*/}
                            </span>
                        </div>
                        <hr/>
                        {/*                                    {nominations && !isVote ?
                                        <div className={`nominations radio-button-container`}>
                                            {works[currentImageIndex].nomi === null ?
                                                <>
                                                    {nominations.map((item, index) => (
                                                        <span key={index}>
                                                        {!item.selected &&
                                                            <label key={index} className="radio-button">
                                                                <input
                                                                    type="radio"
                                                                    value={selected}
                                                                    checked={selected === index}
                                                                    onChange={(e) => setSelected(index)}
                                                                />
                                                                <span className="radio-button-text">{item.name}</span>
                                                            </label>
                                                        }
                                                        </span>
                                                    ))}
                                                    {all ? <div className='bold'><p>Вы сделали выбор во всех номинациях.</p></div> : null}
                                                </> :
                                                <div className='bold'>Вы выбрали номинацию <p>{nominations[works[currentImageIndex].nomi].name}</p> для данный работы</div>
                                            }
                                        </div> : null
                                    }
                                    {isVote && <div className={`small-text`}>Вы уже проголосовали</div>}*/}




                    </div>
                    <div className={style.imgcomment}>
                        <div className={style.imgcomment_list}>
                            {allcomment.map((comm, index)=>(
                                <div className={style.imgcomment_list_block}>
                                    <div className={style.imgcomment_list_block_avatar} style={{backgroundImage: `url(files/profile/${comm.user.avatar})`}}></div>
                                    <div className={style.imgcomment_list_block_text}>
                                        <div className={style.imgcomment_list_block_text_name}>{comm.user.full_name}</div>
                                        <div className={style.imgcomment_list_block_text_message}>
                                            {comm.text}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div className={style.imgcomment_form}>
                            <div className={style.imgcomment_form_input}>
                                <div className={style.imgcomment_form_input_myavatar} style={{backgroundImage: `url(/files/profile/${ava})`}}></div>
                                <textarea className={style.imgcomment_form_input_textarea}  style={{height: resize}} value={newcomment} onChange={(e)=>setNewComment(e.target.value)}></textarea>
                            </div>
                            <div className={style.imgcomment_form_enter}>
                                {/*<div class="emoji-container">*/}
                                {/*    <div className={style.imgcomment_form_emoji}>😀</div>*/}
                                {/*</div>*/}
                                <div className={style.imgcomment_form_enter_btn} onClick={()=>sendComment()}>Отправить</div>
                            </div>
                        </div>
                    </div>
                    <>
                        {/*                                    {!isVote &&
                                    <div className='buttons'>
                                        <hr/>
                                        {works[currentImageIndex].nomi === null ?
                                            <>
                                                {all ?
                                                    <div onClick={(e => setSelected(-1))} className={`button disable`}>Изменить выбор</div>
                                                    :
                                                    <div onClick={(e) => selectNomination(currentImageIndex)} className={`button`}>Номинировать</div>
                                                }
                                            </>
                                            :<div onClick={(e) => unselectNomination(currentImageIndex)} className={`button`}>Отменить</div>}
                                    </div>
                                    }*/}
                    </>


                </div>
            </div>
            <div className={cssphoto.overlay} onClick={closeImageFullscreen}></div>
            <div onClick={closeImageFullscreen} className={cssphoto.close_fullscreen}><i className="fa-solid fa-xmark"></i></div>
        </div>
    )
}

export default ImgGallery