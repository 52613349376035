import style from './Galary.module.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {useEffect, useState} from "react";
import {useContext} from "react";
import {Context} from "../../index";
import {useRef} from "react";
import onDocument from "../functions/onDocument";
import FilesService from "../../services/FilesService";
import LoadingSpinner from "../loading/LoadingSpinner";


export const ListCovers = () => {



    const {store} = useContext(Context)
    const rule = store.user.unit
    let navigate = useNavigate();
    const covers = [
        {
            coverImg: 'https://www.securitylab.ru/upload/iblock/f09/drlo0j06p3y5lkz0omyzlssj50hogco5.jpeg',
            title: 'космичский корпоратив',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://fungiline.com/wp-content/uploads/2023/01/adobestock_557438547-1140x445.jpg',
            title: 'альбом 2',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://img.goodfon.ru/wallpaper/big/e/d2/retro-hi-tech-wallpaper-1920x1080.webp',
            title: 'альбом 5',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://i.pinimg.com/originals/51/fd/56/51fd560d46817dd7c4fd619f782cee1f.jpg',
            title: 'новый вид описания',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-0.webp',
            title: 'живые обои для рабочего стола',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-1.webp',
            title: 'Длинное название для альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://bmw3blog.ru/wp-content/uploads/2019/11/BMW-E21-OBOI-Wallpapers-10.jpg',
            title: '',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://99px.ru/sstorage/53/2023/01/mid_348279_833663.jpg',
            title: 'Короткое название',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://img.wallpapic.com/i9962-731-415/medium/zhivotnye-zmeya-chernaya-mamba-reptiliya-oboi.jpg',
            title: 'Проверка текста для описания альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://carmods.ru/wp-content/uploads/2012/02/chevelle_ss_wall_1920.jpg',
            title: 'Новое описание этого альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://s1.1zoom.ru/b5050/489/Fallout_4_Christmas_111_555187_1920x1080.jpg',
            title: 'Fallout 4',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-0.webp',
            title: 'живые обои для рабочего стола',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-1.webp',
            title: 'Длинное название для альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://bmw3blog.ru/wp-content/uploads/2019/11/BMW-E21-OBOI-Wallpapers-10.jpg',
            title: '',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://99px.ru/sstorage/53/2023/01/mid_348279_833663.jpg',
            title: 'Короткое название',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://img.wallpapic.com/i9962-731-415/medium/zhivotnye-zmeya-chernaya-mamba-reptiliya-oboi.jpg',
            title: 'Проверка текста для описания альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://www.securitylab.ru/upload/iblock/f09/drlo0j06p3y5lkz0omyzlssj50hogco5.jpeg',
            title: 'космичский корпоратив',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://fungiline.com/wp-content/uploads/2023/01/adobestock_557438547-1140x445.jpg',
            title: 'альбом 2',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://img.goodfon.ru/wallpaper/big/e/d2/retro-hi-tech-wallpaper-1920x1080.webp',
            title: 'альбом 5',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://i.pinimg.com/originals/51/fd/56/51fd560d46817dd7c4fd619f782cee1f.jpg',
            title: 'новый вид описания',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-0.webp',
            title: 'живые обои для рабочего стола',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-1.webp',
            title: 'Длинное название для альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://bmw3blog.ru/wp-content/uploads/2019/11/BMW-E21-OBOI-Wallpapers-10.jpg',
            title: '',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://99px.ru/sstorage/53/2023/01/mid_348279_833663.jpg',
            title: 'Короткое название',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://img.wallpapic.com/i9962-731-415/medium/zhivotnye-zmeya-chernaya-mamba-reptiliya-oboi.jpg',
            title: 'Проверка текста для описания альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://carmods.ru/wp-content/uploads/2012/02/chevelle_ss_wall_1920.jpg',
            title: 'Новое описание этого альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://s1.1zoom.ru/b5050/489/Fallout_4_Christmas_111_555187_1920x1080.jpg',
            title: 'Fallout 4',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-0.webp',
            title: 'живые обои для рабочего стола',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://dabbar.ru/files/img/pics/3d-kartinki-na-rabochii-stol-1920kh1080/3d-kartinki-na-rabochii-stol-1920kh1080-1.webp',
            title: 'Длинное название для альбома',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://bmw3blog.ru/wp-content/uploads/2019/11/BMW-E21-OBOI-Wallpapers-10.jpg',
            title: '',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://99px.ru/sstorage/53/2023/01/mid_348279_833663.jpg',
            title: 'Короткое название',
            date: '15-06-2024'
        },
        {
            coverImg: 'https://img.wallpapic.com/i9962-731-415/medium/zhivotnye-zmeya-chernaya-mamba-reptiliya-oboi.jpg',
            title: 'Проверка текста для описания альбома',
            date: '15-06-2024'
        },
    ]
    const [cover, setCover] = useState({})
    const [width, setWidth] = useState(window.innerWidth);
    const [costcovers, setCostCovers] = useState(0)
    const [plusitems, setPlusitems] = useState(8)
    const [btnmore, setBtnmore] = useState(false)
    const [totalcovers, setTotalcovers] = useState([])
    const [createopen, setCreateopen] = useState(false)
    const [files, setFiles] = useState([])
    const empty = 0
    const [progress,setProgress] = useState(0)
    const [dragEnter,setDragEnter] = useState(false)
    const filesInputRef = useRef({})
    const [loading,setLoading] = useState(false)
    const [newname, setNewname] = useState(false)
    const [listalbums, setListalbums] = useState(undefined)

    function dragEnterHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(true)
    }
    function dragLeaveHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(false)
    }
    function dropHandler(event){
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(false)
        selectFilesHandler(event,[...event.dataTransfer.files])
    }
    const handleUploadProgress = (progressEvent,name) => {
        const percentCompleted = (progressEvent.loaded / progressEvent.total)
        setProgress(prevState => ({
            ...prevState,
            [name]: percentCompleted,
        }))
    }
    const selectFilesHandler = async (e,drop = null) => {
        let selectedFiles
        if(drop===null){
            selectedFiles = Array.from(e.target.files)
        }
        else selectedFiles = drop
        setFiles(selectedFiles)
        let index = 0
        for (const file of selectedFiles) {
            try{
                setLoading(true)
                if(onDocument(file.name)){
                    const response = await FilesService.uploadGalaryFiles(file, (progressEvent) => handleUploadProgress(progressEvent, file.name))
                    if (response.data) {
                        console.log(response.data)
                        setCover(response.data)
                    }
                }else{
                    const errFiles = [...selectedFiles]
                    errFiles[index].err = 'Неподдерживаемый фомат файла'
                    setFiles(errFiles)
                }
            }catch (e){
                console.log(e+': Проблема загрузки списка документов')
            }finally {
                setLoading(false)
            }
            index++
        }

    }

    const addPlusItems = (total) => {
        console.log(total)
        if(costcovers + plusitems > costcovers + total){
            let newtotal = total + 1
            setCostCovers(costcovers + newtotal)
            // erwerw(newtotal)
            setTimeout(()=>{
                addPlusItems(newtotal)
            }, 100)
        }
    }

    const getAllAlbums = async () => {
        try {
            const list = await FilesService.getAllAlbums()
            let newArr = list.data
            if(rule === 99 || store.user.account==='superadmin'){
                const pluser = 'fa-solid fa-plus'
                if(!newArr.includes(pluser)){
                    newArr.unshift(pluser);
                }

            }
            setListalbums(newArr)

        }catch(e){

        }
    }

    const createNewAlbum = async (open= false, idopen) => {
        if(open){
            try {
                if(cover.data && newname){

                }
                console.log(newname)
                cover.data = ''
                const album = await FilesService.createAlbum({title: newname, file: cover})
                if(album.data){
                    navigate(`/editgalary?id=${album.data.id}`)
                }
            }catch(e){

            }
        }else{
            navigate(`/editgalary?id=${idopen}`)
        }

    }



    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Очистка слушателя при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(()=>{
        let newArr = covers
        console.log(newArr)

        setTotalcovers(newArr)
        getAllAlbums()
    },[])

    useEffect(()=>{
        // if(width >= 1210){
            if(costcovers < 12){
                setCostCovers(12)
            }
            setPlusitems(12)
        // } else if(width < 1210 && width > 450){
        //     if(costcovers%2 !== 0){
        //         setCostCovers(12)
        //     }
        //     setPlusitems(12)
        // }
    }, [width])

    useEffect(()=>{
        if(costcovers < covers.length){
            setBtnmore(true)
        } else {
            setBtnmore(false)
        }

    },[costcovers])

    return (
        <div className={style.galary}>
            <div className={style.create_albom} style={(createopen)?{display:'flex'}:{display:'none'}}>
                <div className={style.imgcover} style={(cover.data)?{backgroundImage: `url(/files/temp/${cover.name})`}:{backgroundImage: ''}}></div>

                <div className={style.create_albom_photo}
                     onClick={(e) => filesInputRef.current.click()} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler} onDrop={dropHandler}
                    >
                    <input onChange={(e) => selectFilesHandler(e)} multiple ref={filesInputRef} className='hidden-upload' type='file'/>
                    <i className="fa-regular fa-square-plus"/>
                </div>
                {/*<div onClick={(e) => filesInputRef.current.click()} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler} onDrop={dropHandler} className={`draganddrop files-box upload ${empty && 'red-dotted-border'}`}>*/}
                {/*    <div className='upload-button'>{dragEnter ? <i className="fa-solid fa-upload"></i> : <i className="fa-solid fa-upload"></i>}*/}
                {/*        <input onChange={(e) => selectFilesHandler(e)} multiple ref={filesInputRef} className='hidden-upload' type='file'/>*/}
                {/*        <div>Здесь вы можете загрузить документы и изображения</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <input className={style.create_albom_name} placeholder='Введите название альбома' onChange={(e)=>setNewname(e.target.value)} defaultValue={(newname)?newname:''}/>

                <div className={style.create_albom_btns}>
                    <div className={style.create_albom_cancel} onClick={()=>{setCreateopen(false);setCover({})}}>Отмена</div>
                    <div className={style.create_albom_create} onClick={()=>{createNewAlbum(true);setCreateopen(false)}}>Создать</div>
                </div>

            </div>
            <div className={style.covers_list}>

                {(listalbums)&&listalbums.map((albom, index) => {
                    if(index < costcovers){
                        if(albom === 'fa-solid fa-plus'){
                            return(
                                <div key={index} className={style.plus_admin}  onClick={()=>{setCreateopen(true)}}>
                                    <i className={albom}/>
                                </div>
                            )
                        }else{
                            return(
                                <div key={index} className={style.covers_list_block} onClick={()=>{createNewAlbum(false, albom.id);setCreateopen(false)}}>
                                    <div className={style.covers_list_block_imgs} style={{backgroundImage: `url(files/albums/${albom.cover})`}}></div>
                                    <div className={style.covers_list_block_title}>{albom.title}</div>
                                    <div className={style.covers_list_block_date}>{albom.createdAt}</div>
                                    <div className={style.blackhover}></div>
                                </div>
                            )
                        }



                    }

                })}

            </div>
            <div className={style.btn_more} style={(btnmore)?{display: 'flex'}:{display: 'none'}} onClick={()=>addPlusItems(0)}><i className="fa-solid fa-angles-down"/></div>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}
