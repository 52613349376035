import React from "react"
import {MainHeader} from "../../components/header/Mainheader"
import {Mainnavbar} from "../../components/navbar/Mainnavbar"
import AboutUsPage from "./AboutUsPage";

const AboutUsLanding = () => {
    return (
        <div className='new_container'>
            <div className="up_path"><MainHeader /></div>
            <div className="main_path">
                <Mainnavbar />
                <AboutUsPage />
            </div>
        </div>
    )
}

export default AboutUsLanding