import React from "react"
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'


export default function exportToExcel(data,headList){
    const fileName = 'tabel.xlsx'
    const dataToPrint = data.map(item => [
        item.shifr,
        (item.ras === '') ? item.shifr : item.ras,
        item.tn,
        item.name,
        item.developer,
        item.branch,
        (item.d1 !== '') ? item.d1 : item.m1,
        (item.d2 !== '') ? item.d2 : item.m2,
        (item.d3 !== '') ? item.d3 : item.m3,
        (item.d4 !== '') ? item.d4 : item.m4,
        (item.d5 !== '') ? item.d5 : item.m5,
        (item.d6 !== '') ? item.d6 : item.m6,
        (item.d7 !== '') ? item.d7 : item.m7,
        (item.d8 !== '') ? item.d8 : item.m8,
        (item.d9 !== '') ? item.d9 : item.m9,
        (item.d10 !== '') ? item.d10 : item.m10,
        (item.d11 !== '') ? item.d11 : item.m11,
        (item.d12 !== '') ? item.d12 : item.m12,
        (item.d13 !== '') ? item.d13 : item.m13,
        (item.d14 !== '') ? item.d14 : item.m14,
        (item.d15 !== '') ? item.d15 : item.m15,
        (item.d16 !== '') ? item.d16 : item.m16,
        (item.d17 !== '') ? item.d17 : item.m17,
        (item.d18 !== '') ? item.d18 : item.m18,
        (item.d19 !== '') ? item.d19 : item.m19,
        (item.d20 !== '') ? item.d20 : item.m20,
        (item.d21 !== '') ? item.d21 : item.m21,
        (item.d22 !== '') ? item.d22 : item.m22,
        (item.d23 !== '') ? item.d23 : item.m23,
        (item.d24 !== '') ? item.d24 : item.m24,
        (item.d25 !== '') ? item.d25 : item.m25,
        (item.d26 !== '') ? item.d26 : item.m26,
        (item.d27 !== '') ? item.d27 : item.m27,
        (item.d28 !== '') ? item.d28 : item.m28,
        (item.d29 !== '') ? item.d29 : item.m29,
        (item.d30 !== '') ? item.d30 : item.m30,
        (item.d31 !== '') ? item.d31 : item.m31,
        item.transport.split('|')[0],
        item.transport.split('|')[1],
        item.ktu,
        '()'
    ])
    // Добавляем заголовки в данные для печати
    const allData = [headList, ...dataToPrint]
    // Создаем новый рабочий лист
    const worksheet = XLSX.utils.aoa_to_sheet(allData)
    // Функция для расчета автоширины колонок
    const getMaxWidth = (data, index) => {
        return Math.max(
            ...data.map(row => row[index] ? row[index].toString().length : 0), // длина текста в ячейке
            headList[index].length // длина заголовка
        )
    }
    // Рассчитываем ширину для каждой колонки
    worksheet['!cols'] = headList.map((_, colIndex) => ({
        width: getMaxWidth(allData, colIndex) + 2 // добавляем небольшой запас к ширине
    }))
    // Создаем новую книгу и добавляем в неё рабочий лист
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
    // Генерируем файл Excel в формате binary
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    // Создаем Blob из массива и скачиваем файл
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(blob, `${fileName}.xlsx`)
}