import React, {useContext, useRef, useState,useEffect} from 'react'
import styles from './Send.module.scss'
import {DataContext} from "../../context/DataContext";
import MessageArea from "./MessageArea";
import LoadingSpinner from "../loading/LoadingSpinner";
import MessagesService from "../../services/MessagesService";
import {useMessage} from "../../hooks/message.hook";

const SendGift = ({user,active,setActive,type='birthday'}) => {
    const {stickers} = useContext(DataContext)
    const fonMessage = useMessage()
    const [selectedGift,setSelectedGift] = useState(-1)
    const [message,setMessage] = useState('')
    const [loading,setLoading] = useState(false)
    const cancelHandler = () => {
        setActive(false)
        backHandler()
    }
    const backHandler = () => {
        setSelectedGift(-1)
    }
    const selectGift = (index) => {
        console.log('123')
        setSelectedGift(index)
    }
    const sendMessage = async () => {
        try {
            if(selectedGift){
                setLoading(true)
                const {data} = await MessagesService.sendGift(user.user_id,stickers[selectedGift],message)
                console.log(data)
                if(data){
                    fonMessage('Подарок отправлен!')
                    cancelHandler()
                }
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }

    }
    return (
        <>
            {active ?
            <div className={styles.container}>
                <div onClick={cancelHandler} className={styles.overlay}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
                {user ?
                    <div className={styles.window}>
                        {selectedGift<0 ?
                            <>
                                <div className={styles.header}>Выберите подарок</div>
                                <div className={styles.title}>Актуальные</div>
                                <div className={styles.stickers}>
                                    {stickers ? stickers.map((item, index) => (
                                        <div onClick={() => selectGift(index)} key={index} style={{backgroundImage: `url(files/stickers/${item})`}}
                                             className={styles.sticker}></div>
                                    )) : null}
                                </div>
                            </> :
                            <>
                                <div onClick={backHandler} style={{cursor:'pointer'}} className={styles.header}>
                                    <i className="fa-solid fa-chevron-left"></i>вернуться к выбору</div>
                                <div className={styles.stickers}>
                                    <div style={{backgroundImage: `url(files/stickers/${stickers[selectedGift]})`}} className={styles.bigsticker}></div>
                                </div>
                                <MessageArea message={message} setMessage={setMessage} sendMessage={sendMessage} />
                            </>
                        }

                    </div>
                    : null
                }

            </div>
                :null}
            {loading ? (<LoadingSpinner/>) : null}
        </>

    )
}

export default SendGift