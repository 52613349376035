import React from "react"
import './bye.scss'
import './polls.scss'

function Page404(){
    const handleReload = () => window.location.reload()
    return (
        <>
            <div className='bye_container'>
                <div className={'text'}>
                    <p>Что-то пошло не так...</p>
                    <p>К сожалению, нам не удалось загрузить необходимые данные. Мы уже работаем над исправлением этой проблемы.</p>
                    <p>Пожалуйста, попробуйте перезагрузить страницу. Если проблема не исчезнет, вернитесь позже.</p>
                    <p>Спасибо за ваше понимание и терпение.</p>
                    <div onClick={handleReload} className={`button`}>Перезагрузить</div>
                </div>
                <div className={`back`}></div>
            </div>
        </>
    )
}
export default Page404