import React, {useContext, useEffect, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import "./analitics.scss"
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import HistoryService from "../../services/HistoryService";
import formatDateTime from "../../components/functions/formatDateTime";
import CmsSelect from "../../components/inputs/CmsSelect";
import {DatePicker} from "rsuite";
import UserService from "../../services/UserService";
import CmsDatePicket from "../../components/inputs/CmsDatePicket";
import AuthService from "../../services/AuthService";


function History(){
    const [month, setMonth] = useState('01')
    const [year, setYear] = useState('2024')
    const [listhistory, setListhistory] = useState([])
    const getHistory = async () =>{
        const type = {
            id: 11
        }
        //const {data} = await HistoryService.getAllHistorySOSO()
        const {data} = await HistoryService.getAllHistorySOSOIN()
        const svarka = data
        const list = []

        svarka.forEach(line =>{
            const thismonth = line.createdAt.split('-')[1]
            const thisyear = line.createdAt.split('-')[0]
            if(thismonth === month && thisyear === year){
                list.push(line)
            }
        })
        setListhistory(list)
    }

    useEffect(() => {

    },[])
    useEffect(() => {

    },[month,year])
    return (
        <>
            <div className="table_welding">
                <select onChange={(e)=>setMonth(e.target.value)} className="table_welding_selector">
                    <option>{month}</option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                </select>
                <select onChange={(e)=>setYear(e.target.value)} className="table_welding_selector">
                    <option>{year}</option>
                    <option>2024</option>
                    <option>2025</option>
                    <option>2026</option>
                </select>
                <div className="btn_history" onClick={getHistory}  className="table_welding_button">Показать</div>
            </div>


            <div className="table_all">
                <div className="table_all_title">{month}-{year}</div>
                    <table className="table_welding_table">
                        <tr>
                            <th>п/п</th>
                            <th>ФИО</th>
                            <th>Тип</th>
                            <th>Действие</th>
                            <th>Время</th>
                        </tr>
                        {listhistory.map((strock, index)=>(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{strock.user.full_name}</td>
                                <td>{strock.historytype.name}</td>
                                <td>{strock.action}</td>
                                <td>{strock.createdAt.slice(0, 19).split('T')[0].split('-').reverse().join('-')} {strock.createdAt.slice(0, 19).split('T')[1]}</td>
                            </tr>
                        ))}

                    </table>
            </div>
        </>
    )
}
export default observer(History)