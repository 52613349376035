import React, {useEffect, useState} from "react";
import formatDate from "../../../functions/formatDate";
import WeldingService from "../../../../services/WeldingService";
import CmsDatePicket from "../../../inputs/CmsDatePicket";
import TableDatePicker from "../../../inputs/TableDatePicker";
import {useMessage} from "../../../../hooks/message.hook";
import './viewcontroll.scss'
import UserService from "../../../../services/UserService";
import {useContext} from "react";
import {Context} from "../../../../index";
import HistoryService from "../../../../services/HistoryService";

export const ViewTable = ({thisza,connections,setConnections,setActive,onRigthStatus=false, upcheck, setUpcheck}) => {
    const [num,setNum] = useState('')
    const [codecrew,setCodecrew] = useState('')
    const [date,setDate] = useState(new Date())
    const [way,setWay] = useState('')
    const [dostup,setDostup] = useState('')
    const [size,setSize] = useState('')
    const [zav,setZav] = useState('')

    const [oldConn,setOldConn] = useState(connections.map(item => ({ ...item })))
    const [onChange,setOnChange] = useState(false)

    const message = useMessage()

    const deleteConnectionHandler = (index) => {
        const newConnections = [...connections]
        newConnections.splice(index, 1)
        setConnections(newConnections)
    }

    const numHandler = (value,index) => {
        const newConnections = [...connections]
        newConnections[index].num = value
        setConnections(newConnections)
    }
    const codecrewHandler = (value,index) => {
        const newConnections = [...connections]
        newConnections[index].codecrew = value
        setConnections(newConnections)
    }
    const dateHandler = (value,index) => {
        const newConnections = [...connections]
        newConnections[index].date = value
        setConnections(newConnections)
    }
    const wayHandler = (value,index) => {
        const newConnections = [...connections]
        newConnections[index].way = value
        setConnections(newConnections)
    }
    const dostupHandler = (value,index) => {
        const newConnections = [...connections]
        newConnections[index].dostup = value
        setConnections(newConnections)
    }
    const sizeHandler = (value,index) => {
        const newConnections = [...connections]
        newConnections[index].size = value
        setConnections(newConnections)
    }
    const zavHandler = (value,index) => {
        const newConnections = [...connections]
        newConnections[index].zav = value
        setConnections(newConnections)
    }

    const [zavcomm, seZavcomm] = useState(null)

    const zavEdit = async (value,index,sett) => {
        seZavcomm(value)
        const newConnections = [...connections]
        newConnections[index].comment = value
        setConnections(newConnections)
        const comm = await WeldingService.editCommentZa({za_id: newConnections[index].id, value: value, sett: sett})

        console.log(comm.data)
        const currentMilliseconds = Math.random();
        setUpcheck(currentMilliseconds)
        //
        // newConnections[index].comment = value
        // setConnections(newConnections)
    }

    const [openWindow, setOpenWindow] = useState(false)
    const [statusIndex, setStatusIndex] = useState(null)
    const openWinStatus = (index) =>{
        setOpenWindow(!openWindow)
        setStatusIndex(index)
    }

    const saveHandler = async () => {
        try {
            if(onChange && onRigthStatus){
                const {data} = await WeldingService.saveConnections(connections)
                if (data){
                    await HistoryService.setHistoryPoint(11,`Изменение заявки на обьекте ${data.zasv_id}`)
                    console.log(data)
                    setOldConn([data.map(item => ({ ...item }))])
                    setActive(false)
                    message('Список соединений сохранен')
                }
            }
        }catch (e) {
            console.log(e)
        }
    }

    const  {store} = useContext(Context)
    const myid = store.user.id
    const [openSV, setOpenSV] = useState(false)
    const [svMan, setSvMan] = useState({})
    const [svid, setSvid] = useState(0)
    const findObjectById = (id, array) => {
        return array.find(item => item.user_id === id);
    };

    const uploadList = async () => {
        try{
            const {data} = await UserService.getPoint()
            console.log(data.list)

            const foundObject = findObjectById(myid, data.list);
            console.log(foundObject)
            if(foundObject){
                setOpenSV(true)
                setSvMan(foundObject)
                setSvid(foundObject.user_id)
            }
        }catch(e){

        }
    }


    // const openStatuses = (, index, )

    const [sts, setSts] = useState([])
    const getStatuses = async (type, unit) => {
        try{
            const statuses = await WeldingService.fetchStatuses(type, unit)
            setSts(statuses.data)
            console.log(statuses.data)
        }catch(e){
            console.log(e)
        }
    }

    const makeStatusOfwey = async (st) => {
        console.log(statusIndex)
        try{

            if(st){
                const newConnections = [...connections]
                console.log(newConnections[+statusIndex].id)
                console.log(st.id)
                const {data} = await WeldingService.editCommentZa({za_id: newConnections[+statusIndex].id, value: st.id, sett: 'status_id'})
                // if(data){
                    console.log(data)
                    setOpenWindow(!openWindow)
                    newConnections[+statusIndex] = data
                    setConnections(newConnections)

                // }
            }

            setStatusIndex(null)



        }catch(e){
            console.log(e)
        }
    }

    const seachStatus = (id, array) => {
        try{
            const name = array.find(item => item.id === id);
            return name.label
        }catch{
            // seachStatus(id, sts)
        }

    };
    useEffect(() => {
        uploadList()
        getStatuses(3, null)
    },[])

    useEffect(() => {
        if (JSON.stringify(connections) === JSON.stringify(oldConn)) {
            setOnChange(false)
        } else {
            setOnChange(true)
        }
    },[connections])

    return (
        <div className='new_controll' style={{marginTop: '10px', marginBottom: '-10px', height: '90vh'}}>
        {connections ?
            <div className={`new_controll_form`} >
                <div className='view_controll_form_this'>
                    <div className='new_controll_list_this_strock_up' id='cap_list'></div>
                    <div className='view_controll_form_this_strock' id='title_strock'>
                        <div className='view_controll_form_this_strock_number'>№ Соединения</div>
                        <div className='view_controll_form_this_strock_shifr'>Клеймо звена</div>
                        <div className='view_controll_form_this_strock_date'>Дата сварки</div>
                        <div className='view_controll_form_this_strock_way'>Способ сварки и положение</div>
                        <div className='view_controll_form_this_strock_access'>Доступ к сварному соединению</div>
                        <div className='view_controll_form_this_strock_size'>Размер св.соединения Тип св.соединения</div>
                        <div className='view_controll_form_this_strock_tube'>Зав. № труб (деталей)</div>
                        <div className='view_controll_form_this_strock_tube'>Комментарий</div>
                        <div className='view_controll_form_this_strock_date'>Статус</div>
                    </div>
                    {connections.map( (item,index) =>{
                        console.log(thisza.status_name); if(svMan.unit===5 && thisza.status_name !== 'передано в ЛНК' && thisza.status_name !== 'Принято ЛНК' && thisza.status_name !== 'В работе ЛНК' && thisza.status_name !== 'Завершено' ){onRigthStatus=true}
                        let opencomment = true
                        if((svMan.unit===4 && thisza.status_name === 'передано в ЛНК') || (svMan.unit===4 && thisza.status_name === 'В работе ЛНК') || (svMan.unit===4 && thisza.status_name === 'Принято ЛНК')){ opencomment=false}
                        return(
                        <div key={index} className='view_controll_form_this_strock view-connections'>
                            <div className='view_controll_form_this_strock_number'><input disabled={!onRigthStatus} value={item.num} onChange={(e) => numHandler(e.target.value,index)} className={`input_form`} id='number' type="text" /></div>
                            <div className='view_controll_form_this_strock_shifr'><input disabled={!onRigthStatus} value={item.codecrew} onChange={(e) => codecrewHandler(e.target.value,index)} className='input_form' id='shifr' type="text" /></div>
                            <div className='view_controll_form_this_strock_date'><input disabled={true} value={item.date.split('T')[0]} index={index} /></div>
                            <div className='view_controll_form_this_strock_way'><input disabled={!onRigthStatus} value={item.way} onChange={(e) => wayHandler(e.target.value,index)} className='input_form' id='way' type="text" /></div>
                            <div className='view_controll_form_this_strock_access'><input disabled={!onRigthStatus} value={item.dostup} onChange={(e) => dostupHandler(e.target.value,index)} className='input_form' id='access' type="text" /></div>
                            <div className='view_controll_form_this_strock_size'><input disabled={!onRigthStatus} value={item.size} onChange={(e) => sizeHandler(e.target.value,index)} className='input_form' id='size' type="text" /></div>
                            <div className='view_controll_form_this_strock_tube'><input disabled={!onRigthStatus } value={item.zav} onChange={(e) => zavHandler(e.target.value,index)} className='input_form' id='tube' type="text" /></div>
                            <div className='view_controll_form_this_strock_tube'><input disabled={opencomment}
                                                                                        value={item.comment} onChange={(e) => zavEdit(e.target.value,index,'comment')}
                                                                                        className='input_form'
                                                                                        style={(svMan.unit===4 && item.status_name === 'передано в ЛНК' && item.status_name === 'Принято ЛНК' && item.status_name === 'В работе ЛНК')?{boxShadow: '2px 2px 10px inset #454545',width: '100%'}:{}} id='tube' type="text" /></div>
                            <div className='view_controll_form_this_strock_date' onClick={()=>{if(svMan.unit===4){openWinStatus(index)}}}>{(sts.length > 0)?seachStatus(item.status_id, sts):''}</div>
                        </div>
                    )})}
                </div>
                <div className='new_controll_form_btns'>
                    {onRigthStatus ? <div onClick={(e) => saveHandler()} className={`new_controll_form_btns_insert ${!onChange || !onRigthStatus ? 'noactive' : null}`} id='plusConnection'>Сохранить</div>: null}
                    <div onClick={(e) => setActive(false)} className={`new_controll_form_btns_create`} id='plusBtn'>{!onRigthStatus ? 'Выход' : 'Отменить'}</div>
                </div>
                <div className="scroll" style={(openWindow)?{display: 'flex'}:{display: 'none'}}>
                    {sts.map((st, index)=>(
                        <div key={index} className='st_strock' onClick={()=>makeStatusOfwey(st)}>{st.label} {st.background}</div>
                        ))}
                </div>
            </div>
                :null}
        </div>
    )
}