import React, {useEffect, useRef, useState} from "react"
import WeldingService from "../../../services/WeldingService"
import {useMessage} from "../../../hooks/message.hook"
import {useLocation} from "react-router-dom"
import UserService from "../../../services/UserService"
import {useContext} from "react"
import {Context} from "../../../index"
import HistoryService from "../../../services/HistoryService"
import ModalFiles from "../../modalwin/ModalFiles"
import s from './TabelVW.module.scss'

export const TabelVW = ({loader,setLoader,getTabel,setTabel,active,idobj,shifr,month,year,commentCrew}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const  {store} = useContext(Context)

    let getMonth = searchParams.get('month')

    let checkM = +getMonth + 1
    if(checkM<10){
        checkM = '0'+checkM
    }

    const message = useMessage()

    const [datenow, setDatenow] = useState('2024-01-01')
    const [timenow, setTimenow] = useState('12:00:00')
    const [openday, setOpenday] = useState([])
    const [m2, setM2] = useState(false)
    const [m4, setM4] = useState(false)
    const [selectedDelView,setSelectedDelView] = useState(null)
    const [selectedRepairing,setSelectedRepairing] = useState(null)

    const getFormattedDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0")
        const month = (date.getMonth() + 1).toString().padStart(2, "0")
        const year = date.getFullYear()

        return { day, month, year }
    }
    const isValidDay = (day,month = String(+getMonth + 1).padStart(2, '0')) => {
        return ( openday.some(
            date =>
                date.day === day &&
                date.month === month &&
                date.year === +year
        ) && svMan.unit <= 3 ) || svMan.open === 1
    }

    const handleChange = async (pep, valuee, manid, index) => {
        try{
            const int = +valuee
            const newstrocks = [...getTabel]
            newstrocks[index][pep] = int
            setTabel(newstrocks)
            await WeldingService.updateManDays({day:pep,value:int,manid})
            await HistoryService.setHistoryPoint(11,`Редактирование на обьекте ${shifr} видов работ за ${pep} день ${+month+1} месяц ${year}`)
        }catch(e){
            console.log(e)
        }
    }

    const repairHandler = (work) => {
        if(work){
            console.log(work)
            setM4(true)
            setSelectedRepairing(work)
        }
    }
    const delViewHandler = (strock) => {
        if(strock){
            console.log(strock)
            setM2(true)
            setSelectedDelView(strock)
        }
    }
    const cancelHandler = () => {
        setM2(false)
        setM4(false)
        setSelectedDelView(null)
        setSelectedRepairing(null)
    }
    const delView = async () => {
        try{
            if(selectedDelView){
                const thisdel = await WeldingService.delViewWork({strock_id: selectedDelView})
                if(thisdel.data){
                    message('Вид работы удален')
                    setTabel(thisdel.data)
                    cancelHandler()
                }else{
                    setLoader(loader)
                }
            }
        }catch(e){
            console.log(e)
        }
    }

    const summItogy = () => {
        try{
            return '45'
        }catch(e){
            console.log(e)
        }

    }

    const myid = store.user.id
    const [listmans, setListMans] = useState([])
    const [openSV, setOpenSV] = useState(false)
    const [svMan, setSvMan] = useState({})
    const [svid, setSvid] = useState(0)
    const elementRef = useRef(null)

    const findObjectById = (id, array) => {
        return array.find(item => item.user_id === id)
    }

    const uploadList = async () => {
        try{
            const {data} = await UserService.getPoint()
            setListMans(data.list)
            const foundObject = findObjectById(myid, data.list)
            if(foundObject){
                setOpenSV(true)
                setSvMan(foundObject)
                setSvid(foundObject.user_id)
            }
        }catch(e){

        }
    }

    function getDaysInMonth(year, month) {
        const daysInMonth = new Date(year, +month + 1, 0).getDate()
        const daysArray = []
        for (let i = 1; i <= daysInMonth; i++) {
            const day = i.toString().padStart(2, '0')
            daysArray.push(day)
        }
        return daysArray
    }

    useEffect(() =>{
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)

        setOpenday([getFormattedDate(today), getFormattedDate(yesterday)])

        uploadList()
    },[])
    useEffect(() =>{
        // Исправление скроллинга числовых инпутов
        const inputs = document.querySelectorAll('input[type="number"]')

        const handleWheel = (event) => {
            event.preventDefault();
        }

        inputs.forEach(input => {
            input.addEventListener('wheel', handleWheel, { passive: false });
        })

        return () => {
            inputs.forEach(input => {
                input.removeEventListener('wheel', handleWheel);
            });
        }
        ///////////////////////////////
    },[getTabel])

    function canIDelView(){
        return (
            <div>
                {selectedDelView ?
                    <>
                        <p>Вы действительно желаете удалить вид работы - {selectedDelView.name}?</p>
                        <div className={`buttons-modal`}>
                            <div onClick={() => delView()} className={`button da`}>Да</div>
                            <div onClick={cancelHandler} className={`button`}>Нет</div>
                        </div>
                    </>
                    : null}
            </div>
        )
    }
    function canIAddRepair(){
        return (
            <div>
                {selectedRepairing ?
                    <>
                        <p>Вы действительно желаете добавить ремонт для вида работы - {selectedRepairing.name}?</p>
                        <div className={`buttons-modal`}>
                            <div className={`button da`}>Да</div>
                            <div onClick={cancelHandler} className={`button`}>Нет</div>
                        </div>
                    </>
                    : null}
            </div>
        )
    }

    const summ = (data,key) => {
        let S = 0
        for (let i = 1; i <= 31; i++) {
            S = S + data[`${key}${i}`]

        }
        return S
    }

    function DayInput(work,day,index,indexday,type) {
        const key = type ? `d${indexday+1}` : `p${indexday+1}`
        return (
            <input
                className={`${!isValidDay(day) ? s.disable : s.active}`}
                disabled={!isValidDay(day)}
                type='number'
                value={(work[key] === 0 || work[key] === '0') ? '' : work[key]}
                onChange={(e)=>{handleChange(key, e.target.value, work.id, index)}}
            />
        )
    }

    const days = getDaysInMonth(year,month)
    return (
        <>
            <div className={s.tabelvw} style={{ width: `${307 + days.length * 31}px` }}>
                {getTabel.map( (work,windex) => (
                    <div key={windex} className={s.viewswork}>
                        <div className={s.name}>
                            <div className={s.text}>{`${windex+1}. ${work.name}`}</div>
                            {(svMan.unit === 1 || svMan.unit === 2 || svMan.unit === 3) ? <div className={s.remove}><i onClick={() => repairHandler(work)} className={`fa-solid fa-screwdriver-wrench ${s.orahover}`}></i><i onClick={()=>delViewHandler(work)} className={`fa-solid fa-trash ${s.redhover}`}></i></div> : null}
                        </div>
                        <div className={s.tabel}>
                            {days.map( (day,dindex) => (
                                <div key={dindex} className={s.day}>
                                    <div className={s.nd}>{dindex+1}</div>
                                    <div className={s.plan}>
                                        { DayInput(work,day,+windex,+dindex,true) }
                                    </div>
                                    <div className={s.fact}>
                                        { DayInput(work,day,+windex,+dindex,false) }
                                    </div>
                                </div>
                            ))}
                            <div className={s.day}>
                                <div className={s.nd}><i className="fa-solid fa-arrows-left-right"></i></div>
                                <div className={s.text}>план</div>
                                <div className={s.text}>факт</div>
                            </div>
                            <div className={s.day}>
                                <div className={s.nd}>итого</div>
                                <div className={s.text}>{summ(work,'d')}</div>
                                <div className={s.text}>{summ(work,'p')}</div>
                            </div>
                            <div className={s.day}>
                                <div className={s.nd}>проект</div>
                                <div className={s.btext}>{work.volume}</div>
                            </div>
                            <div className={s.day}>
                                <div className={s.nd}>всего</div>
                                <div className={s.btext}>{work.allfact}</div>
                            </div>
                            <div className={s.day}>
                                <div className={s.nd}>δ</div>
                                <div className={s.btext}>{work.volume - work.allfact}</div>
                            </div>
                            <div className={s.day}>
                                <div className={s.nd}>%</div>
                                <div className={s.btext}>{(+work.allfact/+work.volume*100).toFixed(0)}%</div>
                            </div>
                        </div>
                    </div>
                ))}
                <ModalFiles data={canIAddRepair()} active={m4} setActive={setM4} />
                <ModalFiles data={canIDelView()} active={m2} setActive={setM2} />
            </div>
        </>
    )
}