import React, {useContext, useEffect, useState} from "react";
import "./tabelform.scss";
import "./tabviewwork1920.scss";
import "./tabviewwork1550.scss";
import "./tabviewwork1080.scss";
import "./tabviewworkMobile.scss";
import {TabelViewsWork} from "./TabelViewsWork";
import {TabelMans} from "./TabelMans";
import {NewCrewModal} from "./modalactive/NewCrewModal";
import {Link, useLocation} from "react-router-dom";
import WeldingService from "../../../services/WeldingService";
import {useMonth} from "../../../hooks/month.hook";
import ModalFiles from "../../modalwin/ModalFiles";
import {useMessage} from "../../../hooks/message.hook";
import Select from "react-select";
import ObjsService from "../../../services/ObjsService";
import {Context} from "../../../index";
import UserService from "../../../services/UserService";
import LoadingSpinner from "../../loading/LoadingSpinner";
import HistoryService from "../../../services/HistoryService";

export const TabelformOld = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const months = [
        'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
        'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'
    ]
    const thisMonth = useMonth()
    let getId = searchParams.get('id');
    let getShifr = searchParams.get('shifr');
    let getMonth = searchParams.get('month');
    let getYear = searchParams.get('year');

    const message = useMessage()
    const [thisobj, setThisobj] = useState({})

    const [crew, setCrew] = useState(false)
    const [crewName, setCrewName] = useState('')
    const [select, setSelect] = useState('отсутствует')
    const [views, setViews] = useState([])
    const [mans, setMans] = useState([])
    const [tabelView,setTabelView] = useState([])
    const [mycrews,setMycrews] = useState([])
    const [tabMans,setTabelMans] = useState([])
    const [allcrews,setAllcrews] = useState([])
    const [weldingcrews, setWeldingcrew] = useState([])
    const [newstatcrew, setNewstatcrew] = useState(21231213)
    const [listMans, setListMans] = useState([])
    const [thisMans, setThisMans] = useState([])
    const [viewload, setViewLoader] = useState(12323)
    const [listWorks, setListWorks] = useState([])
    const [thisView, setThisView] = useState(null)
    const [thisselcrew, setThisselcrew] = useState(null)
    const [crewopened, setCrewopened] = useState(false)
    const [selectedComment, setSelectedComment] = useState(null)
    const [m1, setM1] = useState(false)
    const [m3, setM3] = useState(false)
    const [commentCrew, setCommentCrew] = useState([])
    const [loading,setLoading] = useState(false)
    const [openSV, setOpenSV] = useState(false)
    const [svMan, setSvMan] = useState({})
    const [svid, setSvid] = useState(0)
    const [actv, setActv] = useState(false)
    const [thislistcomm, setThislistcomm] = useState([])
    const [textcomm, setTextcomm] = useState('')
    const [opencomday, setOpenComday] = useState('0')

    const {store} = useContext(Context)
    const inn = store.user.inn

    const getParamObj = async (e) => {
        try {
            const {data} = await WeldingService.getObgForHook({getShifr})
            if(data) setThisobj(data)
            else message('Ошибка загрузки')
        }catch (e) {
            console.log(e)
        }
    }
    const getViewsWork = async () => {
        try{
            const idshifr = +getShifr
            const viewsv = await WeldingService.getViewWorkSV({idshifr})
            let iskluch = []
            let viewswork = viewsv.data
            viewswork.sort((a, b) => a.id - b.id);
            let i = 0;
            viewswork.forEach(view => {
                if(views.findIndex(exists => exists.id === view.id) === -1 ){
                    view.label = view.viewname
                    view.value = view.id
                    view.index = i
                    i++
                    iskluch.push(view)
                }

            })
            setListWorks(iskluch)
        }catch(e){
            console.log(e)
        }
    }
    const handleSelect = e => {
        const value = e.target.value
        // console.log(value)
        const arr = value.split('|')
        setSelect({id: arr[1],crewname: arr[0]})
    }
    let btnsNameCrews
    const currentDate = new Date();
    const year = currentDate.getFullYear() + '';
    const currentMonth = currentDate.getMonth(); // Получаем номер текущего месяца

    const t13List = async (e) => {
        let month = months[currentMonth];
        try {
            const listMan = await UserService.getWorkers()
            let i = 0
            if (listMan.data.length !== 0){
                listMan.data.forEach(man => {
                    man.label = man.name + '  ' + man.developer
                    man.value = man.tn
                    man.index = i
                    i++
                })
                setListMans(listMan.data)
            } else {
                month = months[currentMonth - 1];
                try{
                    const listMan = await ObjsService.getT13({inn, month, year})
                    listMan.data.forEach(man => {
                        man.label = man.name + '  ' + man.developer
                        man.value = man.tn
                        man.index = i
                        i++
                    })
                    setListMans(listMan.data)
                } catch(e){
                    console.log(e)
                }
            }
        } catch (e){
            console.log(e)
        }
    }

    const activetedCrew = (button, crew) => {

        if(button){
            btnsNameCrews = document.querySelectorAll('.tabwelding_crews_block')
            button.classList = 'tabwelding_crews_block bgactive'
            btnsNameCrews.forEach(btn => {
                if(button !== btn) {
                    btn.classList = 'tabwelding_crews_block'
                }
            })
        }
        weldingcrews.forEach(crews => {
            if(crews.crew === crew){
                setCommentCrew([...crews.comments])
                setViews([...crews.views])
                setMans([...crews.mans])
                setCrewName(crews.crew)
            }
        })
    }

    const getMyCrews = async () => {
        try{
            const obj = await WeldingService.getObgForHook({getShifr})
            const shifr = obj.data.shifr
            const month = months[getMonth]
            const response = await WeldingService.getMyCrews({object_id: getShifr, shifr, month, getYear: ''+getYear})
            setMycrews(response.data)

            const tabel = await WeldingService.getTabelSv({object_id: getShifr, shifr, month, getYear: ''+getYear})
            const listtabels = tabel.data

            const comments = await WeldingService.getCommentTabel({object_id: getShifr, shifr, month, getYear: ''+getYear})
            const crewscomments = comments.data

            const crews = []
            const crewsman = []
            listtabels.forEach(item => {
                if(!crews.includes(item.crew)){
                    crews.push(item.crew)
                }
            })

            crews.forEach(crew=>{
                const zveno = {
                    crew: crew
                }
                const mans = []
                const views = []
                const comms = []
                crewscomments.forEach(comment => {
                    if(crew === comment.crewname){
                        comms.push(comment)
                    }
                })


                listtabels.forEach(item=>{
                    if(crew === item.crew && item.checkin === 'man'){
                        mans.push(item)
                    }
                    if(crew === item.crew && item.checkin === 'view'){
                        views.push(item)
                    }
                })
                views.sort((a, b) => a.id - b.id);
                mans.sort((a, b) => a.id - b.id);
                zveno.mans = mans
                zveno.views = views
                zveno.comments = comms
                crewsman.push(zveno)
            })
            setWeldingcrew([...crewsman])
            setAllcrews([...crews])
        }catch(e){
            console.log(e)
        }finally {
            // setLoading(false)
        }

    }

    const plusViewHandler = () => {
        if(thisView){
            setM1(true)
        }else{
            message('Выберите вид работы')
        }
    }
    const setCrewHandler = () => {
        console.log(thisselcrew)
        if(thisselcrew){
            setCrew(true)
        }else{
            message('Выберите звено')
        }

    }
    const cancelHandler = () => {
        setM1(false)
        setM3(false)
        setThisView(null)
        setSelectedComment(null)
    }
    const closeCommentsHandler = () => {
        setActv(false)
    }
    const plusView = async () => {
        try{
            if(thisView){
                const month = months[getMonth]
                const {data} = await WeldingService.plusVW({thisView, objid: getShifr, crew: crewName, month, year: getYear})
                await HistoryService.setHistoryPoint(11,`Добавление вида работ на обьекте ${getShifr}`)
                // console.log(data)
                if(data){
                    setViews([...views,data])
                    const updatedItems = listWorks.filter(item => item.id !== data.id)
                    setListWorks(updatedItems)
                    message('Вид работы добавлен')
                    cancelHandler()
                }
                else message('Ошибка добавления вида работ')
            }
        }catch(e){
            console.log(e)
        }
    }

    const addManHandler = async () => {
        try {
            // console.log(thisobj)
            if (thisMans){
                const {data} = await WeldingService.addMan({...thisMans,crew:crewName,shifr:thisobj.shifr,object_id:thisobj.id,year:getYear,month:months[+getMonth]})
                if(data){
                    setMans([...mans,thisMans])
                    await HistoryService.setHistoryPoint(11,`Добавление сотрудника ${thisMans.name} в табель на обьекте ${thisobj.shifr}`)
                }
            }
        }catch (e) {
            console.log(e)
        }
    }

    let mDate = new Date();

    let checkyear = mDate.getFullYear()
    let checkmonth = String(mDate.getMonth() + 1).padStart(2, '0')

    const myid = store.user.id
    const autor = store.user.full_name

    const findObjectById = (id, array) => {
        return array.find(item => item.user_id === id);
    }

    const uploadList = async () => {
        try{
            const {data} = await UserService.getPoint()
            setListMans(data.list)
            const foundObject = findObjectById(myid, data.list);

            if(foundObject){
                setOpenSV(true)
                setSvMan(foundObject)
                setSvid(foundObject.user_id)
            }
        }catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        uploadList()
    },[])

    useEffect(() => {
        views.forEach(tabel => {
            if(tabel.year === getYear && tabel.month === getMonth) {
                setTabelView(tabel.types)
                setTabelMans(tabel.peoples)
            }
        })
        t13List()
        getParamObj()
        getMyCrews()
    },[views])

    useEffect(()=>{
        getMyCrews()
        // console.log('2')
    },[newstatcrew])

    useEffect(()=>{
        // console.log('3')

        getViewsWork()

    },[viewload, thislistcomm])

    const writeTextInput = el => {
        setTextcomm(el)
    }

    const runText = async (allcom)=>{
        const thisCimInput = document.getElementById('newcomment')

        if(thisCimInput.value.length !== 0){
            const datacrew = views[0]
            const pushtext = await WeldingService.NewComm({shifr: datacrew.shifr, crewname: datacrew.crew, month: datacrew.month, year: datacrew.year, day: opencomday, autor: autor, text: thisCimInput.value})
            if(pushtext.data){
                const newarr = [...thislistcomm]
                const newarrCrew = [...commentCrew]

                newarr.push(pushtext.data)
                newarrCrew.push(pushtext.data)

                setThislistcomm(newarr)
                setCommentCrew(newarrCrew)
            }
        }

    }

    function ListComments(){
        return (
            <div className="modalka" style={(actv)?{display: 'flex'}:{display: 'none'}}>
                <div className="modalka_w">
                    <div className="modalka_w_up"><i className="fa-solid fa-xmark" onClick={()=>closeCommentsHandler()}></i></div>
                    <div className="list_comms">
                        <div className="newmess">
                            <input className="form_comm" id='newcomment' type={'text'}
                                // onChange={(e)=>setTextcomm(e.target.value)}
                            />
                            <div className="form_btn" onClick={()=>runText(thislistcomm)}>Отправить</div>
                        </div>
                        {thislistcomm.map((comm, index)=>{
                            return(
                                <div key={index} className="list_comms_com">
                                    <div className="list_comms_com_autor">{comm.autor}</div>
                                    <div className="list_comms_com_comment">{comm.comment}</div>
                                    <div className="list_comms_com_remove"><i onClick={() => removeCommentHandler(index)} className="fa-solid fa-xmark"></i></div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        )
    }

    const openWriter = day => {
        if(day && actv === false ){
            setOpenComday(day)
            const newarr = []
            setActv(!actv)
            commentCrew.map((com, index)=>{
                if(day === com.day){
                    newarr.push(com)
                }
            })
            setThislistcomm(newarr)
        }
    }
    const removeCommentHandler = (index) => {
        if(thislistcomm && thislistcomm.length){
            setM3(true)
            setSelectedComment(thislistcomm[index])
        }
    }
    const removeComment = async () => {
        try {
            if(selectedComment){
                const {data} = await WeldingService.removeComment(selectedComment.id)
                if(data){
                    const updatedComments = thislistcomm.filter(item => item.id !== selectedComment.id)
                    const updatedCommentsCrew = commentCrew.filter(item => item.id !== selectedComment.id)
                    setThislistcomm(updatedComments)
                    setCommentCrew(updatedCommentsCrew)

                    cancelHandler()
                }
            }
        }catch (e) {
            console.log(e)
        }
    }
    function CommentWelding(){
        if(views.length>0 && actv === false){
            let days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
            return(
                <div className="days_comm">
                    {days.map((day, index)=>(
                        <div key={index} className={`days_comm_day ${commentCrew.some(comment => comment.day === day) ? 'full-comment' : ''}`} onClick={()=>openWriter(day)}>{index+1} <i className="fa-solid fa-pen"/></div>
                    ))}
                </div>
            )
        }
    }
    function canIAddView(){
        return (
            <div>
                {thisView ?
                    <>
                        <p>Вы действительно желаете добавить вид работы - {thisView.label}?</p>
                        <div className={`buttons-modal`}>
                            <div onClick={() => plusView()} className={`button`}>Да</div>
                            <div onClick={cancelHandler} className={`button`}>Нет</div>
                        </div>
                    </> : null }
            </div>
        )
    }
    function canIDelComm(){
        return (
            <div>
                {selectedComment ?
                    <>
                        <p>Вы действительно желаете удалить коментарий - {selectedComment.comment}?</p>
                        <div className={`buttons-modal`}>
                            <div onClick={() => removeComment()} className={`button`}>Да</div>
                            <div onClick={cancelHandler} className={`button`}>Нет</div>
                        </div>
                    </> : null }
            </div>
        )
    }

    return (
        <div className='right-block-tabwelding'>
            <ListComments actv={actv} setActv={setActv}/>
            <div className="tabwelding_header">
                <div className="tabwelding_header_upper">
                    <Link to={`/welwel?id=${getShifr}`} className="back-button">Назад</Link>
                    <div className="tabwelding_header_upper_title"><span>{thisobj.shifr}</span> {thisMonth(getMonth)} {getYear}</div>
                    <Link to={`/welcontroll?id=${getId}&shifr=${getShifr}&month=${getMonth}&year=${getYear}`} className="back-button" style={(svMan.unit === 8)?{display:'none'}:{}}>Контроль</Link>
                </div>

                <div className="tabwelding_header_newcrewblock" style={(svMan.unit !== 1 && svMan.unit !== 2 && svMan.unit !== 3)?{display:'none'}:{}}>
                    <Select placeholder='Выбрать звено' onChange={(e) => setThisselcrew(mycrews[e.index])} value={thisselcrew} options={mycrews} styles={{container:(baseStyles, state) => ({...baseStyles,width:'236px',marginRight:' 6px'}),menu: (baseStyles, state) => ({...baseStyles,zIndex:100000})}}/>

                    <div className="back-button" onClick={() => setCrewHandler()}>Добавить звено</div>
                </div>
            </div>
            <div className="tabwelding_slice"></div>
            <div className="tabwelding_crews">
                <div className="open_wel_crew" onClick={()=>setCrewopened(!crewopened)}>{(crewopened)?'Свернуть':'Выбрать бригаду'}</div>
                {allcrews.map( (item,index) =>(
                    <div className="tabwelding_crews_block" style={(crewopened)?{display: 'flex'}:{display: 'none'}} key={index} onClick={e => {activetedCrew(e.target, item); setCrewopened(!crewopened)}}>{item}</div>
                ))}
            </div>
            <div className="tabwelding_slice"></div>
            <div>{crewName}</div>
            <div className="tabwelding_viewswork" style={(crewName==='')?{display: 'none'}:{display: 'flex'}}>
                <div className="tabwelding_viewswork_upper" style={(+getMonth+1 > +checkmonth)?{display: 'none'}:{}}>
                    <Select placeholder='Выбрать вид работы' onChange={(e) => setThisView(listWorks[e.index])} value={thisView} options={listWorks} styles={{container:(baseStyles, state) => ({...baseStyles,width:'250px'}),menu: (baseStyles, state) => ({...baseStyles,zIndex:100000})}}/>
                    {/*<div className="tabwelding_viewswork_upper_title">Виды работ</div>*/}
                    <div className="back-button"  style={(svMan.unit !== 1 && svMan.unit !== 2 && svMan.unit !== 3)?{display:'none'}:{margin: '0 10px'}} onClick={()=>{plusViewHandler()}}>Добавить вид</div>
                    <div onClick={() => alert(tabelView)} className="tabwelding_viewswork_upper_date">
                        {/*сегодня: 01-01-2024*/}
                    </div>
                </div>
            </div>
            <CommentWelding/>
            <TabelViewsWork actv={actv}
                            setActv={setActv}
                            thislistcomm={thislistcomm}
                            setThislistcomm={setThislistcomm} loader={viewload} setLoader={setViewLoader} getTabel={views} setTabel={setViews} active={crewName} idobj={getId} shifr={getShifr} month={getMonth} year={getYear} commentCrew={commentCrew}/>
            <div className="tabwelding_slice"></div>
            <div className="tabwelding_tabel" style={(crewName==='')?{display: 'none'}:{display: 'flex'}}>
                <div className="tabwelding_tabel_upper">
                    <Select placeholder='Выбрать сотрудника' onChange={(e) => setThisMans(listMans[e.index])} value={thisMans} options={listMans} styles={{container:(baseStyles, state) => ({...baseStyles,width:'250px',zIndex: '1'})}}/>
                    {/*<div className="tabwelding_tabel_upper_title">Табель</div>*/}
                    <div onClick={(e) => addManHandler()} style={(svMan.unit !== 1 && svMan.unit !== 2 && svMan.unit !== 3)?{display:'none'}:{}} className="back-button">Добавить</div>
                </div>
                <TabelMans peoples={mans} setPeoples={setMans} active={crewName} idobj={getId} shifr={getShifr} month={getMonth} year={getYear}/>
            </div>

            <ModalFiles data={canIAddView()} active={m1} setActive={setM1} />
            <ModalFiles data={canIDelComm()} active={m3} setActive={setM3} zindex={'100001'}/>
            <ModalFiles data={<NewCrewModal newstatcrew={newstatcrew} setNewstatcrew={setNewstatcrew} sel={thisselcrew} active={crew} setActive={setCrew} monther={getMonth} year={getYear} idobj={getShifr}  allcrews={allcrews} setAllcrews={setAllcrews} setWeldingcrew={setWeldingcrew} setMycrews={setMycrews}/>} active={crew} setActive={setCrew}/>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}