import React from "react";
import Buttons from "../../components/economist/Buttons";

export default function MedicialMenu(){
    return (
        <div className={`menu`}>
            <Buttons text={'Пройденые Медосмотры'} icon={`fa-solid fa-people-group`} url={`/medosmotry`}/>
            <Buttons text={'Плановые Медосмотры'} icon={`fa-solid fa-people-group`} url={`/prikazmedosmotry`}/>
        </div>
    )
}
