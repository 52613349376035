import React, {useContext, useState, useRef, useEffect} from "react";
import {Context} from "../../index";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import styles from './AboutUsPage.module.scss';
import UserService from "../../services/UserService";
import {DataContext} from "../../context/DataContext";
const AboutUsPage = () => {
    const {store} = useContext(Context)
    const {optionsMonth,optionsYear} = useContext(DataContext)
    const [loading,setLoading] = useState(false)

    const getNowMonthNum = () => {
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth()
        return (currentMonth === 0) ? 11 : currentMonth - 1
    }
    const monthNow = getNowMonthNum()

    const [num1,setNum1] = useState(0)
    const [num2,setNum2] = useState(0)
    const [num3,setNum3] = useState(0)
    const [num4,setNum4] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)

    const [monthState,setMonthState] = useState(optionsMonth[monthNow])
    const [yearState,setYearState] = useState(optionsYear[2])
    const [news,setNews] = useState(0)
    const [allPeoples,setAllPeoples] = useState(0)

    const timelineRef = useRef(null);
    let isDown = false;
    let startX;
    let scrollLeft;

    const loadingHandler = async() => {
        try{
            setLoading(true)
            const newusers = await UserService.getNewPeoples()
            if(newusers.data){
                let monthOnboard = []
                newusers.data.map(item => {
                    if(parseInt(item.onboard.split('.')[1]) === parseInt(monthState.value)+1 && item.onboard.split('.')[2] === yearState.label) monthOnboard.push(item)
                })
                //console.log(monthOnboard)
                setNews(monthOnboard.length)
            }
            const {data} = await UserService.getUni()
            if(data) {
                setAllPeoples(data.length)
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }

    }

    const handleMouseDown = (e) => {
        isDown = true;
        startX = e.pageX - timelineRef.current.offsetLeft;
        scrollLeft = timelineRef.current.scrollLeft;
    }
    const handleMouseLeave = () => {
        isDown = false;
    }
    const handleMouseUp = () => {
        isDown = false;
    }
    const handleMouseMove = (e) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - timelineRef.current.offsetLeft
        const walk = (x - startX) * 3
        timelineRef.current.scrollLeft = scrollLeft - walk
    }

    const intervalRef = useRef();
    const carouselRef = useRef();

    const startInterval = () => {
        intervalRef.current = setInterval(() => {
            scrollNext()
        }, 3000)
    }

    const stopInterval = () => {
        clearInterval(intervalRef.current)
    }
    const scrollNext = () => {
        const carousel = carouselRef.current;
        const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
        if (carousel.scrollLeft >= maxScrollLeft) {
            carousel.scrollTo({ left: 0, behavior: 'smooth' });
        } else {
            carousel.scrollBy({ left: carousel.clientWidth, behavior: 'smooth' });
        }
    };

    const scrollPrev = () => {
        const carousel = carouselRef.current;
        if (carousel.scrollLeft <= 0) {
            carousel.scrollTo({ left: carousel.scrollWidth - carousel.clientWidth, behavior: 'smooth' });
        } else {
            carousel.scrollBy({ left: -carousel.clientWidth, behavior: 'smooth' });
        }
    };

    const events = [
        { date: '1998', icon: <i className="fa-solid fa-trowel-bricks"></i>, title: 'Основание компании', description: 'Администрацией Сургутского района, Тюменской области распоряжением № 218/2 зарегистрировано общество с ограниченной ответственностью «Сургутское ремонтно – строительное управление».  ООО «Сургутское РСУ» было создано как дочернее предприятие АК «Транснефть» на базе структурного подразделения треста СНПС ОАО «Сибнефтепровод»' },
        { date: '2002', icon: <i className="fa-solid fa-unlock-keyhole"></i>, title: 'Создание нового Юридического Лица', description: '«Сургутское РСУ» становится самостоятельным предприятием' },
        { date: '2005', icon: <i className="fa-solid fa-user-tie"></i>, title: 'Лидер', description: 'Генеральным директором становится Макаров Александр Владимирович' },
        { date: '2006', icon: <i className="fa-solid fa-circle-check"></i>, title: 'Аккредитация и качество', description: 'Аккредитована лаборатория неразрушающего контроля' },
        { date: '2010', icon: <i className="fa-solid fa-person-digging"></i>, title: 'Прорыв в производстве', description: 'ООО «Сургутское ремонтно-строительное управление» становиться сертифицированным производителем  муфт композитных П1(КМТ)  и муфт приварных П2, П3, П4, П5, П5У, П6  Введен в эксплуатацию цех по производству ремонтных конструкций, оснащенный оборудованием  ведущих отечественных и зарубежных производителей.' },
        { date: '2011', icon: <i className="fa-solid fa-newspaper"></i>, title: 'Журнал «Эксперт»', description: 'В рейтинге динамично растущих компаний РФ, составленном журналом «Эксперт» ООО «Сургутское РСУ» заняло 34 место' },
        { date: '2011', icon: <i className="fa-solid fa-bookmark"></i>, title: 'Революционное достижение в производстве', description: 'Муфты для ремонта нефтепроводов производства ООО «Сургутское РСУ» внесены в Реестр основных видов продукции, применяемой ПАО "Транснефть"' },
        { date: '2012', icon: <i className="fa-solid fa-book"></i>, title: 'Лицензия на безопасность', description: 'Получена лицензия № 4-Б/00064 на деятельность по монтажу, техническому обслуживанию и ремонту средств обеспечения пожарной безопасности. Выдано Министерством РФ по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствии стихийных бедствий' },
        { date: '2013', icon: <i className="fa-solid fa-book-open"></i>, title: 'Лицензия на ионизирующее излучение', description: 'Получена лицензия на осуществление деятельности в области использования источников ионизирующего излучения (генерирующих) (за исключением случая, если эти источники используются в медицинской деятельности). Выдана Управлением Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Ханты-Мансийскому автономному округу – Югра' },
        { date: '2013', icon: <i className="fa-solid fa-cubes"></i>, title: 'Система качества', description: 'Внедрена  система менеджмента качества, соответствующая требованиям стандарта ГОСТ Р ИСО 9001-2011' },
        { date: '2014', icon: <i className="fa-solid fa-medal"></i>, title: 'Предприятие 2013 года', description: 'ООО «Сургутское РСУ» получило звание «Предприятие 2013 года» за достижение преимущества над конкурентами и получение победного места в Центральном федеративном округе' },
        { date: '2015', icon: <i className="fa-solid fa-school-circle-check"></i>, title: 'Аттестация сварочного производства', description: 'ООО «Сургутское РСУ» получает первую аттестацию в Системе аттестации сварочного производства НАКС' },
        { date: '2015', icon: <i className="fa-solid fa-money-bill-trend-up"></i>, title: 'Статус крупнейшего налогоплательщика', description: 'Получено уведомление о постановке на учёт в налоговом органе в качестве КРУПНЕЙШЕГО налогоплательщика' },
        { date: '2016', icon: <i className="fa-solid fa-magnifying-glass-chart"></i>, title: 'Менеджмента качества', description: 'Внедрена система менеджмента качества, соответствующая требованиям стандарта ГОСТ Р ИСО 9001-2011 (9001:2008)' },
        { date: '2016', icon: <i className="fa-solid fa-book-open"></i>, title: 'Новая лицензия', description: 'Получена лицензия на осуществление деятельности по сбору, транспортированию, обработке, утилизации, обезвреживанию, размещению отходов I-IV класса опасности. Вид работ: транспортирование отходов IV класса опасности' },
        { date: '2017', icon: <i className="fa-brands fa-pagelines"></i>, title: 'ЭКОЛОГИЧЕСКий менеджмент', description: 'Внедрена система ЭКОЛОГИЧЕСКОГО менеджмента, соответствующая требованиям стандарта ГОСТ ИСО 14001-2007 (ISO 14001:2004) и система  менеджмента БЕЗОПАСНОСТИ ТРУДА И ОХРАНЫ ЗДОРОВЬЯ, соответствующая требованиям стандарта ГОСТ Р 54934-2012/OHSAS18001:2007, а также интегрированные (комплексные) системы менеджмента' },
        { date: '2017', icon: <i className="fa-regular fa-calendar-check"></i>, title: 'Новые возможности', description: 'Получена лицензия на эксплуатацию взрывопожароопасных и химически опасных производственных объектов I, II и III классов опасности' },
        { date: '2017', icon: <i className="fa-solid fa-unlock-keyhole"></i>, title: 'Регистрация в СРО', description: 'Регистрация в реестре членов саморегулируемой организации' },
        { date: '2017', icon: <i className="fa-solid fa-people-group"></i>, title: 'Сотрудники в реестре специалистов', description: 'Сведения о некоторых сотрудниках ООО «Сургутское РСУ» включаются в Национальный реестр специалистов в области строительства' },
        { date: '2018', icon: <i className="fa-solid fa-medal"></i>, title: 'Награда', description: 'ООО «Сургутское ремонтно-строительное управление» получило диплом III степени победителя XXII Всероссийского конкурса на лучшую строительную организацию за 2017 год' },
        { date: '2019', icon: <i className="fa-solid fa-people-carry-box"></i>, title: 'Развитие', description: 'Существенно обновлена материально-техническая база предприятия' },
        { date: '2020', icon: <i className="fa-solid fa-people-line"></i>, title: 'Рост численности', description: 'Средняя численность работников организации в 2020 году составила около 1700 сотрудников, что почти в 6,5 раз превысило показатель численности 2009 года' },
        { date: '2021', icon: <i className="fa-solid fa-calendar-check"></i>, title: 'Новые возможности', description: 'Получено специальное разрешение: санитарно-эпидемиологическое заключение от 14.09.2021 № 86.ХЦ.01.000.М.000082.09.21 на осуществление деятельности связанной с работой источников ионизирующего излучения. Выдана Управлением Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Ханты-Мансийскому автономному округу – Югра, территориальный отдел в Сургуте и Сургутском районе' },
        { date: '2021', icon: <i className="fa-solid fa-bookmark"></i>, title: 'Сертификат СТО', description: 'Получен Сертификат соответствия действующей СМК требованиям ISO 9001 или СТО Газпром 9001' },
        { date: '2023', icon: <i className="fa-solid fa-champagne-glasses"></i>, title: 'Юбилей 25 лет', description: 'Состоялось празднование 25 летнего юбилея ООО «Сургутское РСУ»' },
        { date: '2024', icon: <i className="fa-solid fa-hand-point-up"></i>, title: 'Подтверждение аккредитации', description: 'Лаборатория неразрушающего контроля подтвердила аккредитацию на работы по неразрушающему контролю на объектах организаций системы «Транснефть»' },
    ]

    const texts = ['Основной вид деятельности – капитальное строительство, реконструкция и капитальный ремонт объектов нефтегазового комплекса Тюменской области. Вместе с тем, компания успешно реализует проекты жилищного строительства и является сертифицированным производителем ремонтных конструкций для ремонта нефтепроводов, продуктопроводов.','За двадцать пять лет деятельности предприятие сумело стать достойным примером надежности, профессионализма и инициативы.','Производственный опыт, накопленный при выполнении широкого комплекса работ для нефтегазовой промышленности Тюменской области, позволяет оперативно решать самые сложные проектные задачи в различных климатических условиях.','Основные ориентиры компании - это качество, эффективность и развитие.']
    const companies = ['g1.png','g2.png','g3.png','g4.png','g5.png','g6.png','g10.png','g8.png','g9.png']
    useEffect(() => {
        loadingHandler()

        startInterval()

        const options = {
            root: timelineRef.current,
            rootMargin: '0px',
            threshold: 0.1
        }

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.show)
                    observer.unobserve(entry.target)
                }
            });
        }, options);

        const elements = document.querySelectorAll(`.${styles.fadein}`)
        elements.forEach(element => {
            observer.observe(element)
        })

        return () => {
            stopInterval()
            if (observer) {
                observer.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        if(news){
            let start = 0
            const end1 = 128
            const end2 = news
            const end3 = 25

            const duration = 10
            const increment = 1
            const interval = setInterval(() => {
                start += increment
                setNum1(Math.min(Math.round(start), end1))
                setNum2(Math.min(Math.round(start), end2))
                setNum3(Math.min(Math.round(start), end3))

                if (start >= end1) {
                    clearInterval(interval)
                }
            }, duration)
            return () => {
                clearInterval(interval)
            }
        }
    },[news])

    useEffect(() => {
        if(allPeoples){
            let start = 0
            const end4 = allPeoples
            const duration = 0
            const increment = 1
            const interval = setInterval(() => {
                start += increment
                setNum4(Math.min(Math.round(start), end4))
                if (start >= end4) {
                    clearInterval(interval)
                }
            }, duration)
        }
    },[allPeoples])


    return (
        <div className={styles.aboutus}>
            <div className={styles.timeline}>
                <h2 className={styles.title}>О Компании ООО «Сургутское РСУ»</h2>
                <div className={styles.weare}>
                    <div className={styles.und}>
                        <div className={styles.we}>
                            <img alt={'001'} src={'/files/assets/001.png'} />
                            <p>Оперативность производства работ</p>
                        </div>
                        <div className={styles.we}>
                            <img alt={'002'} src={'/files/assets/002.png'} />
                            <p>Крупнейший представитель отрасли в Тюменской области</p>
                        </div>
                    </div>
                    <div className={styles.und}>
                        <div className={styles.we}>
                            <img alt={'003'} src={'/files/assets/003.png'} />
                            <p>Строительство и ремонт нефтепроводов любой сложности</p>
                        </div>
                        <div className={styles.we}>
                            <img alt={'004'} src={'/files/assets/004.png'} />
                            <p>Надежный партнер. Более 20 лет работы!</p>
                        </div>
                    </div>
                </div>

                <div className={styles.abouttext}>
                    <div className={styles.text}>
                        <p>{texts[0]}</p>
                        <p>{texts[1]}</p>
                        <p>{texts[2]}</p>
                        <p>{texts[3]}</p>
                        <div className={styles.punkts}>
                            <div className={styles.ele}>
                                <i className="fa-solid fa-diagram-project"></i>
                                <h5><span>{num1}+</span>выполненых проектов</h5>
                            </div>
                            <div className={styles.ele}>
                                <i className="fa-solid fa-person-circle-check"></i>
                                {num2 ? <h5><span>+{num2}</span>новых сотрудников за месяц</h5> : <h5><span>+30</span>новых сотрудников за месяц</h5> }
                            </div>
                            <div className={styles.ele}>
                                <i className="fa-solid fa-infinity"></i>
                                <h5><span>{num3}</span> лет на рынке</h5>
                            </div>
                            <div className={styles.ele}>
                                <i className="fa-solid fa-people-roof"></i>
                                <h5><span>{num4}</span>сотрудников</h5>
                            </div>
                        </div>
                    </div>
                    <div className={styles.izo}>
                        <img alt={'srsu'} src={'/files/assets/srsu.png'}/>
                    </div>
                </div>
                <div className={styles.carousel} ref={carouselRef}>
                    {companies.map((image, index) => (
                        <div key={index} className={styles.carouselimage}>
                            <img alt={image} src={`/files/assets/${image}`} />
                        </div>
                    ))}
                </div>
                {/**/}
                <div className={styles.carouselbuttons}>
                    <i onClick={scrollNext} className={`${styles.navir} fa-solid fa-chevron-right`}></i>
                    <i onClick={scrollPrev} className={`${styles.navil} fa-solid fa-angle-left`}></i>
                </div>

                <p className={styles.subtitle}>История компании</p>
                <div
                    ref={timelineRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    className={styles.timelineContainer}>
                    {events.map((item, index) => (
                        <div className={`${styles.timelineItem} ${index % 2 === 0 ? styles['odd'] : styles['even']} ${styles[`color${index+1}`]} ${styles.fadein}`} key={index}>
                            <div className={styles.date}>{item.date}</div>
                            <div className={styles.iconContainer}>
                                {item.icon}
                            </div>
                            <div className={styles.linecontainer}>
                                <div className={styles.line}></div>
                            </div>
                            <div className={styles.center}>
                                <div className={styles.circle}></div>
                            </div>
                            <div className={styles.content}>
                                <h3 className={styles.itemTitle}>{item.title}</h3>
                                <p className={styles.description}>{item.description}</p>

                            </div>
                        </div>
                    ))}
                </div>
                {loading ? (<LoadingSpinner/>) : null}
            </div>
        </div>




    )
}
export default AboutUsPage