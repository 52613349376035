import React, {useEffect, useState} from "react";
import s from "./Module.NaturePower.scss"
import {MainHeader} from "../../components/header/Mainheader";
import {Mainnavbar} from "../../components/navbar/Mainnavbar";
import UserService from "../../services/UserService";

export const NaturePower = () => {
    const handleDownload = async (name,file) => {
        const link = document.createElement('a')
        link.href = `${process.env.PUBLIC_URL}${file}`
        link.download = name
        await UserService.sliderClick(true)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const handlePrint = async (file) => {
        const printWindow = window.open(`${process.env.PUBLIC_URL}${file}`, '_blank');
        await UserService.sliderClick(true)
        printWindow.onload = () => {
            printWindow.focus()
            printWindow.print()
        }
    }
    return (
        <div className='new_container'>
            <div className="up_path"><MainHeader /></div>
            <div className="main_path">
                <Mainnavbar />
                <div className="workpage_block">
                    <div className="naturepower">
                        <div className={`baner`} style={{backgroundImage: `url('/news/contest/yearofhealth-min.jpg')`, backgroundColor: 'rgba(255,255,255,0.9)'}}></div>
                        <div className={`texts`}>
                            <h4 className={`b w`}>Уважаемые коллеги,</h4>
                            <p className={`b`}>Мы&nbsp;рады объявить Год здоровья в&nbsp;&laquo;Сургутском РСУ&raquo;.</p>
                            <p>Наш пантер&nbsp;&mdash; медицинский центр &laquo;Натур-Сила&raquo; в&nbsp;рамках программы &laquo;Год здоровья в&nbsp;РСУ&raquo; предлагает спектр медицинских процедур направленных на&nbsp;восстановление энергии и&nbsp;повышение жизненного тонуса Вас и&nbsp;Ваших детей.</p>
                            <p>Для сотрудников ООО &laquo;Сургутское РСУ&raquo; предусмотрена особая программа лояльности (при предъявлении доступа к&nbsp;личному кабинету корпоративного портала &laquo;Сургутское РСУ&raquo;).</p>
                            <p>Общая аэрокриотерапия (криосауна) как современный и&nbsp;эффективный метод, который может не&nbsp;только укрепить ваше здоровье, но&nbsp;и&nbsp;подарить вам заряд энергии, улучшить самочувствие и&nbsp;повысить работоспособность.</p>
                            <p>Суть криотерапии заключается в&nbsp;воздействии на&nbsp;организм холодным воздухом с&nbsp;температурой &minus;120-150&deg;C&nbsp;на протяжении 2-3&nbsp;минут. Несмотря на&nbsp;столь низкую температуру, процедура совершенно безопасна и&nbsp;даже приятна. Она оказывает множество положительных эффектов на&nbsp;организм, среди которых:</p>
                            <ul>
                                <li><p><span>Противовоспалительное и&nbsp;обезболивающее действие&nbsp;&mdash;</span> помогает при артритах, болях в&nbsp;спине и&nbsp;суставах.</p></li>
                                <li><p><span>Улучшение микроциркуляции и&nbsp;обмена веществ&nbsp;&mdash;</span> способствует омоложению кожи, снижению веса и&nbsp;борьбе с&nbsp;целлюлитом.</p></li>
                                <li><p><span>Повышение иммунитета&nbsp;&mdash;</span> ваши дети будут болеть реже, а&nbsp;обострения хронических заболеваний ЛОР-органов отойдут на&nbsp;второй план.</p></li>
                                <li><p><span>Антистрессовый и&nbsp;антидепрессивный эффект</span>&nbsp;&mdash; криотерапия помогает справиться с&nbsp;усталостью, стрессом и&nbsp;улучшить настроение.</p></li>
                                <li><p><span>Реабилитация и&nbsp;восстановление после спортивных нагрузок</span>&nbsp;&mdash; идеальное решение для тех, кто активно занимается спортом или переживает последствия спортивных травм.</p></li>
                            </ul>
                            <p className={`b`}>Особое внимание детям!</p>
                            <p>Мы&nbsp;понимаем, как важно заботиться о&nbsp;здоровье наших детей. Поэтому для детей сотрудников в&nbsp;возрасте от&nbsp;7&nbsp;до&nbsp;14&nbsp;лет действует специальная скидка&nbsp;15% на&nbsp;процедуры криотерапии. Благодаря криотерапии, ваши дети смогут укрепить иммунитет, реже болеть и&nbsp;быстрее восстанавливаться после физических нагрузок. Это идеальный способ поддержать здоровье вашего ребенка в&nbsp;течение всего года!</p>
                            <p className={`b`}>Для взрослых сотрудников действует скидка 10%!</p>
                            <p>Криотерапия будет полезна и&nbsp;для взрослых. Процедуры помогут справиться с&nbsp;хронической усталостью, улучшить обмен веществ и&nbsp;подарить вам свежесть и&nbsp;бодрость на&nbsp;каждый день.</p>
                            <p className={`b`}>Как записаться?</p>
                            <p>Записаться на&nbsp;процедуры можно в&nbsp;центре &laquo;Натур-Сила&raquo; в&nbsp;любое удобное для Вас время. Для вашего удобства мы&nbsp;предлагаем бесплатную консультацию со&nbsp;специалистом, который поможет подобрать оптимальный курс процедур, учитывая ваши индивидуальные потребности и&nbsp;здоровье.</p>
                            <p>Телефон для записи на&nbsp;консультацию<span>8-922-652-43-43.</span></p>
                            <p className={`b`}>Не&nbsp;упустите шанс улучшить свое здоровье и&nbsp;здоровье ваших близких! Сделайте первый шаг к&nbsp;лучшему самочувствию уже сегодня.</p>
                            <div className={`kupons`}>
                                <div className={`sleva`}>
                                    <img alt={`Купон на скидку 10%`} src={`/news/contest/kupon10.jpg`}/>
                                    <i onClick={() => handleDownload('kupon10.jpg','/news/contest/kupon10.jpg')} className="fa-solid fa-download"></i>
                                    <i onClick={() => handlePrint('/news/contest/kupon10.jpg')}  className="fa-solid fa-print"></i>
                                </div>
                                <div className={`sleva`}>
                                    <img alt={`Купон на скидку 15%`} src={`/news/contest/kupon15.jpg`}/>
                                    <i onClick={() => handleDownload('kupon15.jpg','/news/contest/kupon15.jpg')}  className="fa-solid fa-download"></i>
                                    <i onClick={() => handlePrint('/news/contest/kupon15.jpg')}  className="fa-solid fa-print"></i>
                                </div>

                            </div>

                            <p>Присоединяйтесь к&nbsp;Году здоровья в&nbsp;Сургутском РСУ и&nbsp;подарите себе и&nbsp;своим детям силу природы с&nbsp;криотерапией в&nbsp;центре &laquo;Натур-Сила&raquo;!</p>
                            <p className={`b`}>Следите за&nbsp;нашими новостями и&nbsp;последующими этапами программы &laquo;Год здоровья&raquo;.</p>
                            <h4 className={'b w'}>С&nbsp;заботой о&nbsp;Вас, команда ООО &laquo;Сургутское РСУ&raquo;.</h4>
                        </div>



                    </div>
                </div>
            </div>
        </div>

    )
}