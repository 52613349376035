import React, {useEffect, useState} from "react";
import s from './BlackSelect.module.scss'

export default function BlackSelect({icon,options,value,setValue,Width='250px',onsearch=false,disable=false}){
    const [active,setActive] = useState(false)
    const [search,setSearch] = useState('')
    const [filteredOptions, setFilteredOptions] = useState(options)

    const searchHandler = (e=null) => {
        const value = e ? e.target.value : ''
        setSearch(value)
        if (value.length){
            const filtered = filteredOptions.filter(item => item.label.toLowerCase().includes(value.toLowerCase()) )
            setFilteredOptions(filtered)
        }else {
            setFilteredOptions(options)
        }
    }
    const clearHandler = () => {
        searchHandler()
    }
    const openHandler = () => {
        searchHandler()
        if(!disable) setActive(!active)
    }
    const selectHandler = (item) => {
        setActive(false)
        setValue(item)
    }
    useEffect(() => {
        setFilteredOptions(options)
    },[])
    return(
        <>
            <div className={`${s.blackselect} ${disable && s.disable} `}>
                <div onClick={openHandler} className={s.helmet}>
                    <i className={icon}></i>
                </div>
                {active &&
                <div className={s.menu} style={{width:Width}}>
                    <div className={s.quit}>
                         <div className={s.search}>
                             {onsearch ?
                             <div>
                                 <input type={'text'} value={search} onChange={searchHandler}/>
                                 <i onClick={clearHandler} className={`fa-solid fa-xmark ${s.close} `}></i>
                             </div> : null}
                        </div>
                        <i onClick={openHandler} className={`fa-solid fa-xmark ${s.quiti}`}></i>
                    </div>
                    <div className={s.list}>
                        {filteredOptions && filteredOptions.map( (item,index) => ( <div key={index} onClick={() => selectHandler(item)} className={`${s.row} ${ value===item.label && s.active }`}>{item.label}</div> ))}
                    </div>
                </div>
                }

            </div>
        </>
    )
}
