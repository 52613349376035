import './fixers.scss'
import React, {useEffect, useState} from "react";

import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {observer} from "mobx-react-lite";
import AuthService from "../../services/AuthService";



function Repass(){
    const [loading,setLoading] = useState(false)
    const [repasses,setRepasses] = useState(null)
    const loadingHandler = async () => {
        try {
            setLoading(true)
            const {data} = AuthService.getRepasses()
            if(data){
                console.log(data)
                setRepasses(data)
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadingHandler()
    },[])
    return (
        <div className={`register`}>
            <h4>Заявки на восстановление пароля</h4>
            <div>

            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}

export default observer(Repass)