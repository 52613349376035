import {MainHeader} from "../../components/header/Mainheader";
import {WorkPage} from "../../components/workpage/WorkPage";
import {Mainnavbar} from "../../components/navbar/Mainnavbar";
import {useContext, useEffect, useState} from "react";
import {DataContext} from "../../context/DataContext";
import React from "react";
import DriveOnProject from "../../components/objs/DriveProject";
import {observer} from "mobx-react-lite";
import ObjsService from "../../services/ObjsService";
import {useLocation} from "react-router-dom";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {Context} from "../../index";
import {TabelNewMY} from "../../components/tabletabel/TabelMY";
import {TimeSheepPortal} from "../../components/tabletabel/TimeSheep";
import pointSV from "../fixers/PointSV";
import {WelThisObj} from "../../components/welding/yearmounth/WelThisObj";
import {TabelformNew} from "../../components/welding/tabelwelding/TabelformNew";
import {TabelformOld} from "../../components/welding/tabelwelding/TabelformOld";
import {ControllWeldingNew} from "../../components/welding/control/ControllWeldingNew";
import {Svodka} from "../../components/tabletabel/Svodka";
import {useIsDesktop} from "../../hooks/useIsDesktop";

function ObjectsRouter({page,path=null}){
    const {store} = useContext(Context)
    const isDesktop = useIsDesktop()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const objectID = searchParams.get('shifr') || searchParams.get('id') || searchParams.get('id_object')

    const [loading,setLoading] = useState(false)
    const [accessUsers,setAccessUsers] = useState(null)
    const loadingPage = async () => {
        try{
            setLoading(true)
            const {data} = await ObjsService.dataOfObj({idobj: objectID})
            console.log(data)
            console.log(store.pointsv)
            setAccessUsers(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const canSeeTab = () => accessUsers ? (!!store.user.unit) && accessUsers.some(user => (user.user_id === store.user.id && user.type === "tabel")) : false
    const canSeeSv = () => accessUsers ? (store.pointsv) && accessUsers.some(user => (user.user_id === store.user.id && user.type === "sv" )) : false

    const NotAccess = () => <div className={`notacceses`}>У Вас нет прав на просмотр данного ресурса.</div>

    useEffect(() => {
        loadingPage()
    },[])

    return (
        <div className='new_container'>
            <div className="up_path"><MainHeader /></div>
            <div className="main_path">
                <Mainnavbar path={path}/>
                {canSeeTab() || canSeeSv() ?
                    <>
                        {page === 1 && <WorkPage data={<DriveOnProject/>}/>}
                        {page === 2 && ( canSeeTab() ? <WorkPage data={<TabelNewMY/>}/> : <NotAccess/>)}
                        {page === 3 && ( canSeeTab() ? <WorkPage data={<TimeSheepPortal/>}/> : <NotAccess/>)}
                        {page === 4 && ( canSeeTab() ? <WorkPage data={<Svodka/>}/> : <NotAccess/>)}

                        {page === 11 && ( canSeeSv() ? <WorkPage data={<WelThisObj/>}/> : <NotAccess/>)}
                        {page === 12 && ( canSeeSv() ? isDesktop ? <WorkPage data={<TabelformNew/>} path={`welding`}/> : <WorkPage data={<TabelformOld/>}/> : <NotAccess/>)}
                        {page === 13 && ( canSeeSv() ? <WorkPage data={<ControllWeldingNew/>}/> : <NotAccess/>)}
                    </>
                    :
                    <NotAccess/>
                }
            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}

export default observer(ObjectsRouter)