import {MainHeader} from "../../components/header/Mainheader";
import {WorkPage} from "../../components/workpage/WorkPage";
import {Mainnavbar} from "../../components/navbar/Mainnavbar";

import {useEffect, useState} from "react";
import StructurePage from "./StructurePage";
import StructurePageNew from "./StructurePageNew";
import StructureDeps from "./StructureDeps";

// const pages = 2


export default function StructureRouter(){

    const [pages, setPages] = useState(3)

    return (
        <div className='new_container'>
            <div className="up_path"><MainHeader /></div>
            <div className="main_path">
                <Mainnavbar />
                {pages === 1 && <WorkPage data={<StructurePage pages={pages} setPages={setPages}/>}/>}
                {pages === 2 && <WorkPage data={<StructurePageNew pages={pages} setPages={setPages}/>}/>}
                {pages === 3 && <WorkPage data={<StructureDeps pages={pages} setPages={setPages}/>}/>}
            </div>
        </div>
    )
}
