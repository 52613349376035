import React, {useState,useContext} from "react";
import "./wel.scss"
import {Link} from "react-router-dom";
import {Context} from "../../index";
import {useMessage} from "../../hooks/message.hook";
import {ToastContainer} from "react-toastify";
import {DatePicker} from "rsuite";
import isCorrectLoginOnly from "../../components/functions/isCorrectLoginOnly";
import isCorrectInn from "../../components/functions/isCorrectInn";
import AuthService from "../../services/AuthService";
import { useNavigate } from 'react-router-dom';

export function Repass(){
    const [login, setLogin] = useState('')
    const [date, setDate] = useState('')
    const [inn, setInn] = useState('')
    const [phone, setPhone] = useState('')
    const [finish,setFinish] = useState(false)
    const [empty,setEmpty] = useState(false)
    const [exist,setExist] = useState(false)

    const navigate = useNavigate()
    const {store} = useContext(Context)
    const message = useMessage()

    const handlePhoneChange = (event) => {
        const value = event.target.value
        const cleanNumber = value.replace(/\D+/g, '')
        let formattedNumber = '+7 '
        if (cleanNumber.length > 1) {
            formattedNumber += cleanNumber.slice(1)
                .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')
                .trim()
        }
        if (formattedNumber.length <= 16) {
            setPhone(formattedNumber)
        }
    }
    const check = () => {
        const correctLogin = isCorrectLoginOnly(login)
        const correctInn = isCorrectInn(inn)
        if(correctLogin.err){
            message(correctLogin.message)
            return false
        }else{
            if(correctInn.err){
                message(correctInn.message)
                return false
            }else {
                if(phone.trim().length !== 16){
                    message('Не корретный номер телефона')
                    return false
                }
            }
        }
        setEmpty(false)
        return true
    }
    const RepassHandler = async (event) => {
        //event.preventDefault()
        if (check()){
            const {data} = await AuthService.repass(login,date,inn,phone)
            console.log(data.message)
            if(data){
                message(data.message)
                if(data.err){
                    setEmpty(true)
                    if(data.exist){
                        setExist(true)
                        setFinish(true)
                    }
                }else{
                    setEmpty(false)
                    setFinish(true)
                }
            }
        }
    }
    return (
        <div className='welcomepage'>
            <div className='imgback'>
                <div className="auth_back">
                    <div className="auth_form">
                        <div className="auth_line">
                            <div className="logo"></div>
                            <div className="title_back">Восстановление<br />пароля</div>
                        </div>
                        {!finish ?
                            <div className="inauth">
                                <label>Введите логин</label>
                                <input className="false" type="text" value={login}
                                       onChange={(e) => setLogin(e.target.value)} placeholder="Логин:"
                                       autoComplete="off"/>
                                <label>Дата рождения (пр. 01.01.1901)</label>
                                <input className="false" type="text" value={date}
                                       onChange={(e) => setDate(e.target.value)} placeholder="Дата рождения:"
                                       autoComplete="off"/>
                                <label>Введите Ваш ИНН</label>
                                <input className="false" type="text" value={inn}
                                       onChange={(e) => setInn(e.target.value)} placeholder="Инн:" autoComplete="off"/>
                                <label>Номер телефона для связи</label>
                                <input type="tel" value={phone} onChange={handlePhoneChange}
                                       placeholder="+7 XXX XXX XX XX"/>
                                <div onClick={(e) => RepassHandler(e)} className="button">Продолжить</div>
                                <Link to={'/'} className="first-link">Вы вспомнили пароль?</Link>
                            </div>
                            :
                            <div className="inauth">
                                {exist ? <label>Вы уже подавали заявку на восстановление.Ожидайте пожалуйста, Служба поддержки свяжется с Вами в рабочее время.</label> : <label>Вы успешно подали заявку на восстановления пароля. Ожидайте, пожалуйста. Служба поддержки свяжется с Вами в течении 2х часов в рабочее время.</label>}
                                <Link to={'/'} className="first-link">Вернуться на страницу входа</Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}