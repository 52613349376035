import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite"
import "../econom/ogm.scss"
import '../editor/editor.scss'
import LoadingSpinner from "../../components/loading/LoadingSpinner"
import OdataService from "../../services/OdataService";

function SyncToUat1C(){
    const [loading,setLoading] = useState(false)
    const [struct,setStruct] = useState(null)

    const getStructHandler = async () => {
        try {
            cancelHandler()
            setLoading(true)
            const {data} = await OdataService.getStructure()
            setStruct(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const loadingHandler = async () => {
        try {
            setLoading(true)
            const {data} = await OdataService.getUatStructure()
            console.log(data)
            setStruct(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const cancelHandler = () => {
        setStruct(null)
    }
    useEffect(() => {
        cancelHandler()
        loadingHandler()
    },[])
    return (
        <>
            <div>
                <h1 style={{marginBottom:'10px'}}>OData UAT</h1>

            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </>
    )
}
export default observer(SyncToUat1C)