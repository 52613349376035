import React,{useEffect, useState} from "react";

import {MainHeader} from "../../components/header/Mainheader";
import {WorkPage} from "../../components/workpage/WorkPage";
import {Mainnavbar} from "../../components/navbar/Mainnavbar";
import MedicialMenu from "./MedicialMenu";
import Medosmotry from "./Medosmotry";
import PriMedosmotry from "./PriMedosmotry";
export default function MedicialRouter({page= 1}){
    return (
        <div className='new_container'>
            <div className="up_path"><MainHeader /></div>
            <div className="main_path">
                <Mainnavbar />
                {page === 1 && <WorkPage data={<MedicialMenu />}/>}
                {page === 2 && <WorkPage data={<Medosmotry />}/>}
                {page === 3 && <WorkPage data={<PriMedosmotry />}/>}
            </div>
        </div>
    )
}
