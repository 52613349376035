import React,{useContext} from "react";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function StatusIcon2({tn}){
    const {store} = useContext(Context)
    return (
        <>
            {store.userday[tn] === 'Б' ? <i className={`status-icon1 fa-solid fa-staff-snake`}></i> : null}
            {store.userday[tn] === 'ОТ' || store.userday[tn] === 'ДО' || store.userday[tn] === 'ОТ/У' || store.userday[tn] === 'У' || store.userday[tn] === 'ОД' ? <div className={'status-icon1 status-image'}/> : null}
            {store.userday[tn] === 'ОЖ' || store.userday[tn] === 'Р'  ? <i className={`status-icon1 fa-solid fa-baby`}></i> : null}
        </>
    )
}
export default observer(StatusIcon2)