export default function getParticipantsString(number) {
    // Преобразуем число в строку, чтобы удобно извлекать последние цифры
    const numberString = number.toString();
    const lastDigit = numberString.slice(-1);
    const lastTwoDigits = numberString.slice(-2);

    let result;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
        result = `${number} участников`;
    } else if (lastDigit === '1') {
        result = `${number} участник`;
    } else if (lastDigit >= '2' && lastDigit <= '4') {
        result = `${number} участника`;
    } else {
        result = `${number} участников`;
    }

    return result;
}
