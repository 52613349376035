import React, {useContext, useEffect, useState} from "react";
import "./tabel.scss";
import ObjsService from "../../services/ObjsService";
import {Context} from "../../index";
import {Link} from "react-router-dom";
import ModalFiles from "../modalwin/ModalFiles";
import {AllTabelSettBTN} from "./modalactive/AllTabelSettBTN";
import LoadingSpinner from "../loading/LoadingSpinner";
import CmsSelect from "../inputs/CmsSelect";
import {DataContext} from "../../context/DataContext";
import CheckBox from "../inputs/CheckBox";



export const AllTabelsList = () => {

    let now = new Date()
    let nowYear = now.getFullYear()
    let nowMonth = now.getMonth()

    const {optionsMonth,optionsYear2} = useContext(DataContext)

    const [selectedMonth, setSelectedMonth] = useState(null)
    const [selectedYear, setSelectedYear] = useState(optionsYear2.find(year => year.value === nowYear))

    const {store} = useContext(Context)
    const inn = store.user.inn

    const [objs, setObjs] = useState([])
    const [alltabels, setAlltabels] = useState([])
    const [list, setList] = useState([])
    const [sett, setSett] = useState(false)
    const [idtabel, setIdtabel] = useState('no')
    const [loading,setLoading] = useState(false)
    const [onEmptyTabels,setOnEmptyTabels] = useState(false)

    const makealltebels = async () => {
        try{
            setLoading(true)
            const {data} = await ObjsService.getObjs({inn})
            const tabels = await ObjsService.getTabelsForAll({year: ''+selectedYear.label, inn})
            const list = tabels.data
            setObjs(data)
            setAlltabels(list)
        }catch(e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const combineObj = () => {
        const newlist = []
        objs.forEach(obj => {
            obj.tabels = []
            alltabels.forEach(tab => {
                if(obj.id === tab.object_id){
                    obj.tabels.push(tab)
                }
            })
            newlist.push(obj)

        })

        setList(newlist)
    }
    const cancelHandler = () => {
        setSelectedMonth(null)
        setSelectedYear(optionsYear2.find(year => year.value === nowYear))
        setOnEmptyTabels(false)
    }
    useEffect(()=>{
        makealltebels()
    }, [selectedYear])

    useEffect(()=>{
        combineObj()
    }, [objs,alltabels])

    return (
        <div className='itogy_form'>
            <div className="title">Итоговый табель</div>
            <div className="filter">
                <div className={`selects`}>
                    <CmsSelect value={selectedMonth} onChange={setSelectedMonth} options={optionsMonth} placeholder={`Выберите месяц`}/>
                    <CmsSelect value={selectedYear} onChange={setSelectedYear} options={optionsYear2} placeholder={`Выберите год`} />
                </div>
                <CheckBox checked={onEmptyTabels} label={`Отображать пустые обьекты?`} onChange={setOnEmptyTabels} />
                <div onClick={cancelHandler} className={`check`}>сбросить</div>

            </div>
            <div className="alltabels">
                {list.map((obj, index)=>(
                    <>
                        { ( obj.tabels && obj.tabels.length ) || onEmptyTabels ?
                            <>
                                {!selectedMonth || (selectedMonth && obj.tabels.findIndex(o => o.month === selectedMonth.value) !== -1 ) ?
                                <div key={index} className='alltabels_years'>
                                    <div className='alltabels_years_head'>{obj.shifr}</div>
                                    <div className='alltabels_years_body'>
                                        {obj.tabels.map((tabels, index) => (
                                            <>
                                                {!selectedMonth || (selectedMonth && selectedMonth.value === tabels.month) ?
                                                    <>
                                                        <div key={index} className='alltabels_years_body_month' style={(tabels.auto === 1)?{border: '4px solid #454545'}:{color: '#CCC'}}>
                                                            <Link to={`/thistabelportal/?id=${index}&shifr=${obj.id}&month=${tabels.month}&year=${tabels.year}`} className='alltabels_years_body_month_text'>{optionsMonth[tabels.month].label} {tabels.year}</Link>
                                                            <div className='alltabels_years_body_month_settings' onClick={()=>{setIdtabel([obj, tabels]); setSett(!sett)}}> ... </div>
                                                        </div>
                                                    </>
                                                    : null }

                                            </>
                                        ))}
                                    </div>
                                </div>
                            : null }
                            </>

                            : null }
                    </>
                ))}
            </div>
            {loading ? (<LoadingSpinner/>) : null}
            <ModalFiles data={<AllTabelSettBTN func={makealltebels} tab={idtabel} active={sett} setActive={setSett}/>}  active={sett} setActive={setSett}/>
        </div>
    )
}