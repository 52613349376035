import React, {useEffect, useRef, useState} from 'react';
import { ReactMic } from 'react-mic';
import style from './Rec.module.scss'
import MessagesService from "../../services/MessagesService";
import {useContext} from "react";
import {Context} from "../../index";
import {useMessage} from "../../hooks/message.hook";
import CustomAudioPlayer from "../../components/inputs/CustomAudioPlayer";
const Rec = ({openRec,setOpenRec,record,setRecord,audio,setAudio,send}) => {
    const [onRecord, setOnRecord] = useState(false)
    const message = useMessage()
    const start = () => {
        setRecord(prev => !prev)
        setOnRecord(prev => !prev)
   }
    const onData = (recordedBlob) => {
        console.log('Chunk of real-time data is: ', recordedBlob)
    };
    const onStop = (recordedBlob) => {
        console.log('Recorded blob is: ', recordedBlob)
        setAudio(recordedBlob)
    }
    const clearHandler = () => {
        setOpenRec(false)
        setRecord(false)
        setOnRecord(false)
        setAudio(null)
    }
    const sendRecord = async () => {
        setOpenRec(false)
        setRecord(false)
        setOnRecord(false)
        if(audio) send()
    }
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop())
                if(record){
                    setOnRecord(true)
                }
            })
            .catch(err => {
                setOnRecord(false)
                if(openRec){
                    console.log(err)
                    message('Микрофон не найден.')
                    clearHandler()
                }
            })

    }, [record])
    return (
        <>

                <div style={ (openRec) ? {display:"flex"} : {display:'none'}} className={style.rec}>
                    <div onClick={clearHandler} className={style.glass}><i style={{position: 'absolute'}} className={`${style.exit} fa-solid fa-xmark`}></i></div>
                    <div className={style.spherevoice}>
                        <div onClick={start}>
                            <ReactMic
                                record={onRecord}
                                className={style.soundwave}
                                onStop={onStop}
                                onData={onData}
                                strokeColor="rgb(18, 19, 56)"
                                backgroundColor="#F1F1F1"
                                onBlock={() => console.log('Permission to use microphone was denied')}
                                onStart={() => console.log('Recording started')}
                            />
                        </div>

                        {!record ? <div onClick={start} className={style.reload}><i className="fa-solid fa-rotate-left"/></div> : null}
                        <div onClick={start} className={style.tit}><i className="fa-solid fa-stop"></i> </div>
                        {audio && <div className={style.control}>
                            {/*<CustomAudioPlayer src={audio.blobURL}/>*/}
                            {/*<audio className={style.audio} src={audio.blobURL} controls/>*/}
                            <div className={style.history_mess_pen_btn} onClick={() => sendRecord()}> <i className="fa-regular fa-paper-plane"/></div>
                        </div>
                        }
                    </div>
                </div>
        </>

    )
}

export default Rec