import React from "react";
import Buttons from "../../components/economist/Buttons";

export default function CmsMenu(){
    return (
        <div className={`menu`}>
            <Buttons text={'Импорт из 1С'} icon={`fa-solid fa-file-import`} url={`/import1c`}/>
            <Buttons text={'Экспорт в 1С'} icon={`fa-solid fa-file-export`} url={`/export1c`}/>
            <Buttons text={'Тестирования'} icon={`fa-solid fa-wrench`} url={`/testing`}/>
        </div>
    )
}
