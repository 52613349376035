import { io } from "socket.io-client";
import {useState} from "react";
let socket

export const initSocket = () => {
    if (!socket) {
        socket = io.connect('https://srsuportal.ru:443', {
            withCredentials: true,
            auth: {
                token: `${localStorage.getItem('token')}`
            }
        })
        startInactivityTimer()
    }
    return socket
}
export const getSocket = () => {
    return socket
}
export const disconnectSocket = () => {
    if (socket) {
        console.log('disconnected from server')
        socket.disconnect()
        socket = null
    }
}

function startInactivityTimer() {
    let inactivityTimeout

    function resetInactivityTimer() {
        clearTimeout(inactivityTimeout)
        inactivityTimeout = setTimeout(() => {
            if (socket && socket.connected) {
                disconnectSocket()
                console.log('Socket closed: timeout.')
            }
        }, 15 * 60 * 1000 ) //  15 минут в миллисекундах
        if(!socket){
            console.log('reload')
            window.location.reload()
        }
    }

    resetInactivityTimer()

    // Сбросить таймер при любом действии пользователя
    document.addEventListener('mousemove', resetInactivityTimer)
    document.addEventListener('keypress', resetInactivityTimer)
}