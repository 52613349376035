import React, {useContext, useEffect, useRef, useState} from "react";
import {DataContext} from "../../context/DataContext";
import './structure.scss'
import './custom-select.scss';
import styles from './Deps.module.scss';
import {ReactToPrint} from "react-to-print";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import UserService from "../../services/UserService";
import PhonesService from "../../services/PhonesService";
import ModalFiles from "../../components/modalwin/ModalFiles";
import {useMessage} from "../../hooks/message.hook";
import MessagesService from "../../services/MessagesService";
import {getSocket} from "../../http/socket";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import CmsSelect from "../../components/inputs/CmsSelect";
import HistoryService from "../../services/HistoryService";
import getOnlineStatus from "../../components/functions/getOnlineStatus";
import {Link} from "react-router-dom";
import {MainHeader} from "../../components/header/Mainheader";
import {Mainnavbar} from "../../components/navbar/Mainnavbar";
import {WorkPage} from "../../components/workpage/WorkPage";
import StructurePage from "./StructurePage";
import StructurePageNew from "./StructurePageNew";
import Select from "react-select";
import StatusIcon from "../../components/icons/StatusIcon";
import StatusIcon2 from "../../components/icons/StatusIcon2";

function StructureDeps({pages, setPages}){
    const [branchs,setBranchs] = useState([])
    const [peoples,setPeoples] = useState([])
    const [users,setUsers] = useState([])
    const [win, setWin] = useState(false)
    const [online, setOnline] = useState(null)
    const [loading,setLoading] = useState(false)
    const [empty,setEmpty] = useState([])
    const [selected,setSelected] = useState(-1)
    const [activeSend,setActiveSend]= useState(false)
    const [activeContacts,setActiveContacts] = useState(false)
    const [activeList,setActiveList] = useState(false)
    const [thisMans, setThisMans] = useState([])
    const [listMans, setListMans] = useState([])
    const [contacts,setContacts] = useState([])
    const [listBranches, setListBranches] = useState([])
    const [openBranch, setOpenBranch] = useState([])
    const [selectedUser,setSelectedUser] = useState(null)
    const [messageText,setMessageText] = useState('')

    const [searching,setSearching] = useState(null)
    const [searchList,setSearchList] = useState([])

    const departs = [
        {
            label: 'Технический отдел',
            value: 'Технический отдел',
            option: 'Технический отдел',
            index: 0,
        },
        {
            label: 'Лаборатория неразрушающего контроля',
            value: 'Лаборатория неразрушающего контроля',
            option: 'Технический отдел',
            index: 1,
        },
        {
            label: 'Служба охраны труда',
            value: 'Служба охраны труда',
            option: 'Технический отдел',
            index: 2,
        },
        {
            label: 'ОТДЕЛ ГЕОДЕЗИИ',
            value: 'ОТДЕЛ ГЕОДЕЗИИ',
            option: 'Технический отдел',
            index: 3,
        },
        {
            label: 'ОТДЕЛ ГЛАВНОГО ЭНЕРГЕТИКА',
            value: 'ОТДЕЛ ГЛАВНОГО ЭНЕРГЕТИКА',
            option: 'Технический отдел',
            index: 4,
        },
        {
            label: 'СЛУЖБА ГЛАВНОГО СВАРЩИКА',
            value: 'СЛУЖБА ГЛАВНОГО СВАРЩИКА',
            option: 'Технический отдел',
            index: 5,
        },
    ]

    useEffect(()=>{
        setListMans(departs)
    }, [])

    const message = useMessage()
    const {store} = useContext(Context)
    const loadingHandler = async () => {
        try {
            setLoading(true)
            const {data} = await UserService.getStructure()

            const res_login = await UserService.getStatUsers()
            const res_users = await UserService.getWorkers()
            const res_contacts = await PhonesService.fetchPhones()
            const res_socket = await HistoryService.getSocketHistory()

            const logindata = res_login.data.users
            const usersdata = res_users.data
            const contactsdata = res_contacts.data
            const statusdata = res_socket.data
            console.log(statusdata)
            const tree = buildTree(data)

            const addDataToTree = (node, usersdata, contactsdata, logindata) => {
                if (node.structusers) {
                    node.structusers = node.structusers.map(user => {
                        let contact = ''
                        const userData = usersdata.find(u => u.tn === user.user_tn)
                        const contactsData = contactsdata.find(u => u.name === user.name)
                        const loginData = logindata.find(u => u.tn === user.user_tn)
                        if(contactsData){
                            contact = contactsData.mobile_phone.length ? contact + 'Мобильный номер телефона : ' + contactsData.mobile_phone + '\n' : contact
                            contact = contactsData.city_phone.length ? contact + 'Городской номер телефона : ' + contactsData.city_phone + '\n' : contact
                            contact = contactsData.ats.length ? contact + 'Номер АТС : ' + contactsData.ats + '\n' : contact
                            contact = contactsData.email.length ? contact + 'E-Mail адрес : ' + contactsData.email + '\n' : contact
                        }
                        return {
                            ...user,
                            developer: userData ? userData.developer : null,
                            phonebook: contact.length ? contact : null,
                            avatar: loginData ? loginData.avatar : 'null',
                            user_id: loginData ? loginData.id : 'null',
                            registered: !!loginData
                        }
                    })
                }
            }
            tree.forEach(branch => addDataToTree(branch, usersdata, contactsdata,logindata))

            const userlist = res_users.data.map(item => {
                const contactsData = contactsdata.find(u => u.name === item.name)
                const loginData = logindata.find(u => u.tn === item.tn)
                const statusData = statusdata.find(u => u.name === item.name)

                const status = getOnlineStatus(item.gender,statusData ? statusData.maxCreatedAt : null)

                return {...item,label: item.name + ' | ' + item.developer + ' | ' + item.branch,ats:contactsData ? contactsData.ats : null,avatar:loginData && loginData.avatar ? loginData.avatar : 'face.png',registered:!!loginData, status: status,user_id:loginData && loginData.id ? loginData.id : 308}
            })

            setSearchList(userlist)
            setContacts(contactsdata)
            setUsers(logindata)
            setBranchs(tree)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const setListHandler = async (id) => {
        try{
            setLoading(true)
            const {data} = await UserService.fetchWorkersBranch(id)
            const res_login = await UserService.getStatUsers()
            const logindata = res_login.data.users
            if(data.length) {
                const node = data.map(people => {
                    const contactsData = contacts.find(u => u.name === people.name)
                    const loginData = logindata.find(u => u.tn === people.tn)
                    return {
                        ...people,
                        user_tn: people.tn,
                        ats: contactsData ? contactsData.ats : null,
                        avatar: loginData ? loginData.avatar : null,
                        user_id: loginData ? loginData.id : null,
                        registered: !!loginData,
                        email: ''
                    }
                })

                setPeoples(node)
                setSelected(id)
                setOpenBranch(node)
                setActiveList(true)
                console.log(node)
            }else{
                message('Записи отсутствуют')
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }

    }

    const onContactHandler = (user) => {
        console.log(user)
        setSelectedUser(user)
        setMessageText('')
        setActiveContacts(true)
    }
    const onSendHandler = (user) => {
        setSelectedUser(user)
        console.log(user)
        setMessageText('')
        setActiveSend(true)
    }
    const checkEmpty = () => {
        const n = [...empty]

        n[0] = !!!messageText.trim().length

        const hasTrueValue = n.some(value => value === true);
        if( hasTrueValue ) setEmpty(n)
        else setEmpty([])
        return hasTrueValue
    }
    const cancelHandler = () => {
        setActiveList(false)
        setActiveContacts(false)
        setSelectedUser(null)
        setActiveSend(false)
        setMessageText('')
    }
    const cancelSearch = () => {
        setSearching(null)
    }
    useEffect(() => {
        loadingHandler()
        const socket = getSocket()
        socket.emit('online', {data:'get online users'},(response) => {
            setOnline(response)
        })
    },[])

    function buildTree(data, parentId = 5,node=[]) {
        const start = data.find(item => item.id === parentId)
        if(start) {
            node.push(start)
            if(start.next){
                start.next.map(item => buildTree(data,item,node))
            }
        }
        return node
    }

    const selectHandler = (id) => {
        if(selected === id) setSelected(-1)
        else setSelected(id)
    }
    const sendMessage = async () => {
        try{
            if(!checkEmpty()){
                if(selectedUser){
                    setLoading(true)
                    const {data} = await MessagesService.sendNewMessage(selectedUser.user_id,messageText)
                    if(data) {
                        const socket = getSocket()
                        const data = {from:store.user.tn,from_name:store.user.full_name,to:selectedUser.user_tn,message:messageText}
                        socket.emit('message', data)
                        message('Сообщение отправлено')
                        cancelHandler()
                    }
                }else{
                    message('Выберете пользователя')
                }
            }else {
                message('Введите сообщение')
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }

    }
    const StartTree = ({start}) => {
        return (
            <>
                <div className="structure_new_forest_cuedo_card">
                    <div className="structure_new_forest_cuedo_card_top"></div>
                    <div className="structure_new_forest_cuedo_card_center">
                        {start.structusers ? start.structusers.map((item, index) => (
                            <div key={index} className="structure_new_forest_cuedo_card_center_content"
                                 style={(selected === start.id) ? {display: 'flex'} : {display: 'none'}}>
                                <div className="structure_new_forest_cuedo_card_center_content_person">
                                    <div className="structure_new_forest_cuedo_card_center_content_person_photo" style={{backgroundImage: `url(/files/profile/${item.avatar ? item.avatar : 'face.png'})`,position:'relative'}}>{online ? online.includes(item.tn) ? <i className="online2 fa-solid fa-circle"></i> : null : null}</div>
                                    <div onClick={() => onContactHandler(item)} className="structure_new_forest_cuedo_card_center_content_person_contact">Связаться</div>
                                    <div className="structure_new_forest_cuedo_card_center_content_person_disc">
                                        <div className="structure_new_forest_cuedo_card_center_content_person_disc_name">{item.name}</div>
                                        <div className="structure_new_forest_cuedo_card_center_content_person_disc_dev">{item.developer}</div>
                                    </div>
                                </div>
                            </div>
                        )) : null}
                        <div className="structure_new_forest_cuedo_card_center_button" onClick={() => selectHandler(start.id)}>
                            <div className="structure_new_forest_cuedo_card_center_button_slash"></div>
                            <div className="structure_new_forest_cuedo_card_center_button_slash"></div>
                            <div className="structure_new_forest_cuedo_card_center_button_slash"></div>
                        </div>
                    </div>
                    <div className="structure_new_forest_cuedo_card_bottom">{start.name}</div>
                </div>
                {start.next ? start.next.map((item,index) => {
                    return (
                    <span key={index}>
                        <Tree branch={branchs.find(u => u.id === item)} long={!!( (index+1)%2 )} />
                    </span>
                    )
                }):null}
            </>

        )
    }

    const Tree = ({branch,long=false}) => {
        const failLevel = branch.next.length ? !branchs.find(u => u.id === branch.next[0]).type : true
        const cardLevel = {
            2: 'structure_new_forest_cuedo_card',
            3: branch.next.length ? 'structure_new_forest_cuedo_card_two' : 'structure_new_forest_cuedo_card_tree',
            4: 'structure_new_forest_cuedo_card_tree',
            5: 'structure_new_forest_cuedo_card_tree',
        }
        const branchLevel = {
            2: failLevel ? 'two_cueda' : 'five_cueda',
            3: failLevel ? 'tree_cueda' : 'four_cueda',
            4: 'four_cueda',
            5: 'four_cueda',
        }
        return (
            <div>
                <div className={`${cardLevel[branch.level]} ${long && 'honest'}`}>
                    {!branch.type ?
                        <>
                            <div className="slash_rang"></div>
                            <div className="structure_new_forest_cuedo_card_top"></div>
                            <div className="structure_new_forest_cuedo_card_center">
                                <div className="structure_new_forest_cuedo_card_center_content" style={(selected===branch.id)?{display:'flex'}:{display:'none'}}>
                                    { (branch.structusers && !branch.type) ? branch.structusers.map( (item,index) => (
                                        <div key={index} className="structure_new_forest_cuedo_card_center_content_person">
                                            <div className="structure_new_forest_cuedo_card_center_content_person_photo" style={{backgroundImage: `url(/files/profile/${item.avatar ? item.avatar : 'face.png'})`,position:'relative'}}>{online ? online.includes(item.tn) ? <i className="online2 fa-solid fa-circle"></i> : null : null} <StatusIcon tn={item.tn}/></div>
                                            <div className="structure_new_forest_cuedo_card_center_content_person_contact" onClick={() => onContactHandler(item)}>Связаться</div>
                                            <div className="structure_new_forest_cuedo_card_center_content_person_disc">
                                                <div className="structure_new_forest_cuedo_card_center_content_person_disc_name">{item.name}</div>
                                                <div className="structure_new_forest_cuedo_card_center_content_person_disc_dev">{item.developer}</div>
                                            </div>
                                        </div>
                                    )):null}
                                </div>
                                <div className="structure_new_forest_cuedo_card_center_button" onClick={() => selectHandler(branch.id)}>
                                    <div className="structure_new_forest_cuedo_card_center_button_slash"></div>
                                    <div className="structure_new_forest_cuedo_card_center_button_slash"></div>
                                    <div className="structure_new_forest_cuedo_card_center_button_slash"></div>
                                </div>
                            </div>
                            <div className="structure_new_forest_cuedo_card_bottom">{branch.name}</div>
                        </> :
                        <span onClick={() => setListHandler(branch.id)}>
                            <div className="slash_rang"></div>
                            <div className="structure_new_forest_cuedo_card_top"></div>
                            <div className="structure_new_forest_cuedo_card_bottom">{branch.name}</div>
                        </span>}
                </div>

                {branch.next.length ?
                <div className={branchLevel[branch.level]}>
                    { branch.next.map( (item,index) => (
                        <span key={index}>
                            <Tree branch={branchs.find(u => u.id === item)} long={!!( (index)%2 )}/>
                        </span>
                    )) }
                </div>
                    : null}
            </div>
        )
    }

    const List = () => {
        return (
            <span>
                { (branchs.length && ( selected>=0 )) ?
                    <div className='glass'>
                        <div className="glass_board">
                            <div className="glass_board_close"><i className="fa-solid fa-xmark" onClick={()=>cancelHandler()}/></div>
                            <div className="glass_board_body">
                                <div className="glass_title">{branchs.find(u => u.id === selected).name}</div>
                                <div className="glass_list_persons">
                                {peoples.length ? peoples.map( (item,index) => (
                                    <div key={index}  className="glass_list_persons_man">
                                        <div className="glass_list_persons_man_photo" onClick={() => console.log(item)} style={{backgroundImage: `url(/files/profile/${item.avatar ? item.avatar : 'face.png'})`}}>{online.includes(item.tn) ?<i className="online1 fa-solid fa-circle"></i> : null}</div>
                                        <div className="glass_list_persons_man_name">{item.name}</div>
                                        <div className="glass_list_persons_man_dev">{item.developer}</div>
                                        <div className="glass_list_persons_man_branch">{item.branch}</div>
                                        <div className="glass_list_persons_man_phone">{item.ats ? `АТС - ${item.ats}` : null}</div>
                                        {item.registered ? <div onClick={() => onSendHandler(item)} className="glass_list_persons_man_btn">Написать</div> : <div>Не зарегестрирован</div> }
                                    </div>
                                    )) : null }

                                </div>
                            </div>
                        </div>
                    </div>
                : null}
            </span>
        )
    }
    const Contact = () => {
        return (
            <div className={'contact'}>
                {selectedUser ?
                    <>
                        <div onClick={() => console.log(selectedUser)} className={`title`}>Контактная информация</div>
                        <textarea disabled value={selectedUser.onphonebook ? (selectedUser.phonebook || 'Контактные данные отсутствуют') : (selectedUser.contacts || 'Контактные данные отсутствуют')} className={`contact-text`} />
                        <div className={`title`}>Отправить сообщение</div>
                        <div className={`message`}>
                            <textarea value={messageText} onChange={(e) => setMessageText(e.target.value)} className={`send-text ${empty[0] && 'red-solid-border'}`} />
                        </div>
                        <div className={`buttons`}>
                            <div onClick={() => sendMessage()} className={`button`}> Написать </div>
                        </div>
                    </>
                    : null}
            </div>
        )
    }
    const Send = () => {
        return (
            <div className={'contact'}>
                {selectedUser ?
                    <>
                        <div className={`title`}>Отправить сообщение</div>
                        <div className={`message`}>
                            <textarea value={messageText} onChange={(e) => setMessageText(e.target.value)} className={`send-text ${empty[0] && 'red-solid-border'}`} />
                        </div>
                        <div className={`buttons`}>
                            <div onClick={() => sendMessage()} className={`button`}> Написать </div>
                        </div>
                    </>
                    : null}
            </div>
        )
    }

    const getOnlyBranch = async () => {
        try{
            const {data} = await UserService.getOnlyBranch()
            const listname = []
            const newList = []
            data.map((branch, index)=>{
                if(!listname.includes(branch.name) && branch.name.toUpperCase() !== 'ПРОИЗВОДСТВЕННЫЙ УЧАСТОК №2' ){
                    if(branch.factbranchs !== null && branch.factbranchs !== '' && branch.factbranchs.length !== 0 && branch.name.toUpperCase() !== 'ПРОИЗВОДСТВЕННЫЙ УЧАСТОК №2' || branch.position === 4 || branch.position === 5 ){
                        branch.label = branch.name
                        branch.value = branch
                        branch.option = branch.id
                        branch.index = branch.id
                        newList.push(branch)
                        listname.push(branch.name)
                    }
                }

            })
            setListMans(newList)
            setListBranches(newList)

        }catch(e){
            console.log(e)
        }
    }



    useEffect(()=>{
        getOnlyBranch()

    }, [])

    useEffect(()=>{
        setListHandler(77)
    }, [listMans])

    return (
        <div className='structure_new'>
            <div className={`title`}>Организационная структура ООО "Сургутское РСУ"</div>
            <h4>Поиск сотрудников</h4>
            <CmsSelect value={searching} radius={'5px'} placeholder={'Введите текст для поиска'} onChange={setSearching} options={searchList} />
            {searching ? <>
                    <div className={`searching-card`}>
                        <div className="person">
                            <div className="photo" style={{backgroundImage: `url(/files/profile/${searching.avatar})`}}>{online.includes(searching.tn) ?<i className="online1 fa-solid fa-circle"></i> : <div className={`online-status`}>{searching.status}</div>} <StatusIcon tn={searching.tn}/></div>
                            <div className="text">{searching.name}</div>
                            <div className="text">{searching.developer}</div>
                            <div className="text">{searching.branch}</div>
                            <div className="text">{searching.ats ? searching.ats : null}</div>
                            {searching.registered ? <div onClick={() => onSendHandler(searching)} className="button">Написать</div> : <div className={`text`}>Не зарегестрирован</div>}
                        </div>
                    </div>
                <div onClick={(e) => cancelSearch()} className={`cancel-search`}>Сбросить поиск</div>
                </> : null}
            <div className="btnnavigate">
                <div onClick={()=>setPages(2)} className="btnnavigate_tree">Дерево</div>
                <div className="btnnavigate_deps active">По отделам</div>
            </div>
            <Select placeholder="Выбрать отдел" className="custom-select-container" classNamePrefix="custom-select" onChange={(e) => {setThisMans(listMans[e.index]);  setListHandler(e.id)}} value={thisMans} options={listMans} styles={{container:(baseStyles, state) => ({...baseStyles,})}}/>

            <div className={styles.new_structure}>
                <div className={styles.leftmenu}>
                    {listBranches.map((branch, index) => (
                        <div onClick={()=>setListHandler(branch.id)} key={index} className={styles.devstrock}>{branch.name}</div>
                    ))}

                </div>
                <div className={styles.deps}>
                    <div className={styles.superviser}>
                        <div className={styles.title}>Руководитель</div>
                        {openBranch.map((man, index) => {

                            if(index === 0 && man.branch !== 'Автоколонна №2' && man.branch !== 'Автоколонна №1' && man.branch !== 'Автоколонна №3'){
                                return (
                                    <div key={index} className={styles.block}>
                                        <div className={styles.ava} style={{backgroundImage: `url(/files/profile/${man.avatar ? man.avatar : 'face.png'})`,position:"relative"}}><StatusIcon tn={man.tn}/></div>
                                        <div className={styles.description}>
                                            <div className={styles.name}>{man.name}</div>
                                            <div className={styles.dev}>{man.developer}</div>
                                            <div className={styles.email}>эл.почта: {man.email}</div>
                                            <div className={styles.ats}>вн.телефон: {man.ats}</div>
                                            {/*<div className={styles.mobile}>моб.телефон: {man.mobile}</div>*/}
                                            <div className={styles.postbtn} onClick={() => onSendHandler(man)}>Написать</div>
                                        </div>
                                    </div>

                                )}})}

                        <div className={styles.title}>Сотрудники</div>

                        <div className={styles.otdel}>
                            {openBranch.map((man, index) => {

                                if(man.branch === 'Автоколонна №2' || man.branch === 'Автоколонна №1' || man.branch === 'Автоколонна №3'){
                                return (
                                <div key={index} className={styles.man}>
                                    <div className={styles.ava} style={{backgroundImage: `url(/files/profile/${man.avatar ? man.avatar : 'face.png'})`,position:'relative'}}><StatusIcon2 tn={man.tn}/></div>
                                    <div className={styles.description}>
                                        <div className={styles.name}>{man.name}</div>
                                        <div className={styles.dev}>{man.developer}</div>
                                        <div className={styles.email}>эл.почта: {man.email}</div>
                                        <div className={styles.ats}>вн.телефон: {man.ats}</div>
                                        {/*<div className={styles.mobile}>моб.телефон: {man.mobile}</div>*/}
                                        <div className={styles.postbtn} onClick={() => onSendHandler(man)}>Написать</div>
                                    </div>
                                </div>
                            )}

                                if(index !== 0){
                                    return (
                                        <div key={index} className={styles.man}>
                                            <div className={styles.ava} style={{backgroundImage: `url(/files/profile/${man.avatar ? man.avatar : 'face.png'})`,position:"relative"}}> <StatusIcon2 tn={man.tn}/></div>
                                            <div className={styles.description}>
                                                <div className={styles.name}>{man.name}</div>
                                                <div className={styles.dev}>{man.developer}</div>
                                                <div className={styles.email}>эл.почта: {man.email}</div>
                                                <div className={styles.ats}>вн.телефон: {man.ats}</div>
                                                {/*<div className={styles.mobile}>моб.телефон: {man.mobile}</div>*/}
                                                <div className={styles.postbtn} onClick={() => onSendHandler(man)}>Написать</div>
                                            </div>
                                        </div>
                                    )}

                            })}

                        </div>
                    </div>
                </div>

            </div>

            {/*<div className="structure_new_forest">*/}
            {/*    <div className="structure_new_forest_cuedo">*/}
            {/*        {branchs.length ?*/}
            {/*            <div className="structure_new_forest_cuedo_general">*/}
            {/*                <StartTree start={branchs[0]}/>*/}
            {/*            </div>*/}
            {/*            :null}*/}
            {/*        <div className="structure_new_forest_cuedo_dother"></div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<ModalFiles heigth={'auto'} data={Contact()} active={activeContacts} setActive={setActiveContacts} />*/}
            {/*<ModalFiles data={List()} active={activeList} setActive={setActiveList} />*/}
            <ModalFiles heigth={'auto'} data={Send()} active={activeSend} setActive={setActiveSend} />

            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}
export default observer(StructureDeps)