import React, {useContext, useEffect, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import "./soc.scss"
import {useMessage} from "../../hooks/message.hook";
import ObjsService from "../../services/ObjsService";
import Select from "react-select";
import SocialService from "../../services/SocialService";
import {Context} from "../../index";
import MessagesService from "../../services/MessagesService";
import CheckBox from "../../components/inputs/CheckBox";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {Link} from "react-router-dom";

function StatementsList(){
    const {store} = useContext(Context)
    const iam = store.user
    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)
    const [commission, setCommission] = useState(false)
    const [stazhedit, setStazhedit] = useState(false)
    const [thisuser, setThisuser] = useState([])
    const [listuser, setListuser] = useState([])
    const [editcash, setEditcash] = useState(false)
    const [createcash, setCreatecash] = useState(false)
    const [stazhcash, setStazhcash] = useState(false)
    const [readstatement, setReadstatement] = useState(false)
    const [maker, setMaker] = useState(false)
    const [comment, setComment] = useState('')
    const [resave, setResave] = useState(0)
    const [btnwrite, setBtnwrite] = useState(true)
    const [btnrefusal, setBtnrefusal] = useState(true)
    const [loading,setLoading] = useState(false)
    const [listza, setListza] = useState([])
    const [thisza, setThisza] = useState({})

    const [chk1, setChk1] = useState(false)
    const [chk2, setChk2] = useState(false)

    let summa = 0
    const [commission_ids, setCommission_ids] = useState([])
    const message = useMessage()
    const getUsers = async (e) => {
        try {
            const users = await ObjsService.getUsersList()

            let i = 0
            users.data.forEach(man => {
                man.label = man.full_name + '  ' + man.developer
                man.value = man.tn
                man.index = i
                i++
            })
            setListuser(users.data)
        }catch(e){

        }
    }

    const [list, setList] = useState([])
    const [zalist, setZAlist] = useState([])

    const chooseLine = (index, za) => {
        if(za.status === 'Согласовано'){
            let newList = [...list]
            let newZAlist = [...zalist]
            if(newList.includes(index)){
                newList = newList.filter(item => item !== index);
                newZAlist = newZAlist.filter(item => item[0] !== index);
            } else {
                newList.push(index)
                newZAlist.push([index, za])
            }

            setList(newList)
            setZAlist(newZAlist)
        }
    }

    const cleanList = () => {
        setList([])
        setZAlist([])
    }
    const getAllZa = async () => {
        try{
            setLoading(true)
            const zas = []
            const {data} = await SocialService.getAllZa()
            data.forEach(za => {
                const { 0: def = 0, 1: yes = 0, 2: no = 0 } = za.commission
                    .filter(man => man.possion === 1)
                    .reduce((counts, man) => {
                        counts[man.status] = (counts[man.status] || 0) + 1
                        return counts
                    }, {})

                za.status = za.protocolId ? 'Сформировано' :
                    no > 0 ? 'Отказано' :
                    yes > 0 && def > 0 ? 'На согласовании' :
                        def > 0 && yes === 0 ? 'Новая' :
                            yes > 0 && def === 0 ? 'Согласовано' :
                                'Неопределенный статус'
            })
            console.log(data)
            setListza(data)
        }catch(e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const handleDownload = async (doc) => {
        try {
            const response = await SocialService.downloadDoc({doc})
            if(response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', doc.docname)
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const getButton = (thisza, learn, btn) => {
        let itog = 'error'
        if(thisza.commission && btn===1){
            const newComs = thisza.commission
            newComs.map((man, index) => {
                if(man.user_tn === iam.tn && man.possion === 1 && man.status === 0){
                    if(learn === 'title'){
                        itog = 'Согласовать'
                    } else if (learn === 'display'){
                        itog = 'flex'
                    }
                } else if(man.user_tn === iam.tn && man.possion === 2 && man.status === 0){
                    if(learn === 'title') {
                        itog = 'Подписать'
                    } else if (learn === 'display'){
                        itog = 'flex'
                    }
                } else if(man.user_tn === iam.tn && man.possion === 3 && man.status === 0){
                    if(learn === 'title') {
                        itog = 'Подписать'
                    } else if (learn === 'display'){
                        itog = 'flex'
                    }
                } else {
                    setBtnwrite(false)
                }
            })
        } else if(thisza.commission && btn===2){
            const newComs = thisza.commission
            newComs.map((man, index) => {
                if(man.user_tn === iam.tn && man.possion !== 4 && man.status === 0){
                    if(learn === 'title'){
                        itog = 'Отказать'
                    } else if (learn === 'display'){
                        itog = 'flex'
                    }
                } else {
                    setBtnrefusal(false)
                }
            })
        }
        return itog
    }
    const reverStatus = (status) => {
        try{
            let go = false
            let tn = ''
            let pos = 0

            if(thisza.commission){
                thisza.commission.forEach(man=>{
                    if(man.user_tn === iam.tn && man.possion === 1 && man.status === 0){
                        tn = man.user_tn
                        pos = 1
                        const compliteSt = SocialService.reverStatus({thisza, user: tn, possion: pos, status, comment})
                        compliteSt.then(data=>{setThisza(data.data); message('Заявление согласовано')})
                        if(status === 1){
                            btnwrite(false)
                        } else if (status === 2){
                            btnrefusal(false)
                        }
                    }
                })
            }

        }catch(e){
            console.log(e)
        }
    }
    const showStatement = za => {
        setReadstatement(true)
        setThisza(za)
    }

    const getCommissions = async () => {
        try{
            const {data} = await SocialService.getComission()
            const listmans = []
            data.forEach(man => {
                if(man.possion === 2){
                    listmans.push(man.user.id)
                }
            })
            setCommission_ids(listmans)
        }catch(e){

        }
    }

    const makeProtocol = async () => {
        try{
            const goodlist = [...zalist]
            const newlist = []
            goodlist.forEach(za => {
                newlist.push(za[1].id)
            })
            const protocol = await SocialService.makeProtocol({user_tn: iam.tn, newlist})
            setMaker(false)
            if(protocol) getAllZa()
            message('Протокол сформирован, для формирования приказа необходимо перейти в раздел Протоколы')
        }catch(e){
            console.log(e)
        }

    }

    const checkPlus = async (man, status, user_possion) => {
        try{
            const statusmake = await SocialService.makeStatus({user_tn: man.t13uni.tn, za: thisza, comment, user_possion: user_possion, status: status })
            if(statusmake) {
                message('Подписано')
                setThisza(statusmake.data)
            }
            if(status === 2){
                //sendMess(status, comment)
            }
        }catch(e){

        }
    }

    useEffect(()=>{
        getUsers()
        getAllZa()
        getCommissions()

    }, [])
    useEffect(()=>{
        getAllZa()
    }, [resave])

    function StatusList({ data }){
        const statusOrder = {
            'Отказано': 1,
            'Новая': 2,
            'На согласовании': 3,
            'Согласовано': 4,
            'Сформировано': 5,
        }

        const sortedData = [...data].sort((a, b) => statusOrder[a.status] - statusOrder[b.status])

        const renderStatus = (status) => {
            switch (status) {
                case 'Отказано':
                    return <span style={{ color: 'rgba(180,92,92,1)' }}>{status}</span>
                case 'На согласовании':
                    return <span style={{ color: 'rgba(255,161,97,0.8)' }}>{status}</span>
                case 'Новая':
                    return <span style={{ color: 'rgba(0,49,83,1)' }}>{status}</span>
                case 'Согласовано' || 'Сформировано':
                    return <span style={{ color: 'rgba(68,148,74,1)' }}>{status}</span>
                default:
                    return <span>{status}</span>
            }
        }

        let previousStatus = null

        return (
            <div>
                <div style={{ margin: '20px 0' }} />
                {sortedData.map((za, index) => {
                    const statusChanged = za.status !== previousStatus
                    previousStatus = za.status
                    return (
                        <React.Fragment key={index}>
                            {statusChanged && index !== 0 && <div style={{ margin: '20px 0' }} />} {/* Отступ между статусами */}
                            { ( chk1 || za.status !== 'Отказано' ) && ( chk2 || za.status !== 'Сформировано' ) ?
                            <div key={index} className="statelist_list_line bordertopnone tourer" style={(list.includes(index))?{border: '4px solid #000'}:{border: '4px solid #CCC'}} onClick={()=>chooseLine(index, za)}>
                                <div className="statelist_list_line_name listpp">{index+1}</div>
                                <div className="statelist_list_line_price date">{za.updatedAt.split('T')[0].split('-').reverse().join('.')}</div>
                                <div className="statelist_list_line_group">{za.programofsoc.name}</div>
                                <div className="statelist_list_line_group">{za.user.full_name}</div>
                                <div className="statelist_list_line_cropname">{renderStatus(za.status)}</div>
                                <div className="statelist_list_line_del" onClick={()=>{showStatement(za)}}><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                            </div>
                                : null }
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="soclist">
            <div className="soclist_title"><p>Список всех заявлений на мат.помощь</p> <Link to={'/protocol'} className={'button'}>перейти к списку протоколов <i className="fa-solid fa-arrow-right"></i></Link></div>

            <CheckBox label={'Отображать заявления получившие отказ'} checked={chk1} onChange={setChk1} />
            <CheckBox label={'Отображать запротоколированные заявления'} checked={chk2} onChange={setChk2} />

            <div className="soclist_btns">
                {/*<div className="soclist_upload">Фильтр</div>*/}
                <div className="soclist_upload" style={(list.length > 0)?{display: 'flex'}:{display: 'none'}} onClick={()=>setMaker(true)}>Действия</div>
                <div className="soclist_upload" style={(list.length > 0)?{display: 'flex'}:{display: 'none'}} onClick={cleanList}>Сбросить</div>
            </div>
            <div className="statelist_list">
                <div className="statelist_list_line" >
                    <div className="statelist_list_line_name title">П/П</div>
                    <div className="statelist_list_line_price title ">Дата</div>
                    <div className="statelist_list_line_group title">Наименование</div>
                    <div className="statelist_list_line_group title">ФИО заявителя</div>
                    <div className="statelist_list_line_cropname title ">Статус</div>
                    <div className="statelist_list_line_del title"></div>
                </div>
                {listza ? <StatusList data={listza} /> : null}
            </div>

            <div className='glass' style={(readstatement)?{display: 'flex'}:{display: 'none'}}>
                <div className="glass_board">
                    <div className="glass_board_close"><i className="fa-solid fa-xmark"  onClick={()=>setReadstatement(false)}/></div>
                    <div className="glass_board_body">
                        <div className="glass_board_body_author">заявитель: {(thisza.user)&&thisza.user.full_name}</div>
                        <div className="glass_board_body_developer">должность: {(thisza.user)&&thisza.user.developer}</div>
                        <div className="glass_board_body_title">{(thisza.programofsoc)&&thisza.programofsoc.name}</div>
                        <div className="glass_board_body_title">Заявленная сумма: {(thisza.programofsoc)&&thisza.programofsoc.sum} руб.</div>
                        <div className="glass_board_body_stazh">стаж {(thisza.stazh)&&thisza.stazh}</div>
                        <div className="glass_board_body_documents">
                            {(thisza.docs)&&thisza.docs.map((doc, index)=>(
                                <div key={index} className="glass_board_body_documents_file" onClick={()=>handleDownload(doc)}>
                                    <i className="fa-regular fa-file"/>
                                    <div className="glass_board_body_documents_file_name">{doc.docdesc}</div>
                                </div>
                            ))}
                        </div>

                        <div className="glass_board_body_control">

                            {(btnwrite)?(
                                <div className="glass_board_body_control_btn" style={{display: 'flex'}} onClick={()=>reverStatus(1)} >{getButton(thisza, 'title', 1)}</div>
                            ):''}
                            {(btnrefusal)?(
                                <div className="glass_board_body_control_btn" style={{display: 'flex'}} onClick={()=>reverStatus(2)} >{getButton(thisza, 'title', 2)}</div>
                            ):''}
                        </div>
                        <div className="glass_board_body_tit">Согласования</div>

                        {(thisza.commission)&&thisza.commission.map((man, index)=>{ if(man.possion === 1){return(
                            <div key={index} className="glass_approve_body_man">
                                <div className="glass_approve_body_man_content">
                                    <div className="glass_approve_body_man_photo" style={(man.user.avatar)?{backgroundImage: `url("/files/profile/${man.user.avatar}")`}:{backgroundImage: 'url("/profile/face.png")'}}></div>
                                    <div className="glass_approve_body_man_params">
                                        <div className="glass_approve_body_man_name">{man.t13uni.name}</div>
                                        <div className="glass_approve_body_man_dev">{man.t13uni.developer}</div>
                                        <div className="glass_approve_body_man_departament">{man.t13uni.branch}</div>
                                        <div className="glass_board_body_tit">Комментарий:</div>
                                        {(iam.tn === man.t13uni.tn && man.status === 0)?(<input className='glass_approve_body_man_comment' style={{border: '3px solid #000', width: '300px'}} defaultValue={comment} onChange={(e)=>setComment(e.target.value)} type='text' placeholder='Ввести комментарий'/>):(<div className="glass_approve_body_man_comment">{(man.comment === '')?'Комментарий отсутствует':man.comment}</div>)}
                                    </div>
                                </div>
                                <div className="glass_approve_body_man_status">
                                    {(man.status === 0 && man.user_tn === iam.tn)&&(
                                        <>
                                            <div className="glass_approve_body_man_status_wait">Ожидание</div>
                                            <div className="glass_approve_body_man_status_yes hovhover" onClick={()=>checkPlus(man, 1, 1)}>Cогласовать</div>
                                            <div className="glass_approve_body_man_status_no hovhover" onClick={()=>checkPlus(man, 2, 1)}>Отклонить</div>
                                        </>
                                    )}
                                    {(man.status === 0 && man.user_tn !== iam.tn)&&(
                                        <div className="glass_approve_body_man_status_wait">Ожидание</div>
                                    )}
                                    {(man.status === 1)&&(
                                        <div className="glass_approve_body_man_status_yes">Cогласовано</div>
                                    )}
                                    {(man.status === 2)&&(
                                        <div className="glass_approve_body_man_status_no">Отклонено</div>
                                    )}
                                </div>
                            </div>
                        )}})}
                        <div className='glassslash'></div>
                        <div className="glass_board_body_tit">Контроль</div>
                        {(thisza.commission)&&thisza.commission.map((man, index)=>{ if(man.possion === 2){return(
                            <div key={index} className="glass_approve_body_man">
                                <div className="glass_approve_body_man_content">
                                    <div className="glass_approve_body_man_photo" style={(man.user.avatar)?{backgroundImage: `url("/files/profile/${man.user.avatar}")`}:{backgroundImage: 'url("/profile/face.png")'}}></div>
                                    <div className="glass_approve_body_man_params">
                                        <div className="glass_approve_body_man_name">{man.t13uni.name}</div>
                                        <div className="glass_approve_body_man_dev">{man.t13uni.developer}</div>
                                        <div className="glass_approve_body_man_departament">{man.t13uni.branch}</div>
                                    </div>
                                </div>
                                <div className="glass_approve_body_man_status">

                                </div>
                            </div>
                        )}})}
                    </div>
                </div>
            </div>
            <div className='glass' style={(maker)?{display: 'flex'}:{display: 'none'}}>
                <div className="glass_board">
                    <div className="glass_board_close"><i className="fa-solid fa-xmark"  onClick={()=>setMaker(false)}/></div>
                    <div className="glass_board_body">
                        <div className="glass_board_body_author">оформил: Чернобай Ольга Владимировна</div>
                        <div className="glass_board_body_developer">должность: начальник отдела продаж</div>

                        <div className="glass_board_body_control">
                            <div className="glass_board_body_control_btn" onClick={()=>makeProtocol()} style={{display: 'flex'}}>Сформировать</div>
                        </div>
                        <div className="glass_board_body_tit">Список</div>
                        <div className="glass_board_body_liststatements">
                            <div className="glass_board_body_liststatements_man">
                                <div className="glass_board_body_liststatements_num bold">№</div>
                                <div className="glass_board_body_liststatements_fio bold">ФИО</div>
                                <div className="glass_board_body_liststatements_dev bold">Должность</div>
                                <div className="glass_board_body_liststatements_view bold">Вид</div>
                                <div className="glass_board_body_liststatements_app bold">Основание</div>
                                <div className="glass_board_body_liststatements_del bold">Сумма / Статус</div>
                            </div>
                            {zalist.map((za,index)=>{let check = 0; let no = 0; let yes = 0; summa = summa + za[1].programofsoc.sum; return(
                                <div key={index} className="glass_board_body_liststatements_man">
                                    <div className="glass_board_body_liststatements_num">{index+1}</div>
                                    <div className="glass_board_body_liststatements_fio">{za[1].user.full_name}</div>
                                    <div className="glass_board_body_liststatements_dev">{za[1].user.developer}</div>
                                    <div className="glass_board_body_liststatements_view">{za[1].programofsoc.name}</div>
                                    <div className="glass_board_body_liststatements_app">{za[1].programofsoc.description}</div>
                                    <div className="glass_board_body_liststatements_del">
                                        <div className="glass_board_body_liststatements_del_eye">
                                            {za[1].programofsoc.sum}
                                        </div>
                                        <div className="glass_board_body_liststatements_del_del">
                                        </div>
                                        {za[1].commission.map((man, index)=>{
                                            if(man.possion === 1 && man.status === 1){
                                                yes++
                                            } else if(man.possion === 1 && man.status === 0) {
                                                check++
                                            } else if(man.possion === 1 && man.status === 2) {
                                                no++
                                            }
                                        })}
                                        <div className={(check === 0 && no === 0)?`glass_board_body_liststatements_del_yes`:(no > 0)?`glass_board_body_liststatements_del_no`:`glass_board_body_liststatements_del_wait`}>{(check === 0 && no === 0)?`согласовано`:(no > 0)?`есть отказ`:`ожидание`}</div>
                                    </div>
                                </div>
                            )})}
                            <div className="glass_board_body_liststatements_man">
                                <div className="glass_board_body_liststatements_num"></div>
                                <div className="glass_board_body_liststatements_fio"></div>
                                <div className="glass_board_body_liststatements_dev"></div>
                                <div className="glass_board_body_liststatements_view"></div>
                                <div className="glass_board_body_liststatements_app" style={{display: 'flex', justifyContent: 'flex-end', fontWeight: '800'}}>ИТОГО:</div>
                                <div className="glass_board_body_liststatements_del">
                                    <div className="glass_board_body_liststatements_del_eye">
                                        {summa}
                                    </div>
                                    <div className="glass_board_body_liststatements_del_del">
                                    </div>

                                    {/*<div className={`glass_board_body_liststatements_del_wait`}>согласовано</div>*/}
                                    <div className={`glass_board_body_liststatements_del_wait`} style={{border: '1px solid #FFFFFF', color: '#FFF'}}>************</div>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}

export default observer(StatementsList)