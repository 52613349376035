import React, {useContext, useEffect, useState} from "react";
import "../welding/tabelwelding/tabelform.scss";
import "../welding/tabelwelding/tabviewwork1920.scss";
import "../welding/tabelwelding/tabviewwork1550.scss";
import "../welding/tabelwelding/tabviewwork1080.scss";
import style from "./SvodkaStyle.module.scss";
import 'jspdf-autotable';
import {Link, useLocation} from "react-router-dom";
import {useMessage} from "../../hooks/message.hook";
import {Context} from "../../index";
import ObjsService from "../../services/ObjsService";
import {useMonth} from "../../hooks/month.hook";
import Select from "react-select";
import ModalFiles from "../modalwin/ModalFiles";
import {DelManTabel} from "./modalactive/DelManTabel";
import WriteTabelService from "../../services/WriteTabelService";
import ManService from "../../services/ManService";
import ExportExcelSvodka from "../prints/ExportExcelSvodka";
export const Svodka = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let getId = searchParams.get('id');
    let getShifr = searchParams.get('shifr');
    let getMonth = searchParams.get('month');
    let getYear = searchParams.get('year');
    const message = useMessage()
    const  {store} = useContext(Context)
    const inn = store.user.inn
    const login = store.user.login
    const [listObjs, setListObjs] = useState([])
    const pushMonth = useMonth()
    const [activeday, setActiveday] = useState([])
    const [allday, setAllday] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31])
    const [filers, setFilters] = useState([
        {
            name: 'com',
            title: 'Комментарий',
            number: 0,
        },
        {
            name: 'dop',
            title: 'Доп шифры',
            number: 0,
        },
        // {
        //     name: 'itr',
        //     title: 'ИТР',
        //     number: 0,
        // },
        // {
        //     name: 'rab',
        //     title: 'Рабочие',
        //     number: 0,
        // },
        {
            name: 'ras',
            title: 'Шифр',
            number: 0,
        },
        {
            name: 'auto',
            title: 'Убраные',
            number: 0,
        },
    ])
    const [delwin, setDelwin] = useState(false)
    const [idman, setIdman] = useState('no')
    const [nameman, setNameman] = useState('no')
    const [devman, setDevman] = useState('no')
    const [tabel, setTabel] = useState([])
    const [listPeoples, setListPeoples] = useState([])
    const [ymtabel, setYMtabel] = useState([])
    const [dop, setDop] = useState(null)
    const [listdop, setListdop] = useState([])

    const dayOclockHandler = async (index, man, day, data, goods) => {
        try{
            if(goods === 'marker'){
                day = ''
                if(data===1){
                    data = 0
                }else{
                    data=1
                }
            }
            const refreshtabel = [...tabel]
            refreshtabel[index][goods+day] = data
            setTabel(refreshtabel)
            const refreshTabel = await ObjsService.refreshTabel({man, day, data, goods})
        }catch(e){

        }
    }

    const plusDopShifr = async (enter = null) => {
        try{
            const newshifr = (dop === null)?'':dop.trim()
            if(enter !== null && newshifr === ''){
                message('Введите название дополнительного шифра')
            }else{
                const dops = await ObjsService.getDopShifr({enter, newshifr, object_id:getShifr, month: getMonth, year: getYear})
                setListdop(dops.data)
                setDop(null)
            }

        }catch(e){
            console.log(e)
        }
    }

    const getMyObj = (num, param) => {
        let getParam
        listObjs.forEach(obj => {
            if(obj.id === parseInt(num)){
                getParam = obj[param]
            }
        })
        return getParam
    }

    const viewAllObjs = async (e) => {
        try {
            const viewList = await ObjsService.getObjs()
            setListObjs(viewList.data)
        }catch (e) {
            console.log('puizercvccvfg')
        }
    }

    const viewDay = async (day) => {
        try{
            let mydays = [...activeday]
            if(day){
                if(activeday.includes(day)){
                    mydays = mydays.filter(value => value !== day)
                }else{
                    mydays.push(day)
                }
            }

            const makemydays = await ObjsService.makeMyDays({days: mydays,object_id:getShifr,month:getMonth,year:getYear})
            setActiveday(mydays)
        }catch(e){
            console.log(e)
        }

    }

    const viewFiler = async (filter = null) => {
        try{
            const getfilter = await ObjsService.viewFilter({filter, object_id: getShifr, month: getMonth, year: getYear})
            const myYm = getfilter.data
            const newfilter = [
                {
                    name: 'com',
                    title: 'Комментарий',
                    number: (myYm.com !== null)?myYm.com:0,
                },
                {
                    name: 'dop',
                    title: 'Доп шифры',
                    number: (myYm.dop !== null)?myYm.dop:0,
                },

                {
                    name: 'ras',
                    title: 'Шифр',
                    number: (myYm.ras !== null)?myYm.ras:0,
                },
                {
                    name: 'auto',
                    title: 'Убраные',
                    number: (myYm.auto !== null)?myYm.auto:0,
                },
            ]
            setFilters(newfilter)
        }catch(e){

        }
    }

    const listMansOfTabel = async (e) => {
        let month = pushMonth(getMonth)
        let year = getYear
        let shifre = getShifr
        const listTable = await ObjsService.listTabelMans({inn, shifre, month, year})
        setListPeoples(listTable.data)
        setTabel(listTable.data)
        const ymtabel = await ObjsService.getThisYM({object_id:getShifr,month:getMonth,year:getYear})
        setYMtabel(ymtabel.data)
    }

    const daysOpen = async () => {
        try{
            const mydays = await ObjsService.firstMyDays({object_id:getShifr,month:getMonth,year:getYear})
            setActiveday(mydays.data)
        }catch(e){

        }
    }

    const delDopShifr = async (dop) => {
        try {
            const itogdel = await ObjsService.delDopShifr({id: dop})
            if(itogdel.data){
                plusDopShifr()
            }else{
                message('Попробуйте позже, что-то пошло не так')
            }
        }catch(e){
            console.log(e)
        }
    }

    const deleteMan = (id, name, developer) =>{

        setIdman(id)
        setNameman(name)
        setDevman(developer)
        setDelwin(!delwin)
    }
    const [listMans, setListMans] = useState([])
    const [thisMans, setThisMans] = useState([])
    const months = [
        'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
        'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'
    ];
    const plusToT13 = async list =>{
        let month = months[+getMonth]
        let newList = list
        try{
            let i = list.length
            const humanlist = await ManService.getHumanList()
            humanlist.data.forEach(man => {
                man.label = man.name + '  ' + man.developer
                man.value = man.tn
                man.index = i
                man.month = month
                man.inn = '8617014209'
                i++
            })
            const lastList = humanlist.data
            newList = [...newList, ...lastList]
            setListMans(newList)
        }catch(e){
            console.log(e)
        }
    }

    const [t13, setT13] = useState([])
    const t13List = async (e) => {
        let newArr
        try {
            let month = months[+getMonth]
            let year = getYear
            const listMan = await ObjsService.getT13({inn, month, year})
            let i = 0
            if (listMan.data.length > 100){

                listMan.data.forEach(man => {
                    man.label = man.name + '  ' + man.developer
                    man.value = man.tn
                    man.index = i
                    i++
                })
                // setListMans(listMan.data)
                plusToT13(listMan.data)
                setT13(listMan.data)
            } else {
                month = months[getMonth - 1];
                try{
                    const listMan = await ObjsService.getT13({inn, month, year})
                    listMan.data.forEach(man => {
                        man.label = man.name + '  ' + man.developer
                        man.value = man.tn
                        man.index = i
                        i++
                    })
                    // setListMans(listMan.data)
                    plusToT13(listMan.data)
                    setT13([])
                } catch {

                }

            }
        } catch {
            alert('СП')
        }
    }
    const plusMan = async () => {
        const man = thisMans
        man.month = months[+getMonth]
        man.year = getYear
        man.shifr = getMyObj(getShifr, 'shifr')
        man.objid = getShifr
        try{
            const createLineMan = await WriteTabelService.plusMan({man})
            if(createLineMan.data){listMansOfTabel()}
        }catch(e){
            console.log(e.error)
        }
    }

    const copyTabelDay = async () => {
        try{
            const lastday = Math.max(...activeday);
            console.log(lastday)
            const open = await ObjsService.copyDayInSvodka({tabel, lastday})
            viewDay(lastday+1)

            listMansOfTabel()
        }catch(e){

        }
    }

    const printSvodka = async () => {
        try{
            const print = {
                tabel: tabel,
                dops: listdop,
                days: activeday
            }
            console.log(print)
            ExportExcelSvodka(print)
        }catch(e){
            console.log(e)
        }
    }

    useEffect(()=>{
        viewAllObjs()
        listMansOfTabel()
        daysOpen()
        plusDopShifr()
        viewFiler()
        t13List()
    },[])

    useEffect(()=>{

    }, [ymtabel, activeday])

    return (
        <div className={style.tabelsvodka}>
            <ModalFiles data={<DelManTabel func={listMansOfTabel} idmandel={idman} namemandel={nameman} devmandel={devman}  active={delwin} setActive={setDelwin} month={getMonth} year={getYear} getShifr={getShifr}/>} active={delwin} setActive={setDelwin}></ModalFiles>

            <div className={style.tabelsvodka_up}>
                <Link to={`/thistabelportal/?id=${getId}&shifr=${getShifr}&month=${getMonth}&year=${getYear}`} className={style.tabelsvodka_up_backbtn}>Назад</Link>
                <div className={style.tabelsvodka_up_otdel}>{getMyObj(getShifr, 'shifr')}</div>
                <div className={style.tabelsvodka_up_otdel}>{pushMonth(getMonth)} {getYear}</div>
            </div>
            <div className={style.tabelsvodka_pult}>
                <div className={style.tabelsvodka_pult_plusman}>
                        <Select placeholder="Выбрать сотрудника" className='select' onChange={(e) => setThisMans(listMans[e.index])} value={thisMans} options={listMans} styles={{container:(baseStyles, state) => ({...baseStyles,width:'250px'})}}/>
                        <div className={style.tabelsvodka_pult_plusman_plusbtn} onClick={()=>plusMan()}>Добавить сотрудника</div>
                </div>
                <div className={style.tabelsvodka_pult_daysdop}>
                    <div className={style.tabelsvodka_pult_daysdop_days}>
                        {allday.map((day, index)=>(
                            <div key={index} onClick={()=>viewDay(day)} style={(activeday.includes(day))?{backgroundColor: '#454545', color: '#FFF'}: {}} className={style.day}>{day}</div>
                        ))}

                    </div>
                    <div className={style.tabelsvodka_pult_daysdop_dop}>
                        <div className={style.tabelsvodka_pult_daysdop_dop_inputs}>
                            <input className={style.plus_input} onChange={(e)=>setDop(e.target.value)}/>
                            <div className={style.plus_plusbtn}
                                onClick={()=>plusDopShifr('enter')}
                            >Добавить</div>
                        </div>
                        <div className={style.tabelsvodka_pult_daysdop_dop_list}>
                            {(listdop)&&listdop.map((dop,index)=>(
                                <div key={index} className={style.tabelsvodka_pult_daysdop_dop_list_view}>
                                    <div className={style.tabelsvodka_pult_daysdop_dop_list_view_text}>{dop.name}</div>
                                    {/*<div className={style.tabelsvodka_pult_daysdop_dop_list_view_del} onClick={()=>delDopShifr(dop.id)}><i className="fa-solid fa-trash"/></div>*/}
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
                <div className={style.tabelsvodka_pult_filter}>
                    {filers.map((btn, index)=>{ if(btn.name !== 'ras'){return(
                        <div key={index} className={style.tabelsvodka_pult_btn} style={(btn.number === 1)?{backgroundColor: '#454545', color: '#FFF'}:{}} onClick={()=>viewFiler(btn)}>{btn.title}</div>
                    )}})}

                </div>
                <div className={style.tabelsvodka_pult_filter}>
                    <div className={style.tabelsvodka_pult_btn} onClick={()=>copyTabelDay()}>Скопировать 11е на 12е</div>
                    <div className={style.tabelsvodka_pult_btn} onClick={()=>printSvodka()}>Печать</div>
                </div>
            </div>
            <div className={style.tabelsvodka_table}>
                {tabel.map((man, index)=>(
                    <div key={index} className={style.tabelsvodka_table_strock} style={(filers[3].number===0 && man.marker===1)?{display: 'none'}:{}}>
                        <div className={style.tabelsvodka_table_strock_num}>
                            <div className={style.tabelsvodka_table_strock_num_strock}>{index+1}</div>
                            <div className={style.tabelsvodka_table_strock_num_trash} onClick={()=>deleteMan(man.id, man.name, man.developer)}><i className="fa-solid fa-trash" /></div>
                        </div>
                        <div className={style.tabelsvodka_table_strock_name}>
                            <div className={style.tabelsvodka_table_strock_name_fio}>{man.name}</div>
                            {/*<div className={style.tabelsvodka_table_strock_name_dev}>{man.developer}</div>*/}
                            <div className={style.tabelsvodka_table_strock_name_delete} style={(man.marker === 1)?{backgroundColor: '#ff5e5e'}:{}}
                                 onClick={()=>dayOclockHandler(index,man, '', man.marker, 'marker')}
                            >{(man.marker === 1)?'ВЕРНУТЬ':'УБРАТЬ'}</div>
                        </div>
                        <div className={style.tabelsvodka_table_strock_name}>
                            {/*<div className={style.tabelsvodka_table_strock_name_fio}>{man.name}</div>*/}
                            <div className={style.tabelsvodka_table_strock_name_dev}>{man.developer}</div>
                            {/*<div className={style.tabelsvodka_table_strock_name_delete} style={(man.marker === 1)?{backgroundColor: '#ff5e5e'}:{}}*/}
                            {/*     onClick={()=>dayOclockHandler(index,man, '', man.marker, 'marker')}*/}
                            {/*>{(man.marker === 1)?'ВЕРНУТЬ':'УБРАТЬ'}</div>*/}
                        </div>
                        {allday.map((day, indexer)=>(
                            <div key={indexer} style={(activeday.includes(day))?{display: 'flex'}:{display: 'none'}}>
                                <div className={style.tabelsvodka_table_strock_date}>
                                    <div className={style.tabelsvodka_table_strock_date_tit}>{day}.{(getMonth.length > 1)}</div>
                                    <input className={style.tabelsvodka_table_strock_date_oclock} value={man['m'+day]} onChange={(e)=>dayOclockHandler(index,man, day, e.target.value, 'm')}/>
                                </div>
                                <div className={style.tabelsvodka_table_strock_comment} style={(filers[0].number===0)?{display: 'none'}:{}}>
                                    <div className={style.tabelsvodka_table_strock_comment_tit}>Комментарий</div>
                                    <input className={style.tabelsvodka_table_strock_comment_text} value={man['c'+day]} onChange={(e)=>dayOclockHandler(index,man, day, e.target.value, 'c')}/>
                                </div>
                                <div className={style.tabelsvodka_table_strock_dop} style={(filers[1].number===0)?{display: 'none'}:{}}>
                                    <div className={style.tabelsvodka_table_strock_date_tit}>Доп</div>
                                    <select className={style.tabelsvodka_table_strock_date_oclock} onChange={(e)=>dayOclockHandler(index,man, day, e.target.value, 'dop')}>
                                        <option>{man['dop'+day]}</option>
                                        {(listdop)&&listdop.map((dop, index)=>(
                                            <option key={index}>{dop.name}</option>
                                        ))}
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}

            </div>
        </div>
    )
}