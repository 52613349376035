import React, {useContext, useEffect, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import {useMessage} from "../../hooks/message.hook";
import SocialService from "../../services/SocialService";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import formatDate from "../../components/functions/formatDate";
import PrikazToPdf from "../../components/functions/PrikazToPdf";
import ProtocolToPdf from "../../components/functions/ProtocolToPdf";
import {Context} from "../../index";
import {Link} from "react-router-dom";
import ModalFiles from "../../components/modalwin/ModalFiles";


function StatementsProtocols(){
    const [loading,setLoading] = useState(false)
    const {store} = useContext(Context)
    const iam = store.user
    const [sortDirection,setSortDirection] = useState(true)

    const [protocols,setProtocols] = useState([])
    const [selected,setSelected] = useState(-1)
    const [statuses,setStatuses] = useState([])

    const [commission, setCommission] = useState(false)
    const [readstatement, setReadstatement] = useState(false)

    const message = useMessage()
    const loadingHandler = async () => {
        try {
            setLoading(true)
            loadProtocols()
        }catch(e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const [thiswriter, setThiswriter] = useState(null)

    const [m1,setM1] = useState(false)

    const loadProtocols = async (sort = 'id') => { // 'id' 'date' 'summ' 'status'
        try{
            setLoading(true)
            const {data} = await SocialService.getProtocols(sort,sortDirection)
            const sts = await SocialService.getStatuses()
            const comm = await SocialService.getComission()
            setStatuses(sts.data)
            setCommission(comm.data)

            comm.data.map(man => {
                if(man.possion === 2){
                    setThiswriter(man)
                }
            })
            data.map( item => {
                item.myprograms.map(row => {
                    if(sts.data){
                        const firstPossion = row.commission.filter(element => element.possion === 1)
                        if (firstPossion.every(element => element.status === 1)) {
                            const status = sts.data.find(status => parseInt(status.value) === 1)
                            row.statusvalue = status.value
                            row.statuscolor = status.color
                        }
                        if (firstPossion.some(element => element.status === 2)) {
                            const status = sts.data.find(status => parseInt(status.value) === 2)
                            row.statusvalue = status.value
                            row.statuscolor = status.color
                        }
                        row.statusvalue = 'ожидание'
                        row.statuscolor = '#000000'
                    }
                })
            })
            console.log(data)

            setProtocols(data)

        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const cancelHandler = () => {
        setReadstatement(false)
        setSelected(-1)
        setM1(false)
    }
    const showStatement = index => {
        // alert('eye')
        setSelected(index)
        setReadstatement(true)
    }
    const printProtocol = index => {
        ProtocolToPdf(protocols[index],commission)
    }
    const printPrikaz = index => {
        PrikazToPdf(protocols[index])
    }

    const [wrproto, setWrproto] = useState({})

    const removeProtocolHandler = () => {
        setM1(true)
    }
    const removeProtocol = async () => {
        try {
            setLoading(true)
            if(selected >=0 ){
                const {data} = await SocialService.removeProtocol(protocols[selected].id)
                if(data) message('Протокол расформирован')
                const updatedProtocols = protocols.filter(item => item.id !== protocols[selected].id);
                setProtocols(updatedProtocols)
                cancelHandler()
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const writeProtocol = async () => {
        try{
            setLoading(true)
            if(selected>=0){
                const {data} = await SocialService.saveProtocol(protocols[selected].id)
                if(data){
                    message('Согласовано')
                }
            }

        }catch(e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    function ControllMans(){
        if(iam.full_name === thiswriter.user.full_name){
            return (
                <div className="controll_man">
                    <div className="controll_man_photo" style={(thiswriter.user.avatar.length>0)?{backgroundImage: `url(/files/profile/${thiswriter.user.avatar})`}:{}}></div>
                    <div className="btns">
                        <div className="name">{thiswriter.user.full_name}</div>
                        <div className="dev">{thiswriter.user.developer}</div>

                        {!protocols[selected].status ?
                            <>
                                <div className="control_btn" onClick={()=>writeProtocol(protocols[selected], 1)}>Подписать</div>
                                <div className="control_btn" onClick={()=>removeProtocolHandler(protocols[selected])}>Сбросить протокол</div>
                                {statuses.find(status => parseInt(status.value) === protocols[selected].status).label}
                            </>
                        :null}
                    </div>

                </div>
            )
        } else {
            return (
                <div className="controll_man">

                    <div className="controll_man_photo" style={(thiswriter.user.avatar.length>0)?{backgroundImage: `url(/files/profile/${thiswriter.user.avatar})`}:{}}></div>
                    <div className="btns">
                        <div className="name">{thiswriter.user.full_name}</div>
                        <div className="dev">{thiswriter.user.developer}</div>

                    </div>

                </div>
            )
        }

    }
    function RemProtocol(){
        return (
            <div>
                {selected >= 0 ?
                    <div>
                        <p>Вы действительно желатете сбросить протокол номер {protocols[selected].id}?</p>
                        <p>После сброса все заявления вернуться к прошлому статусу</p>
                        <div className={'buttons'}>
                            <div onClick={() => removeProtocol()} className={'button'}>Да</div>
                            <div onClick={() => setM1(false)} className={'button'}>Нет</div>
                        </div>
                    </div>

                :null}
            </div>
        )
    }

    let sumprotocol = 0

    const sortHandler = (sort) => {
        setSortDirection(!sortDirection)
        loadProtocols(sort)
    }

    useEffect(()=>{
        loadingHandler()
    }, [])



    return (
        <div className="soclist">
            <div className="soclist_title">Группы заявлений для протокола и приказа <Link to={'/statementslist'} className={'button'}>перейти к списку заявлений<i className="fa-solid fa-arrow-right"></i></Link></div>

            <div className={`sort`}>
                <div className={`title text`}>Сортировать</div>
                <div onClick={() => sortHandler('abc')} className={`sortbtn text`}>По алфовиту</div>
                <div onClick={() => sortHandler('num')} className={`sortbtn text`}>По номеру</div>
                <div onClick={() => sortHandler('date')} className={`sortbtn text`}>По дате</div>
            </div>

            <div className="statelist_list">
                <div className="statelist_list_line" >
                    <div className="statelist_list_line_name title">П/П</div>
                    <div className="statelist_list_line_price title ">Создан</div>
                    <div className="statelist_list_line_price title">Кол-во</div>
                    <div className="statelist_list_line_group title">ФИО создателя</div>
                    <div className="statelist_list_line_price title ">Сумма</div>
                    <div className="statelist_list_line_cropname title ">Статус</div>
                    <div className="statelist_list_line_icon title ">Посмотреть</div>
                    <div className="statelist_list_line_icon title ">Протокол</div>
                    <div className="statelist_list_line_icon title ">Приказ</div>
                </div>

                {protocols.length ? protocols.map( (item,index) => (
                    <div key={index} className="statelist_list_line bordertopnone tourer">
                        <div className="statelist_list_line_name listpp">{item.id}</div>
                        <div className="statelist_list_line_price date">{formatDate(item.createdAt)}</div>
                        <div className="statelist_list_line_price date">{item.myprograms.length}</div>
                        <div className="statelist_list_line_group">{item.t13uni.name}</div>
                        <div className="statelist_list_line_price date">{item.myprograms.reduce((accumulator, row) => {return accumulator + row.programofsoc.sum }, 0)}</div>
                        <div className="statelist_list_line_cropname">{statuses.find(status => parseInt(status.value) === item.status).label}</div>
                        <div className="statelist_list_line_icon"><div className="statelist_list_line_del" onClick={()=>{showStatement(index)}} help='просмотреть'><i className="fa-solid fa-eye"/></div></div>
                        <div className="statelist_list_line_icon"><div className="statelist_list_line_del" onClick={()=>{printProtocol(index)}}><i className="fa-solid fa-print"/></div></div>
                        <div className="statelist_list_line_icon"><div className="statelist_list_line_del" onClick={()=>{printPrikaz(index)}}><i className="fa-solid fa-print"/></div></div>
                    </div>

                )) : null}
            </div>

            <div className='glass' style={(readstatement)?{display: 'flex'}:{display: 'none'}}>
                {selected >= 0 ?
                <div className="glass_board">
                    <div className="glass_board_close"><i className="fa-solid fa-xmark"  onClick={cancelHandler}/></div>
                    <div className="glass_board_body">
                        <div className="glass_board_body_author">Протокол № {protocols[selected].id} </div>
                        <div className="glass_board_body_control">
                        </div>

                        <div className="glass_board_body_tit">Список</div>
                        <div className="glass_board_body_liststatements">
                            <div className="glass_board_body_liststatements_man">
                                <div className="glass_board_body_liststatements_num bold">№</div>
                                <div className="glass_board_body_liststatements_fio bold">ФИО</div>
                                <div className="glass_board_body_liststatements_dev bold">Должность</div>
                                <div className="glass_board_body_liststatements_view bold">Вид</div>
                                <div className="glass_board_body_liststatements_app bold">Основание</div>
                                <div className="glass_board_body_liststatements_del bold">Сумма</div>
                            </div>
                            {protocols[selected].myprograms ? protocols[selected].myprograms.map( (item,index) =>{
                                sumprotocol = sumprotocol + item.programofsoc.sum
                                return (
                                    <div key={index} className="glass_board_body_liststatements_man">
                                        <div className="glass_board_body_liststatements_num">{item.id}</div>
                                        <div className="glass_board_body_liststatements_fio">{item.user.full_name}</div>
                                        <div className="glass_board_body_liststatements_dev">{item.user.developer}</div>
                                        <div className="glass_board_body_liststatements_view">выделение материальной помощи</div>
                                        <div className="glass_board_body_liststatements_app">{item.programofsoc ? item.programofsoc.name : null}</div>
                                        <div className="glass_board_body_liststatements_del" style={{justifyContent: "flex-start"}}>
                                            {item.programofsoc.sum} руб.
                                            {/*<div className="glass_board_body_liststatements_del_eye"><i className="fa-solid fa-eye"/></div>*/}
                                            {/*<div className="glass_board_body_liststatements_del_del"><i className="fa-solid fa-eraser"/></div>*/}
                                            {/*<div style={item.statuscolor ? {border:`1px solid ${item.statuscolor}`}:{}} className="glass_board_body_liststatements_del_yes">{item.statusvalue}</div>*/}
                                        </div>
                                    </div>
                                )
                            }  ) : null}
                            <div className="glass_board_body_liststatements_man">
                                <div className="glass_board_body_liststatements_num"></div>
                                <div className="glass_board_body_liststatements_fio"></div>
                                <div className="glass_board_body_liststatements_dev"></div>
                                <div className="glass_board_body_liststatements_view"></div>
                                <div className="glass_board_body_liststatements_app" style={{display: 'flex', justifyContent: "flex-end", fontWeight: '700'}}>ИТОГО: </div>
                                <div className="glass_board_body_liststatements_del" style={{justifyContent: "flex-start"}}>
                                    {sumprotocol} руб.
                                </div>
                            </div>

                            <div className="ctrl_plus">
                                <ControllMans />
                            </div>


                        </div>

                    </div>

                </div>
                : null }
            </div>

            <ModalFiles data={RemProtocol()} active={m1} setActive={setM1} />
            {loading ? (<LoadingSpinner/>) : null}
        </div>
    )
}

export default observer(StatementsProtocols)