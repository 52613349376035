import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite"

import styles from './Cms.module.scss'

import LoadingSpinner from "../../components/loading/LoadingSpinner"
import JsonTable from "../../components/tables/JsonTable"
import OdataService from "../../services/OdataService"
import CmsSelect from "../../components/inputs/CmsSelect";
import {useMessage} from "../../hooks/message.hook";
import {set} from "mobx";

function SyncFrom1C(){
    const message = useMessage()
    const [loading,setLoading] = useState(false)
    const [peoples,setPeoples] = useState(null)
    const [data1,setData1] = useState(null)
    const [user,setUser] = useState(null)
    const [userinfo,setUserinfo] = useState(null)
    const [struct,setStruct] = useState(null)
    const [methods,setMethods] = useState(null)
    const [otpusk1,setOtpusk1] = useState(null)
    const [otpusk2,setOtpusk2] = useState(null)
    const [obsos,setObsos] = useState(null)
    const [daysHoly,setDaysHoly] = useState(null)

    const [sp,setSp] = useState(null)

    const [inn,setInn] = useState(null)
    const [meds,setMeds] = useState(null)

    const loadingHandler = async () => {
        try {
            setLoading(true)
            const {data} = await OdataService.getpeoples()
            setPeoples(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const cancelHandler = () => {
        setData1(null)
        setStruct(null)
        setOtpusk1(null)
        setOtpusk2(null)
        setUserinfo(null)
        setMethods(null)
        setObsos(null)
        setSp(null)
        setInn(null)
        setMeds(null)
    }

    const getAllusersHandler = async () => {
        try {
            cancelHandler()
            setLoading(true)
            await loadingHandler()
            if(peoples){
                setData1(peoples)
                console.log(peoples)
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getStructHandler = async () => {
        try {
            cancelHandler()
            setLoading(true)
            const {data} = await OdataService.getStructure()
            setStruct(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getHolidaysHandler = async () => {
        try {
            cancelHandler()
            const {data} = await OdataService.getAllOtp()
            console.log(data)
            let holi = []

            const key = 'username'
            peoples.map( item => {
                const hl = data.value.find(obj => obj.RecordSet[0].Сотрудник_Key === item.Ref_Key)
                if(hl){
                    hl[key] = item.Description
                    holi.push(hl)
                }
            })
            setOtpusk1(holi)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getHolidays2Handler = async () => {
        try {
            cancelHandler()
            const {data} = await OdataService.getallotp2()
            const key = 'username'
            let holi = []
            data.value.map( item => {
                const hl = peoples.find(obj => obj.Ref_Key === item.Сотрудник)
                if(hl){
                    item[key] = hl.Description
                    holi.push(item)
                }
            })
            holi.sort((a, b) => {
                const dateA = new Date(a.Начало)
                const dateB = new Date(b.Начало)
                return dateA - dateB
            })
            console.log(holi)
            setOtpusk2(holi)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getMethodsHandler = async () => {
        try {
            cancelHandler()
            const {data} = await OdataService.getmethodwork()
            setMethods(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getObsosHandler = async () => {
        try {
            cancelHandler()
            const {data} = await OdataService.getobsos()
            let ozss = []
            const key = 'username'
            peoples.map( item => {
                const obs = data.value.find(obj => obj.Сотрудник_Key === item.Ref_Key)
                if(obs){
                    obs[key] = item.Description
                    ozss.push(obs)
                }
            })
            console.log(ozss)
            setObsos(ozss)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getInnUsers = async () => {
        try {
            cancelHandler()
            const {data} = await OdataService.getInnUsers()
            setInn(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getMeds = async () => {
        try {
            cancelHandler()
            const {data} = await OdataService.getMeds()
            setMeds(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getUserInfo = async () => {
        try {
            if(user){
                cancelHandler()
                setLoading(true)
                const {data} = await OdataService.getUserInfo(user.Ref_Key)
                setUserinfo(data)
            }
            else{
                message('Выберите сотрудника')
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getOtdelyHandler = async () => {
        try {
            cancelHandler()
            setLoading(true)
            const {data} = await OdataService.getOtdely()
            setSp(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const setInnsToUsers = async () => {
        try {
            setLoading(true)
            const {data} = await OdataService.setInnsToUsers()
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const setMedsToUsers = async () => {
        try {
            setLoading(true)
            const {data} = await OdataService.setMedsToUsers()
            console.log(data)
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const getHolydaysCounts = async () => {
        try {
            cancelHandler()
            setLoading(true)
            const {data} = await OdataService.getHolydaysCounts()
            setDaysHoly(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getHolydaysCountsIam = async () => {
        try {
            cancelHandler()
            setLoading(true)
            const {data} = await OdataService.getHolydaysCountsIam()
            setDaysHoly(data)
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getTemp = async () => {
        try {
            cancelHandler()
            setLoading(true)
            const {data} = await OdataService.getTemp()
            console.log(data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    //
    useEffect(() => {
        //cancelHandler()
        //loadingHandler()
    },[])
    return (
        <>
            <div className={styles.container}>
                <h1 style={{marginBottom:'10px'}}>Settings and sync</h1>
                <CmsSelect placeholder={`Выберите сотрудника`} onChange={setUser} options={peoples} radius={'0'} />
                <div className={styles.data}>
                    <div className={styles.buttons}>
                        <div onClick={() => getTemp()} className={styles.button}>Log TEMP</div>
                        <div onClick={() => getUserInfo()} className={styles.button}>select user</div>
                        <div onClick={() => getAllusersHandler()} className={styles.button}>all users</div>
                        <div onClick={() => getHolidaysHandler()} className={styles.button}>holidays</div>
                        <div onClick={() => getHolidays2Handler()} className={styles.button}>holidays2</div>
                        <div onClick={() => getMethodsHandler()} className={styles.button}>methods</div>
                        <div onClick={() => getObsosHandler()} className={styles.button}>o.b.s.o.s</div>
                        <div onClick={() => getStructHandler()} className={styles.button}>structure</div>
                        <div onClick={() => getOtdelyHandler()} className={styles.button}>Otdely</div>
                        <div onClick={() => getInnUsers()} className={styles.button}>Inn</div>
                        <div onClick={() => setInnsToUsers()} className={`${styles.button} ${styles.red}` }>Set INN To Users</div>
                        <div onClick={() => getMeds()} className={styles.button}>see medical</div>
                        <div onClick={() => setMedsToUsers()} className={`${styles.button} ${styles.red}` }>Set Meds To Users</div>
                        <div onClick={() => getHolydaysCounts()} className={styles.button}>see all dovolena</div>
                        <div onClick={() => getHolydaysCountsIam()} className={styles.button}>see iam dovolena</div>

                    </div>
                    <div style={{marginTop:'20px'}} className={styles.table}>
                        {userinfo ?
                            <div className={styles.row}>
                                <div>{userinfo.Description}</div>
                                <div>{userinfo.Description}</div>
                            </div>
                        : null}

                        {data1 ? data1.map( (item,index) => (
                                    <div key={index} className={styles.row}>
                                        <div>{item.Description}</div>
                                        <div>{item.Ref_Key}</div>
                                        <div>{item.Сотрудник_Key}</div>
                                    </div>
                                ))
                        : null}
                        {meds ? meds.value.map( (item,index) => (
                                <div key={index} className={styles.row}>
                                    <div>{item.Значение}</div>
                                    <div>{item.Свойство_Key}</div>
                                    <div>{item.Ref_Key}</div>
                                </div>
                            ))
                            : null}

                        {methods ? methods.value.map( (item,index) => (
                                <div key={index} className={styles.row2}>
                                    <div>{item.Ref_Key}</div>
                                    <div>{item.Description}</div>
                                </div>
                            ))
                            : null}
                        {struct ? struct.value.map( (item,index) => (
                                <div key={index} className={styles.row2}>
                                    <div>{item.url}</div>
                                </div>
                            ))
                            : null}

                        {obsos ? obsos.map( (item,index) => (
                                <div key={index} className={styles.row}>
                                    <div>{item.username}</div>
                                    <div>{item.ДатаНачала}</div>
                                    <div>{item.ДатаОкончания}</div>
                                </div>
                            ))
                            : null}
                        {otpusk1 ? otpusk1.map( (item,index) => (
                            <span key={index}>
                            {item.RecordSet.map( (row,rindex) => (
                                <div key={rindex} className={styles.row}>
                                    <div>{item.username}</div>
                                    <div>{row.ДатаНачала}</div>
                                    <div>{row.ДатаОкончания}</div>
                                </div>
                            ))}
                            </span>
                            ))
                            : null}
                        {otpusk2 ? otpusk2.map( (item,index) => (
                                <div key={index} className={styles.row}>
                                    <div>{item.username}</div>
                                    <div>{item.Начало}</div>
                                    <div>{item.Окончание}</div>
                                </div>
                            ))
                            : null}
                        {/*{inn ? inn.map( (item,index) => (*/}
                        {/*        <div key={index} className={styles.row}>*/}
                        {/*        </div>*/}
                        {/*    ))*/}
                        {/*    : null}*/}
                    </div>
                </div>




            </div>
            {loading ? (<LoadingSpinner/>) : null}
        </>
    )
}
export default observer(SyncFrom1C)