import $api from "../http"

export default class ObjsService{

    static async thisObj(object_id){
        return $api.post(`/objects/thisobj`, object_id)
    }
    static async getObjs(){
        return $api.get(`/objects/getlistobjs`)
    }
    static async listObjsSV(){
        return $api.get(`/objects/viewobjs`)
    }
    static async insertObjs(obj_id){
        return $api.post(`/objects/insertobj`, {obj_id})
    }
    static async getAllTabels(search){
        return $api.post(`/objects/getalltabels`, search)
    }
    static async getTabelsForAll(search){
        return $api.post(`/objects/getforall`, search)
    }
    static async createTabels(tabel){
        return $api.post(`/objects/createtabel`, tabel)
    }
    static async getT13(params){
        return $api.post(`/objects/gett13`, params)
    }
    static async listTabelMans(params){
        return $api.post(`/objects/listtabel`, params)
    }
    static async getKTUdate(params){
        return $api.post(`/objects/getktudate`, params)
    }
    static async copyTab(params){
        return $api.post(`/objects/copytabel`, params)
    }
    static async delManTabel(params){
        return $api.post(`/objects/delmantabel`, params)
    }
    static async getUsersList(){
        return $api.get(`/objects/getuserslist`)
    }
    static async passObj(params){
        return $api.post(`/objects/passobj`, params)
    }
    static async dataOfObj(params){
        return $api.post(`/objects/usersobjs`, params)
    }
    static async getPriory(){
        return $api.get(`/objects/getpriory`)
    }
    static async getTabelSRTO(objs){
        return $api.post(`/objects/getobjssrto`, objs)
    }
    static async getThisYM(ym){
        return $api.post(`/objects/getthisym`, ym)
    }
    static async makeMyDays(days){
        return $api.post(`/objects/makemydays`, days)
    }
    static async firstMyDays(days){
        return $api.post(`/objects/firstmydays`, days)
    }
    static async refreshTabel(day){
        return $api.post(`/objects/refreshtabel`, day)
    }
    static async getDopShifr(dop){
        return $api.post(`/objects/getdopshifr`, dop)
    }
    static async delDopShifr(id){
        return $api.post(`/objects/deldopshifr`, id)
    }
    static async viewFilter(filter){
        return $api.post(`/objects/viewfilter`, filter)
    }
    static async copyDayInSvodka(newdate){
        return $api.post(`/objects/copydayinsvodka`, newdate)
    }
    static async openNextDay(newdate){
        return $api.post(`/objects/opennextDay`, newdate)
    }
    static async makeStatusProtocol(status){
        return $api.post(`/objects/makestatusprotocol`, status)
    }


}