import React, {useEffect, useState, useRef, useContext} from 'react';
import styles from './Messager.module.scss';
import SearchSelect from "../../components/inputs/SearchSelect";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import UserService from "../../services/UserService";
import getOnlineStatus from "../../components/functions/getOnlineStatus";
import getTimeString from "../../components/functions/getTimeString";
import MessagesMultySelect from "../../components/inputs/MessagesMultySelect";
import {getSocket} from "../../http/socket";
import MessagesService from "../../services/MessagesService";
import {useMessage} from "../../hooks/message.hook";
import FilesService from "../../services/FilesService";
import {Context} from "../../index";
import getTime from "../../components/functions/getTime";
import datingMessages from "../../components/functions/datingMessages";
import {DataContext} from "../../context/DataContext";
import Rec from "./Rec";
import onDocument from "../../components/functions/onDocument";
import CircularProgress from "../../components/OldComponents/old/CircularProgress";
import onMessage from "../../components/functions/onMessage";
import SocialService from "../../services/SocialService";
import isImage from "../../components/functions/isImage";
import getParticipantsString from "../../components/functions/getParticipantsString";
import ModalFiles from "../../components/modalwin/ModalFiles";
import PhotoGallery from "../../components/galleries/photogallery";
import removePatronymic from "../../components/functions/removePatronymic";
import getMessagesString from "../../components/functions/getMessagesString";

const Messager = () => {
    const {store} = useContext(Context)
    const {emojis,icons} = useContext(DataContext)
    const [loading,setLoading] = useState(false)
    const [localLoading,setLocalLoading] = useState(false)
    const [users,setUsers] = useState([])
    const [filteredUsers,setFilteredUsers] = useState([])
    const [usersLoaded, setUsersLoaded] = useState(false)
    const [redirectList,setRedirectList] = useState([])

    const [m1,setM1] = useState(false)
    const [m2,setM2] = useState(false)
    const [m3,setM3] = useState(false)
    const [m4,setM4] = useState(false)
    const [m5,setM5] = useState(false)
    const [m6,setM6] = useState(false)

    const [selectedUser,setSelectedUser] = useState(null)
    const [online,setOnline] = useState(null)

    const [selectedUsersGroup,setSelectedUsersGroup] = useState([])
    const [onSelectGroup,setOnSelectGroup] = useState(false)
    const [partipants,setPartipants] = useState([])
    const [creator,setCreator] = useState([])
    const [message,setMessage] = useState('')
    const [showEmojis,setShowEmojis] = useState(false)
    const [showAttachments,setShowAttachments] = useState(false)
    const [audio,setAudio] = useState(null)
    const [selectedMenu,setSelectedMenu] = useState(0)
    const [record,setRecord] = useState(false)
    const [openRec,setOpenRec] = useState(false)

    const [files,setFiles] = useState([])
    const [filesLoading,setFilesLoading] = useState(false)
    const [progress, setProgress] = useState({})

    const [groupname,setGroupName] = useState('')
    const [groupimage,setGroupImage] = useState('')

    const [sM1,setSM1] = useState(-1)
    const [selExcIndex,setSelExcIndex] = useState(-1)

    const [selectedChat,setSelectedChat] = useState(null)
    const [selectedChatGroup,setSelectedChatGroup] = useState([])
    const [selectedRedirect,setSelectedRedirect] = useState(null)
    const [listMessages,setListMessages] = useState([])
    const [attachments,setAttachments] = useState([])
    const [listChats,setListChats] = useState([])
    const [gallery,setGallery] = useState(null)
    const [currentImageIndex,setCurrentImageIndex] = useState(null)

    const [selectedMessages,setSelectedMessages] = useState([])

    const textareaRef = useRef(null)
    const groupFaceRef = useRef(null)
    const messagesEndRef = useRef(null)
    const filesInputRef = useRef(null)
    const audioRef = useRef(null)

    const messageHook = useMessage()

    const loadingHandler = async () => {
        try {
            setLoading(true)
            const users = await UserService.getUsers()
            if(users.data) {
                setUsers(users.data)
                setUsersLoaded(true)
            }
            await getChats(users.data)
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const changeHandler = (e) => {
        setMessage(e.target.value)
    }
    const cancelHandler = () => {
        clearHandler()
        setSelectedMessages([])
        setSelectedMenu(0)
        setListMessages([])
        setGroupImage('')
        setGroupName('')
        setSelectedUser(null)
        setSelectedUsersGroup([])
        setSelectedChat(null)
        setOnSelectGroup(false)
        getChats()
    }
    const clearHandler = () => {
        setMessage('')
        setFiles([])
        setAudio(null)
    }
    const createChatHandler = () => {
        if(selectedUsersGroup.length === 1) {
            setSelectedUser(selectedUsersGroup[0])
            setSelectedUsersGroup([])
        }else{
            createChat()
        }
    }
    const createChat = async () => {
        try {
            setLoading(true)
            if(selectedUser || selectedUsersGroup){
                const {data} = await MessagesService.createChat(selectedUser,selectedUsersGroup,groupname,groupimage)
                console.log(data)
                setSelectedChat(setOnlineToChat(data))
                setOnSelectGroup(false)
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const setOnlineToChat = (data = null) => {
        if(data && users){

            data.users[0].online = new Date()
            data.inchat.map( ( item, index) => {
                const userIndex = users.findIndex(u => u.tn === item.user.tn)
                data.inchat[index].user.online = users[userIndex].online
            })
            return data
        }
    }
    const sendMessage = async () => {
        try {
            if( (message.length || audio || files.length) && selectedChat){
                setLoading(true)
                let sendingFiles = []
                if(files.length){
                    sendingFiles = files.map(item => {
                        if(!item.err) return item.name
                        return null
                    })
                    console.log(sendingFiles)
                }
                const {data} = await MessagesService.sendChatMessage(message,selectedUser,selectedUsersGroup,selectedChat,sendingFiles,audio ? audio.blob : null)
                if(data){
                    clearHandler()
                    const updatedChat = JSON.parse(JSON.stringify(selectedChat))
                    updatedChat.cmessages = data
                    setSelectedChat(updatedChat)
                    scrollToBottom()
                    const socket = getSocket()
                    updatedChat.inchat.map( item => {
                        const socketMessage = message.length ? message : files.length ? files[0].name : 'Голосовое сообщение'
                        const data = {from:store.user.tn,from_name:store.user.full_name,to:item.user.tn,message:socketMessage}
                        socket.emit('message', data)
                    })
                    playUpDownAudio()
                }
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const getMessages = async () => {
        try {
            const {data} = await MessagesService.getMessages(selectedChat)
            console.log('загрузка сообщений')
            if(data && data.length) {
                const filteredMessages = data.filter(message => !message.trash.includes(store.user.id))
                setSelectedChat({...selectedChat,cmessages:filteredMessages})
                setListMessages(filteredMessages)
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const updateChats = async () => {
        try {
            const {data} = await MessagesService.getChats()
            if(data) {
                console.log(data)
                setListChats(data)
            }
        }catch (e) {
            console.log(e)
        }
    }
    const startRecord = () => {
        setRecord(true)
        setOpenRec(true)
    }
    const getChats = async (usrs = users) => {
        try {
            setLoading(true)
            const {data} = await MessagesService.getChats()
            if(data) {
                const forSelect = data.map( item => {
                    return {...item,
                        value: item.ongroup ? item.id : item.inchat[0].user.tn,
                        label: item.ongroup ? item.name.length ? item.name : `Групповой чат ${item.id}` : item.inchat[0].user.full_name,
                        avatar: item.ongroup ? item.image.length ? item.image : 'faceg.png' : item.inchat[0].user.avatar ? item.inchat[0].user.avatar : "face.png"}
                })
                let temp = []
                if(usrs){
                    usrs.map( usr => {
                        if (!forSelect.some(obj => obj.tn !== usr.tn)) {
                            temp.push(usr)
                        }
                    })
                }

                setRedirectList([...forSelect,...temp])
                setListChats(data)
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const addEmoji = (emoji) => {
        setMessage(message + emoji)
        setShowEmojis(false)
    }
    const selectChat = async (chat) => {
        if(!chat.ongroup) {
            const selUser = users.find(user => user.id === chat.inchat[0].user.id)
            console.log(selUser)
            setSelectedUser(selUser)
        }
        chat.cmessages.map( message => {
            if (!message.sees.includes(store.user.id)) {
                message.sees.push(store.user.id)
            }
        })
        setSelectedChat(setOnlineToChat(chat))

        try{
            await MessagesService.iSee(chat.id)
        }catch (e) {
            console.log(e)
        }
    }
    const unSelectChat = (chat) => {
        setSelectedChatGroup(prevItems => prevItems.filter(item => item.id !== chat.id))
        if(selectedChat) {
            if(selectedChat.id === chat.id) setSelectedChat(null)
            setSelectedMessages([])
        }
    }
    const selectUnreaded = () => {
        const unreaded = listChats.filter(chat =>
            chat.cmessages.some(message =>
                message.author_id !== store.user.id && !message.sees.includes(store.user.id)
            )
        )
        setSelectedChat(null)
        setListChats(unreaded)
        setSelectedMenu(1)
    }
    const loadImage = async (e) => {
        setLoading(true)
        try {
            const response = await MessagesService.loadAvatarImage(e.target.files[0])
            if(response){
                console.log(response.data)
                if(response.err) messageHook(response.message)
                else {
                    console.log(response.data.path)
                    setGroupImage(response.data.path)
                }
            }
        }catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }
    const handleUploadProgress = (progressEvent,name) => {
        const percentCompleted = (progressEvent.loaded / progressEvent.total)
        setProgress(prevState => ({
            ...prevState,
            [name]: percentCompleted,
        }))
    }
    const openDocInNewWindow = (file,event) => {
        event.stopPropagation()
        if(selectedChat){
            const extension = file.path.split('.').pop()

            switch (extension){
                case 'pdf':
                    window.open("https://srsuportal.ru/files/chats/" + selectedChat.id + '/' + file.path, '_blank')
                    break
                case 'rar':
                    handleDownload(file)
                    break
                case  'zip':
                    handleDownload(file)
                    break
                default:
                    window.open("https://docs.google.com/viewer?url=https://srsuportal.ru/files/chats/" + selectedChat.id + '/' + file.path, "_blank")
                    break
            }
        }
    }
    const getAttachments = async (type = 0) => {
        try {
            setLocalLoading(true)
            const {data} = await MessagesService.getAttachments(selectedChat.id,type)
            if(data) {
                setAttachments(data)
                if(type===0) setGallery(data)
            }
        }catch (e) {
            console.log(e)
        }finally {
            setLocalLoading(false)
        }
    }
    const openGallery = (path,event) => {
        event.stopPropagation()
        if(gallery){
            const index = gallery.findIndex(img => img.path === path)
            setCurrentImageIndex(index)
        }
    }
    const playUpDownAudio = () => {
        if (audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error('Failed to play sound:', error)
            })
        }
    }
    const selectFilesHandler = async (e,drop = null) => {
        let selectedFiles
        if(drop===null){
            selectedFiles = Array.from(e.target.files)
        }
        else selectedFiles = drop
        setFiles(selectedFiles)
        setFilesLoading(true)
        let index = 0
        const uploadPromises = selectedFiles.map(async (file) => {
            try{
                if(onMessage(file.name)){
                    const {data} = await FilesService.uploadTaskFiles(file, (progressEvent) => handleUploadProgress(progressEvent, file.name))
                    if (data) {
                        console.log(data)
                    }
                }else{
                    const errFiles = [...selectedFiles]
                    errFiles[index].err = 'Неподдерживаемый фомат файла'
                    setFiles(errFiles)
                    console.log(errFiles)
                }
            }catch (e){
                console.log(e+': Проблема загрузки списка документов')
            }
            index++
        })
        try {
            await Promise.all(uploadPromises);
            setFilesLoading(false)
        } catch (error) {
            console.error('Ошибка при загрузке файлов', error);
        }
    }
    const deleteFileHandler = async (index) => {
        try{
            const response = await FilesService.deleteFile(files[index].name)
            const newFiles = [...files]
            newFiles.splice(index, 1);
            setFiles(newFiles)

            console.log(response.data)
        }catch (e) {
            console.log(e+': Проблема удаления')
        }
    }
    const handleDownload = async (file) => {
        try {
            const response = await FilesService.downloadMessageFile(selectedChat.id,file.id)
            if(response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file.name)
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const handleDeleteMessages = async () => {
        try {
            setLoading(true)
            if(selectedMessages.length){
                const {data} = await MessagesService.deleteMessages(selectedMessages)
                if(data) {
                    setM3(false)
                    console.log('data')
                    setListMessages(prevListMessages =>
                        prevListMessages.filter(message => !selectedMessages.includes(message.id))
                    )
                    setSelectedMessages([])
                 }
            }
        } catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const handleRedirectMessages = async () => {
        try {
            setLoading(true)
            if(selectedMessages.length && selectedRedirect){
                const {data} = await MessagesService.redirectMessages(selectedRedirect,selectedMessages)
                if(data) {
                    console.log(data)
                    setM4Handler(false)
                    setSelectedMessages([])
                    updateChats()
                    const socket = getSocket()
                    selectedRedirect.map(chat => {
                        chat.inchat.map( item => {
                            const socketMessage = 'пересланное сообщение'
                            const data = {from:store.user.tn,from_name:store.user.full_name,to:item.user.tn,message:socketMessage}
                            socket.emit('message', data)
                        })
                    })
                    playUpDownAudio()
                    messageHook('Сообщения отправлены')
                }
            }else{
                messageHook('Выберите чат для отправки')
            }
        } catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const handleAddPartipants = async () => {
        try {
            setLoading(true)
            if(selectedUsersGroup.length && selectedChat){
                const {data} = await MessagesService.addPartipants(selectedChat,selectedUsersGroup)
                if(data) {
                    setM5(false)
                    console.log(data.messages)
                    setSelectedChat({...selectedChat,inchat:data.users,cmessages:data.messages})
                    setPartipants([...partipants,...selectedUsersGroup])
                    messageHook('Добавил')
                }
            }else{
                messageHook('Выберите чат для отправки')
            }
        } catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }
    const handleExcPartipants = async () => {
        try {
            setLoading(true)
            if(partipants[selExcIndex]){
                const {data} = await MessagesService.excPartipants(selectedChat,partipants[selExcIndex])
                console.log(data)
                if(data) {
                    setM6(false)
                    console.log(data.messages)
                    console.log(data.users)
                    setSelectedChat({...selectedChat,inchat:data.users,cmessages:data.messages})
                    setPartipants(prevPartipants =>
                        prevPartipants.filter((_, i) => i !== selExcIndex)
                    )
                    messageHook('Исключил')
                }
            }else{
                messageHook('Выберите чат для отправки')
            }
        } catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const openM1Handler = () => {
        setM1(true)
        if(selectedChat){
            let Creator = null
            const partis = selectedChat.inchat.map(item => item.user)
            const chatgroup = [...partis,selectedChat.users[0]]

            const index = chatgroup.findIndex(item => item.id === selectedChat.creator_id)
            if (index !== -1) {
                Creator = chatgroup.splice(index, 1)
            }
            setCreator(Creator[0])
            setPartipants(chatgroup)
        }

    }
    const openM2Handler = (index = 0) => {
        setM2(true)
        setSM1(index)
        if(selectedChat){
            getAttachments(index)
        }
    }
    const openM3Handler = () => {
        setM3(true)
    }
    const openM4Handler = () => {
        setM4(true)
    }
    const setM4Handler = (bool) => {
        setM4(bool)
        setSelectedRedirect(null)
    }
    const openM5Handler = () => {
        if(selectedChat){
            const inChatIds = new Set(selectedChat.inchat.map(item => item.user.id))
            setFilteredUsers(users.filter(user => !inChatIds.has(user.id)))
        }
        setM5(true)
    }
    const setM5Handler = (bool) => {
        setM5(bool)
        setSelectedUsersGroup([])
    }
    const openM6Handler = (index) => {
        if(selectedChat){
            setSelExcIndex(index)
        }
        setM6(true)
    }
    const setM6Handler = (bool) => {
        setM6(bool)
        setSelExcIndex(-1)
    }

    function countUnreadMessages(messages) {
        return messages.filter(message =>
            !message.sees.includes(store.user.id) && message.author_id !== store.user.id && !message.service
        ).length
    }

    const selectMessageHandler = (message,service) => {
        console.log(selectedMessages)
        if(!service){
            setSelectedMessages(prevMessages => {
                if (prevMessages.includes(message)) {
                    return prevMessages.filter(n => n !== message)
                } else {
                    return [...prevMessages, message]
                }
            })
        }
    }

    useEffect( () => {
        loadingHandler()
        const socket = getSocket()
        socket.emit('online', {data:'get online users'},(response) => {
            setOnline(response)
        })
        socket.on('receiveMessage', (data) => {
            updateChats()
        })
    },[])
    useEffect(() => {
        const textarea = textareaRef.current
        const maxHeight = 200
        const minHeight = 30
        if(textarea){
            textarea.style.height = textarea ? 'auto' : null
            const newHeight = Math.min(Math.max(textarea.scrollHeight-20, minHeight), maxHeight)
            textarea.style.height = `${newHeight}px`
        }
    }, [message])
    useEffect(() => {
        if(online && usersLoaded){
            const temp = [...users]
            online.map(item => {
                const index = users.findIndex(u => u.tn === item)
                if(index !== -1) {
                    temp[index] = { ...temp[index], socket: true }
                }
            })
            setOnline(online)
            setUsers(temp)
        }
    },[online,usersLoaded])
    useEffect(() => {
        if(selectedUser && !selectedChat){
            createChat()
        }
    },[selectedUser])
    useEffect(() => {
        if(selectedChat){
            console.log(selectedChat)
            getAttachments(0)
            let filteredMessages = []
            if(selectedChat.cmessages){
                filteredMessages = selectedChat.cmessages.filter(message => !message.trash.includes(store.user.id))
            }
            setListMessages(filteredMessages)
            if( !selectedChatGroup.some(obj => obj.id === selectedChat.id) ){
                console.log(selectedChatGroup)
                setSelectedChatGroup([...selectedChatGroup,selectedChat])
            }
        }
    }, [selectedChat])
    useEffect(() => {
        scrollToBottom()
    },[listMessages])
    useEffect(() => {
        if(selectedChat) {
            console.log(listChats)
            getMessages()
            selectChat(selectedChat)
        }
    },[listChats])
    useEffect( () => {
        if(online && selectedChat){
            const temp = {...selectedChat}
            online.map(item => {
                if(temp.users[0].tn === item) temp.users[0] = { ...temp.users[0], socket: true }
                const others = selectedChat.inchat.findIndex(u => u.tn === item)
                if(others) temp.inchat[others] = { ...temp.inchat[others], socket: true }
                setSelectedChat(temp)
            })
        }
    },[m1])

    function Participants(){
        return (
            <div className={styles.settings}>
                {selectedChat ?
                    <>
                        <div className={styles.title}>Информация</div>
                        <div className={styles.info}>
                            <div style={{backgroundImage:`url(/files/profile/${selectedChat.image ? selectedChat.image : 'faceg.png'})`}} className={styles.ava}></div>
                            <div className={styles.chatname}>
                                <div className={styles.name}>{selectedChat.name.length ? selectedChat.name : `Групповой чат ${selectedChat.id}`}</div>
                                <div className={styles.status}>{`${selectedChat.inchat ? getParticipantsString(selectedChat.inchat.length+1) : 'участники'}`}</div>
                            </div>
                        </div>
                    </>
                    :null}

                <div className={styles.settingmenu}>
                    <div onClick={() => openM2Handler(0)} className={styles.btn}>
                        <i className="fa-solid fa-paperclip"></i>
                        Показать вложения
                    </div>

                </div>
                <div className={styles.title}>Участники чата</div>

                {partipants && creator ?
                    <div className={styles.partipants}>
                        <div className={`${styles.part} ${styles.add}`}>
                            <div className={styles.icon}><i className="fa-solid fa-plus"></i></div>
                            <div className={styles.nameman}>
                                <div className={styles.inname}>
                                    <div onClick={openM5Handler} style={{fontSize:'0.8rem'}} className={styles.status}>добавить участников</div>
                                </div>
                            </div>
                        </div>
                        {selectedChat ?
                            <div className={styles.part}>
                                <div style={{backgroundImage:`url(/files/profile/${(creator && creator.avatar) ? creator.avatar : 'face.png'})`}} className={styles.ava}></div>
                                <div className={styles.nameman}>
                                    <div className={styles.inname}>
                                        <div className={styles.name}>{creator.full_name}</div>
                                        <div className={styles.status}>{!creator.socket ? getOnlineStatus(creator.gender,creator.online) : 'Онлайн'}</div>
                                    </div>
                                    <div className={styles.set}>Создатель</div>
                                </div>
                            </div> : null}
                        { partipants.map( (item,index) => (
                            <div key={index} className={styles.part}>
                                <div style={{backgroundImage:`url(/files/profile/${item.avatar ? item.avatar : 'face.png'})`}} className={styles.ava}></div>
                                <div className={styles.nameman}>
                                    <div className={styles.inname}>
                                        <div className={styles.name}>{item.full_name}</div>
                                        <div className={styles.status}>{!item.socket ? getOnlineStatus(item.gender,item.online) : 'Онлайн'}</div>
                                    </div>
                                    {selectedChat && store.user.id === selectedChat.creator_id ? <div onClick={() => openM6Handler(index)} className={styles.set}><i className="fa-solid fa-right-from-bracket"></i></div> : null}
                                </div>

                            </div>
                        ))}
                    </div> : null}
                <div className={styles.exit}>Выйти из чата</div>
            </div>
        )
    }
    function Attachments(){
        return (
            <>
                {selectedChat ?
                    <div className={styles.attachments}>
                        <div className={styles.attheader}>
                            <div onClick={() => openM2Handler(0)} className={`${styles.li} ${sM1 === 0 ? styles.selected : ''}`}>Изображения</div>
                            <div onClick={() => openM2Handler(1)} className={`${styles.li} ${sM1 === 1 ? styles.selected : ''}`}>Файлы</div>
                        </div>
                        <div className={sM1===0 ? styles.smallgallery : styles.fileslist}>
                            {attachments.length ? attachments.map( (item,index) => (
                                <div key={index} onClick={(event) => openGallery(item.path,event)} style={sM1===0 ? {backgroundImage:`url(files/chats/${selectedChat.id}/${encodeURIComponent(item.path)})`} : null} className={styles.fileitem}>
                                    {sM1===1 ?
                                        <div className={styles.file}>
                                            <i className={`fa-regular fa-file-lines ${icons[item.name.split('.').pop()]}`}></i>
                                            <p onClick={(event) => handleDownload(item)}>{item.name}</p>
                                        </div>
                                    : null }
                                </div>
                            )) : null}

                        </div>
                    </div>
                    :null}
            </>
        )
    }
    function Delete(){
        return (
            <>
                {selectedMessages.length ?
                    <div className={styles.delete}>
                        <div className={styles.title}>Удалить сообщение</div>
                        <div className={styles.text}>Вы действительно хотите удалить {getMessagesString(selectedMessages.length)}?</div>
                        <div className={styles.buttons}>
                            <div onClick={() => setM3(false)} className={`${styles.button}`}>Отменить</div>
                            <div onClick={() => handleDeleteMessages()} className={`${styles.button} ${styles.da}`}>Удалить</div>
                        </div>
                    </div>
                    :null}
            </>
        )
    }
    function Redirect(){
        return (
            <>
                {selectedMessages.length ?
                    <div className={styles.delete}>
                        <div className={styles.title}>Переслать сообщение</div>
                        <MessagesMultySelect options={redirectList} online={online} placeholder={'Введите имя или фамилию'} heigth={'auto'} setOptions={setSelectedRedirect} values={selectedRedirect} />
                        <div className={styles.buttons}>
                            <div onClick={() => setM4Handler(false)} className={`${styles.button}`}>Отменить</div>
                            <div onClick={() => handleRedirectMessages()} className={`${styles.button} ${styles.da}`}>Переслать</div>
                        </div>
                    </div>
                    :null}
            </>
        )
    }
    function AddContact(){
        return (
            <>
                {selectedChat && selectedChat.ongroup ?
                    <div className={styles.delete}>
                        <div className={styles.title}>Добавить участника</div>
                        <MessagesMultySelect options={filteredUsers} online={online} placeholder={'Введите имя или фамилию'} heigth={'auto'} setOptions={setSelectedUsersGroup} values={selectedUsersGroup} />
                        <div className={styles.buttons}>
                            <div onClick={() => setM5Handler(false)} className={`${styles.button}`}>Отменить</div>
                            <div onClick={() => handleAddPartipants()} className={`${styles.button} ${styles.da}`}>Добавить</div>
                        </div>
                    </div>
                    :null}
            </>
        )
    }
    function ExcContact(){
        return (
            <>
                {partipants.length && selExcIndex>=0 ?
                    <div className={styles.delete}>
                        <div className={styles.title}>Ислючить из чата</div>
                        {selExcIndex >=0 && partipants[selExcIndex] ? <div className={styles.text}>Вы действительно хотите ислючить из чата {partipants[selExcIndex].full_name}?</div> : null}
                        <div className={styles.buttons}>
                            <div onClick={() => setM6Handler(false)} className={`${styles.button}`}>Отменить</div>
                            <div onClick={() => handleExcPartipants()} className={`${styles.button} ${styles.da}`}>Убрать</div>
                        </div>
                    </div>
                    :null}
            </>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.chatlist}>
                <div className={styles.header}>
                    {selectedChat ?
                        selectedMessages.length === 0 ?
                            !selectedChat.ongroup && selectedUser ?
                                <div className={styles.opened}>
                                    <div className={styles.back} onClick={cancelHandler}>
                                        <i className="fa-solid fa-angle-left"></i>
                                        <div>назад</div>
                                    </div>
                                    <div className={styles.center}>
                                        <div className={styles.name}>{selectedUser.label}</div>
                                        <div className={styles.status}>{!selectedUser.socket ? getOnlineStatus(selectedUser.gender,selectedUser.online) : 'Онлайн'}</div>
                                    </div>
                                    <div className={styles.right}>
                                        <div style={{backgroundImage:`url(/files/profile/${selectedUser.avatar ? selectedUser.avatar : 'face.png'})`}} className={styles.ava}>{selectedUser.socket ? <span className={styles.greencircle}></span> : null}</div>
                                    </div>
                                </div>
                                :
                                <div className={styles.opened}>
                                    <div className={styles.back} onClick={cancelHandler}>
                                        <i className="fa-solid fa-angle-left"></i>
                                        <div>назад</div>
                                    </div>
                                    <div onClick={openM1Handler} className={styles.center}>
                                        <div className={styles.name}>{selectedChat.name.length ? selectedChat.name : `Групповой чат ${selectedChat.id}`}</div>
                                        <div className={styles.status}>{`${selectedChat.inchat ? getParticipantsString(selectedChat.inchat.length+1) : 'участники'}`}</div>
                                    </div>
                                    <div className={styles.right}>
                                        <div style={{backgroundImage:`url(/files/profile/${selectedChat.image ? selectedChat.image : 'faceg.png'})`}} className={styles.ava}></div>
                                    </div>
                                </div>
                        :
                            <div className={styles.smsgcontrol}>
                                <div className={styles.nm}>{getMessagesString(selectedMessages.length)}<i onClick={() => setSelectedMessages([])} className="fa-solid fa-xmark"></i></div>
                                <div className={styles.remove}>
                                    <i onClick={openM3Handler} className="fa-regular fa-trash-can"></i>
                                    <div onClick={openM4Handler} className={styles.redir}>переслать</div>
                                </div>
                            </div>
                        : onSelectGroup ?
                            <>
                                <div className={styles.left}>
                                    <div>Создание чата</div>
                                </div>
                                <div className={styles.right}>
                                    <i onClick={cancelHandler} className="fa-solid fa-xmark"></i>
                                </div>
                            </>
                            :
                            <>
                                <div className={styles.left}>
                                    <SearchSelect defaultValue={null} value={selectedUser} onChange={setSelectedUser} options={users} online={online} placeholder={'поиск'} />
                                </div>
                                <div className={styles.right}>
                                    <i onClick={() => setOnSelectGroup(!onSelectGroup)} className="fa-regular fa-pen-to-square"></i>
                                    <i className="fa-solid fa-ellipsis"></i>
                                </div>
                            </>
                    }
                </div>
                <div className={styles.chats}>
                    {selectedChat ?
                            <>
                                <div className={styles.messages}>
                                    <>
                                        {listMessages ? datingMessages(listMessages).map( (item,index) => ( //selectedChat.cmessages.reverse()
                                            <div key={index} onClick={() => selectMessageHandler(item.id,item.service)} className={`${styles.messagein} ${selectedMessages.includes(item.id) ? styles.selected : !item.service ? styles.messageinhovered : null}`}>
                                                {item.service ?
                                                    <div className={styles.status}>{item.text}</div>
                                                    :
                                                    <>
                                                        <div style={item.user.avatar.length ? {backgroundImage:`url(files/profile/${item.user.avatar})`} : {backgroundImage:`url(files/profile/face.png)`} } className={styles.ava}></div>
                                                        <div className={styles.bodymessage}>
                                                            <div className={styles.name}>{item.user.full_name} <span>{getTime(item.createdAt)}</span>  {item.forwarded ? <span>пересланное сообщение</span> : null}</div>
                                                            <div className={styles.text}>{!item.voice ? item.text : <audio src={`/files/voice/${item.voice}`} controls />}</div>
                                                            {item.messagefiles.length ?
                                                                <div className={styles.messagefiles}>
                                                                    {item.messagefiles.map( (row,index) => (
                                                                        <div key={index} className={styles.file}> {/*onClick={() => handleDownload(row)}*/}
                                                                            {!isImage(row.name) ? <i className={`fa-regular fa-file-lines ${icons[row.name.split('.').pop()]}`}></i> : null }
                                                                            {isImage(row.name) ? <div onClick={(event) => openGallery(row.path,event)} style={{backgroundImage:`url(files/chats/${selectedChat.id}/${encodeURIComponent(row.path)})`}} className={styles.imagemessage}></div> : <p onClick={(event) => handleDownload(row)}>{row.name}</p>}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                :null
                                                            }
                                                        </div>
                                                    </>}
                                            </div>
                                        )) : null}
                                        <div className={'end'} ref={messagesEndRef} />
                                    </>
                                </div>
                                <div className={styles.textbox}>
                                    <div className={styles.right}><div onClick={(e) => filesInputRef.current.click()} className={styles.circle}><i onMouseEnter={() => setShowAttachments(true)} onMouseLeave={() => setShowAttachments(false)} className="fa-solid fa-paperclip"></i></div></div>
                                    <input onChange={(e) => selectFilesHandler(e)} multiple ref={filesInputRef} className='hidden-upload' type='file'/>
                                    {/*{showAttachments && (*/}
                                    {/*<div onMouseEnter={() => setShowAttachments(true)} onMouseLeave={() => setShowAttachments(false)} className={styles.attachments}>*/}
                                    {/*    <div className={styles.ul}>*/}
                                    {/*        <div className={styles.li}><i className="fa-solid fa-file"></i>Файл</div>*/}
                                    {/*        <div className={styles.li}><i className="fa-solid fa-file-image"></i>Фотография</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>)}*/}
                                    <div className={styles.textarea}>
                                        <textarea ref={textareaRef} rows="1" cols="50" value={message} onChange={changeHandler} placeholder="Напишите сообщение"/>
                                        <div className={styles.circle2}><i onClick={() => startRecord()} className="fa-solid fa-microphone-lines"></i><i onMouseEnter={() => setShowEmojis(true)} onMouseLeave={() => setShowEmojis(false)} className="fa-regular fa-face-smile"></i></div>
                                        <div className={styles.emojicontainer}>
                                            {showEmojis && (
                                                <div className={styles.emojipicker} onMouseEnter={() => setShowEmojis(true)} onMouseLeave={() => setShowEmojis(false)}>
                                                    {emojis.map((emoji, index) => (
                                                        <span key={index} onClick={() => addEmoji(emoji)} className={styles.emoji}>{emoji}</span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.left}><div className={styles.circle}>{ ( !loading && !filesLoading ) ? <i onClick={() => sendMessage()} className="fa-solid fa-paper-plane"></i> : <i className="fa-solid fa-hourglass-half"></i>}</div></div>
                                </div>

                                <>
                                    {files && files.length ? (
                                        <div className={styles.filesbox}>
                                            <div className={styles.fileslist}>
                                                {Array.from(files).map((item,index) => (
                                                    <div key={index} className={styles.file}>
                                                        <div className={styles.l}>
                                                            <i className={`fa-regular fa-file-lines ${icons[item.name.split('.').pop()]}`}></i>
                                                            <p>{item.name}<span> {item.err}</span></p>
                                                        </div>
                                                        <div className='l'>
                                                            <CircularProgress color={`${item.err ? 'red' : ''}`} progress={progress[item.name] ? progress[item.name] : 0}/>
                                                            <i onClick={(e) => deleteFileHandler(index)} className="fa-solid fa-xmark del"></i>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}
                                </>

                                <Rec audio={audio} setAudio={setAudio} openRec={openRec} setOpenRec={setOpenRec} record={record} setRecord={setRecord} send={sendMessage} />
                            </>
                        : listChats.length && !onSelectGroup ?
                        <div className={styles.list}>
                            {listChats.map( (item,index) => (
                                <div key={index} onClick={() => selectChat(item)} className={styles.chat}>
                                    {item.ongroup ?
                                        <div style={item.image.length ? {backgroundImage:`url(files/profile/${item.image})`} : {backgroundImage:`url(files/profile/faceg.png)`} } className={styles.ava}></div>
                                    : <div style={item.inchat[0].user.avatar.length ? {backgroundImage:`url(files/profile/${item.inchat[0].user.avatar})`}  : {backgroundImage:`url(files/profile/face.png)`} } className={styles.ava}></div>}
                                    <div className={styles.chatlabel}>
                                        <div className={styles.chatname}>{item.ongroup ? item.name.length ? item.name : `Групповой чат ${item.id}` : item.inchat[0].user.full_name}</div>
                                        <div className={styles.message}>{!item.cmessages[0].voice ? item.cmessages[0].text ? item.cmessages[0].text : item.cmessages[0].messagefiles[0].name : 'Голосовое сообщение'}</div>
                                    </div>

                                    <div className={styles.chatdate}>
                                        <div className={styles.date}>{getTimeString(item.cmessages[0].createdAt)} </div>
                                        {countUnreadMessages(item.cmessages)>0 ? <div className={styles.bluecircle}>{countUnreadMessages(item.cmessages)}</div> : null }
                                    </div>
                                </div>
                                ))}

                        </div> :
                        <>
                            {listChats.length === 0 && selectedMenu===1 ? <div className={styles.nomessages}>У вас нет непрочитанных сообщений</div> : null}
                        </>
                    }
                    {onSelectGroup ?
                        <div className={styles.multy}>
                            <MessagesMultySelect options={users} online={online} placeholder={'Введите имя или фамилию'} heigth={'auto'} setOptions={setSelectedUsersGroup} values={selectedUsersGroup} />
                            <div className={styles.messages}>
                                <div ref={messagesEndRef} />
                            </div>
                            <div className={styles.groupcontrol}>
                                <div className={styles.control}>
                                    <div onClick={(e) => groupFaceRef.current.click()} style={groupimage.length ? {backgroundImage:`url(files/profile/${groupimage})`} : {backgroundImage:`url(files/profile/faceg.png)`}} className={styles.groupimage}></div>
                                    <input onChange={(e) => loadImage(e)} ref={groupFaceRef} className='hidden-upload' type='file'/>
                                    <input value={groupname} onChange={(e) => setGroupName(e.target.value)} placeholder={`Введите имя чата`}/>
                                    <div onClick={() => createChatHandler()} className={styles.button}>создать чат</div>
                                </div>
                            </div>
                        </div>
                        :
                        <> </>
                    }
                </div>
            </div>

            <div onClick={(e) => console.log(selectedChatGroup)}  className={styles.menu}>
                <div className={styles.control}>
                    <div className={selectedMenu===0 ? styles.activemenu : '' } onClick={cancelHandler}>Все чаты</div>
                    <div className={selectedMenu===1 ? styles.activemenu : '' } onClick={() => selectUnreaded()}>Непрочитанные</div>
                </div>
                <div className={styles.opened}>
                    {selectedChatGroup.map( (item,index) => (
                        <div key={index} className={styles.chatslist}>
                            <div onClick={() => selectChat(item)} className={styles.chatlink}> {item.ongroup ? item.name.length ? item.name : `Групповой чат №${item.id}` : removePatronymic(item.inchat[0].user.full_name)} </div>
                            <div onClick={() => unSelectChat(item)} className={styles.del}><i className="fa-solid fa-xmark"></i></div>
                        </div>
                    )) }
                </div>
            </div>
            {loading ? (<LoadingSpinner/>) : null}
            <ModalFiles heigth={'auto'} data={Participants()} active={m1} setActive={setM1} />
            <ModalFiles heigth={'auto'} data={Attachments()} active={m2} setActive={setM2} />
            <ModalFiles heigth={'auto'} data={Delete()} active={m3} setActive={setM3} />
            <ModalFiles heigth={'auto'} data={Redirect()} active={m4} setActive={setM4Handler} />
            <ModalFiles heigth={'auto'} data={AddContact()} active={m5} setActive={setM5} />
            <ModalFiles heigth={'auto'} data={ExcContact()} active={m6} setActive={setM6} />
            <audio ref={audioRef} src="/audio/zvuk2.mp3" preload="auto" />
            {selectedChat && currentImageIndex!==null && gallery ? <PhotoGallery path={`/files/chats/${selectedChat.id}`} currentImageIndex={currentImageIndex} setCurrentImageIndex={setCurrentImageIndex} images={gallery} /> : null}
        </div>

    )
}

export default Messager